import {
	createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { getTime } from "@/utils/utils";
// 导入全局组件——插件式
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "animate.css/animate.min.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "normalize.css";
import "@/assets/styles/common.less";
import "babel-polyfill";
import "./utils/rem.js";
import {
	safeButton
} from '@/api/login/index'
// 全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	createApp(App).component(key, component);
}
const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); // 使用持久化插件

let timer = Date.now()
//兼容弹窗埋点
app.directive('track', {
	mounted(el, binding, vnode) {
		if(binding.value.event == '登录') return
		const eventType = binding.arg || 'click'; // 默认是 click 事件
		const handler = (event) => {
			// 判断是否是 self 修饰符
			if (binding.modifiers.self && event.target !== el) {
				return; // 如果是 self 修饰符且事件不是在 el 上触发，直接返回
			}

			// 执行埋点逻辑
			let eventData = binding.value
			let referer = document.referrer
			if (referer == '') {
				referer = '直接访问'
			} else if (referer.indexOf('?') != -1) {
				referer = referer.substring(0, referer.indexOf('?'))
			}


			let data = {
				page: eventData.page, //来源页面
				targetPage: eventData.event, //目标页面
				clickEvent: eventData.event, //按钮名称
				client: 'PC', //终端
				visitTime: getTime(timer), //访问时间
				source: referer
			}

			if (data.page && data.targetPage) {
				safeButton(data)
			}



			// 如果是 once 修饰符，移除事件监听
			if (binding.modifiers.once) {
				el.removeEventListener(eventType, handler);
			}
		};

		el.addEventListener(eventType, handler);
		// 存储 handler 以便在 unmounted 中移除
		el._trackHandler = handler;
	},
	unmounted(el) {
		const eventType = el._trackEventType || 'click';
		el.removeEventListener(eventType, el._trackHandler);
	}
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

//定义一个获取用户的函数
const get_userinfo = (to, from) => {
	let arr = [{
		id: '4',
		title: '工作站'
	}, {
		id: '2',
		title: '台式机'
	}, {
		id: '9',
		title: '服务器'
	}, {
		id: '5',
		title: '一体机'
	},{
		id: '6',
		title: 'mini电脑'
	},{
		id: '3',
		title: '笔记本'
	}]



	let afterTime = to.path === '/detailsGoods' || to.path === '/detailsGoodsNew' || to.path==='/pointsDetails' || to.path === '/incrementService' || to.meta.pointTitle ===
		'工作站配置' ? 3000 : 0
	//兼容退出工作站配置和商品详情时携带商品名称和不同的商品分类
	let page = ''
	if (from.path === '/detailsGoods' || from.path === '/detailsGoodsNew' || from.path==='/pointsDetails' || from.path === '/incrementService') {
		page = localStorage.getItem('productName') + from.meta.pointTitle
	}else if (from.meta.pointTitle === '工作站配置') {
		page = '适用于' + localStorage.getItem('topText') + '的' + from.meta.pointTitle
	} else if (from.path.indexOf('/desktop/') !== -1) {
		page = arr.find(v=>v.id===from.params.id)?.title
	} else {
		page = from.meta.pointTitle
	}







	setTimeout(() => {
		let targetPage = ''
		//兼容进入工作站配置和商品详情时携带商品名称
		if (afterTime) {
			if (to.meta.pointTitle === '工作站配置') {
				targetPage = '适用于' + localStorage.getItem('topText') + '的' + to.meta.pointTitle
			} else {
				targetPage = localStorage.getItem('productName') + '的' + to.meta.pointTitle
			}
		} else if (to.path.indexOf('/desktop/') !== -1) {
			targetPage = arr.find(v=>v.id===to.params.id)?.title
		} else {
			targetPage = to.meta.pointTitle
		}

		let referer = document.referrer
		if (referer === '') {
			referer = '直接访问'
		} else if (referer.indexOf('?') !== -1) {
			referer = referer.substring(0, referer.indexOf('?'))
		}


		let data = {
			page, //来源页面
			targetPage, //目标页面
			clickEvent: localStorage.getItem('buttonName'), //按钮名称
			client: 'PC', //终端
			visitTime: getTime(timer), //访问时间
			source: referer
		}


		if (data.page && data.targetPage) {
			safeButton(data)
			timer = Date.now()
			localStorage.setItem('buttonTimer',timer)
		}
	}, afterTime)




};

router.beforeEach((to, from, next) => {
	if ((typeof to.params.id !== 'undefined') && location.href.indexOf('desktop') !== -1) {
		//console.log(to.params.id);
		switch (to.params.id) {
			case "4":
				to.meta = to.meta.workspace;
				break;
			case "2":
				to.meta = to.meta.computer;
				break;
			case "9":
				to.meta = to.meta.server;
				break;
			case "5":
				to.meta = to.meta.onemachine;
				break;
			case "3":
				to.meta = to.meta.notebook;
				break;
		}
	}
	/* 路由发生变化修改页面meta */
	if (to.meta.keywords) {
		let meta = document.querySelector('meta[name="keywords"]');
		meta.setAttribute('content', to.meta.keywords);
	}
	if (to.meta.description) {
		let meta = document.querySelector('meta[name="description"]');
		meta.setAttribute('content', to.meta.description);
	}
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}

	get_userinfo(to, from)

	next()
});

import VueLuckyCanvas from '@lucky-canvas/vue'

createApp(App);
app.use(store);
app.use(router);
app.use(VueLuckyCanvas);
app.use(pinia);
app.use(ElementPlus, {
	locale,
	size: "small",
	zIndex: 3000
});
app.config.warnHandler = () => null;
app.mount("#app");
