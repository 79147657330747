<template>
  <div v-if="isVisible" class="overlay" @click.self="close" >
    <div class="modal-content">
      <div class="lottery-dialog-box">
        <div class="close" @click="close"></div>
        <div class="title">
          <img src="../img/points-recodes-dialog.png" alt="">
          <span>积分记录</span>
        </div>

        <div class="table-box">
          <div class="table-title">
            <div>积分时间</div>
            <div>积分途径</div>
            <div>积分变动</div>
            <div>积分余额</div>
          </div>
          <div class="table-content table-title" v-for="(item,index) in recodesArr" :key="index">
            <div>{{item.updatedTime}}</div>
            <div>{{item.source}}</div>
            <div :class="{'isRed':item.isAdd===1}">{{item.changes}}</div>
            <div>{{item.balance}}</div>
          </div>
        </div>

        <div class="demo-pagination-block" v-if="recodesObj.total">
          <el-pagination
            :current-page="recodesObj.current"
            page-size="10"
            background
            layout="prev, pager, next, jumper,total"
            :total="recodesObj.total"

            @current-change="handleCurrentChange"
          />
          <el-button class="btn-cl">确定</el-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
// 控制遮罩显示/隐藏
import { ref, onMounted } from "vue";
import {pointsRecodesList} from '@/api/points'
import { getTime } from "@/utils/utils";
import { safeButton } from "@/api/login";

const isVisible = ref(true);
const emits = defineEmits(["close", "find"]);


const recodesArr=ref([])
const query=ref({
  pageNum:1,
  pageSize:10
})
const recodesObj=ref({})
const options=[
  {value:0,title:'积分兑换'},
  {value:1,title:'购买商品'},
  {value:2,title:'购买服务'},
  {value:3,title:'平台赠送'},
  {value:4,title:'新用户注册'},
  {value:5,title:'签到领积分'},
]
const getList=()=>{
  pointsRecodesList(query.value).then(res=>{
    res.data.list.forEach(v=>{
      if (v.isAdd===0){
        v.changes='-'+v.changes
      }else{
        v.changes='+'+v.changes
      }
      v.source=options.find(o=>o.value===v.source)?.title
    })

    recodesArr.value=res.data.list
    recodesObj.value=res.data
  })
}

// 分页
const handleCurrentChange = (Num) => {
  query.value.pageNum = Num;
  getList();
};

getList()

// 在组件挂载时禁用页面滚动
onMounted(() => {
  document.body.style.overflow = "hidden";
});





// 关闭遮罩函数
const close = () => {
  let timer=Number(localStorage.getItem('buttonTimer'))
  let referer = document.referrer
  if (referer === '') {
    referer = '直接访问'
  } else if (referer.indexOf('?') !== -1) {
    referer = referer.substring(0, referer.indexOf('?'))
  }

  let data = {
    page: '积分中心', //来源页面
    targetPage: '关闭积分记录', //目标页面
    clickEvent: '关闭积分记录', //按钮名称
    client: 'PC', //终端
    visitTime: getTime(timer), //访问时间
    source: referer
  }
  safeButton(data)


  isVisible.value = false;
  document.body.style.overflow = "";
  emits("close");
};
</script>

<style scoped lang="less">
/* 全屏遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 弹窗内容 */
.modal-content {
  position: relative;
  .lottery-dialog-box {
    background: url("../img/recodes-bg.png") no-repeat;
    width:1200px;
    height: 648px;
    background-size: 100%;
    position: relative;

    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url("../img/close.png");
      background-size: 100%;
      top: 12px;
      right: 12px;
      z-index: 1;
      cursor: pointer;
    }
    .title{
      padding: 29px 0 0 36px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
      }
      span{
        margin-left: 7px;
        font-size: 24px;
        color: #472300;
      }
    }
    .table-box{
      margin-top: 29px;
      padding: 0 16px 0 32px;
      box-sizing: border-box;

      .table-title{
        height: 40px;
        display: flex;
        font-size: 14px;
        color: #928C7F;
        &>div:nth-child(1){
          width: 25%;
        }
        &>div{
          line-height: 40px;
        }
        &>div:nth-child(2){
          width: 45%;
          overflow: hidden; /* 确保超出容器的文本被裁剪 */
          white-space: nowrap; /* 确保文本在一行内显示 */
          text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
        }
        &>div:nth-child(3){
          width: 20%;
        }
        &>div:nth-child(4){
          width: 10%;
        }
      }
      .table-content{
        height: 40px;
        color: #666666;
        font-size: 14px;

        &>div{
          line-height: 40px;
          border-bottom: 1px solid #fbfafa;
        }
        &>div:nth-child(3){
          font-weight: bold;
          color: #333333;
        }
        .isRed{
          color: #ce1200 !important;
        }

      }

    }

    .demo-pagination-block{
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 50px;

      :deep(.is-active){
        background: #ce1200;
      }
      :deep(.is-active:hover){
        color: #FFFFFF !important;
      }
      :deep(.number:hover){
        color: #ce1200;
      }
    }

  }
}

/* 隐藏滚动条，但仍可滚动 */
.scrollable {
  overflow: auto; /* 保持内容可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
}

/* 针对IE和Edge的隐藏滚动条样式 */
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none; /* IE 和 Edge 下隐藏滚动条 */
}
</style>

