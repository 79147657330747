// stores/user.js
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: "",
    };
  },
  actions: {
    updateUser(newUser) {
      this.user = newUser;
    },
    removeUser() {
      this.user = "";
    },
  },
  getters: {},
  persist: {
    storage: localStorage,
    keys: "user",
  }, // 自动将状态存储到 localStorage
});
