//埋点
export function getButtonName(e,isHandlingClickEvent){
  isHandlingClickEvent=true
  setTimeout(()=>{
    isHandlingClickEvent=false
  },1000)
  let target = e?.target
  let buttonName = ' '
  if (target) {
    //如果是图片或者是视频，则获取其URL
    if (target.nodeName === 'IMG' || target.nodeName === 'VIDEO') {
      //如果URL长度过长，则说明是base64
      if(target.currentSrc.length<200){
        buttonName = target.currentSrc
      }
    } else {
      //其他情况则取子元素内部最短的文本
      let filterArr = getAllTextNodes(e.target).filter(v => Boolean(v))
      buttonName = filterArr[0]
      filterArr.forEach(v => {
        buttonName = v.length < buttonName.length ? v : buttonName
      })
    }
  }

  localStorage.setItem('buttonName', buttonName)
}

function getAllTextNodes(element) {
  let textArr = []
  // 递归函数，用于收集文本节点
  function collectTextNodes(el) {
    // 遍历当前元素的所有子节点
    el.childNodes.forEach(node => {
      // 检查节点类型是否为文本节点
      if (node.nodeType === Node.TEXT_NODE) {
        textArr.push(node.textContent.trim())
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // 如果是元素节点，则递归调用
        collectTextNodes(node);
      }
      // 忽略其他类型的节点，如注释节点等
    });
  }

  // 从给定的元素开始收集文本节点
  collectTextNodes(element);

  // 返回所有文本节点
  return textArr;
}

