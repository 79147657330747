<template>
  <div class="lottery-box">
    <LuckyWheel
        ref="myLucky"
        width="34rem"
        height="34rem"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        :default-config="defaultConfig"
        @start="startLottery"
        @end="endCallback"
        v-if="isShow"
    />
    <lottery-dialog v-if="isShowLottery" :messageObj="messageObj" @close="isShowLottery=false"></lottery-dialog>
    <recodes-dialog :activityNumber="info.activityNumber" v-if="isShowRecodes" @close="isShowRecodes=false"></recodes-dialog>
  </div>

</template>



<script setup>
// 开始写逻辑
// 首先设置转速 然后设置文字分割
// 拆分 将背景拆分 将按钮拆分 然后将每个旋转的内容拆分 还有控制旋转最后的位置拆分
import { nextTick, onMounted, ref } from "vue";
import {lotteryDraw,lotteryQueryOne} from "@/api/lottery"
import LotteryDialog from "@/views/activity/components/lottery-dialog.vue";
import RecodesDialog from './recode-dialog.vue'
import { ElMessage } from "element-plus";
import { getIfLogin, safeButton } from "@/api/login";
import emitter from "@/utils/eventBus";
import { getTime } from "@/utils/utils";
import { useUserStore } from "@/store/user";

const myLucky=ref(null)

const isShowLottery=ref(false)
const isShowRecodes=ref(false)


const  blocks=ref( [
  { padding:'3.5rem' }
])

const info=ref()
const prizes=ref([])
const isShow=ref(false)
const imgArr=[
  {name:'20元',img:require('../img/lottery/20.png')},
  {name:'50元',img:require('../img/lottery/50.png')},
  {name:'80元',img:require('../img/lottery/80.png')},
  {name:'100元',img:require('../img/lottery/100.png')},
  {name:'显示器',img:require('../img/lottery/monitor.png')},
  {name:'鼠标垫',img:require('../img/lottery/mouse-pad.png')}
]

//判断是否登录
const isLogin=()=>{
  return new Promise((resolve,reject)=>{
    getIfLogin().then((res) => {
      if (res.code != 200) {
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
          useUserStore().removeUser(); // 删除本地存储的用户信息
        }
        emitter.emit('openLogin',{ openLogin:  true})
        reject()
      }else{
        resolve()
      }
    });
  })
}


onMounted(()=>{
  lotteryQueryOne().then(res=>{
    if (res.code===500){
      ElMessage({
        message: res.message,
        type: "error",
      })
      return
    }
    info.value=res.data
    prizes.value= res.data.itemS.map((v,i)=>{
      let img= imgArr.find(o=>o.name===v.prizeName)?.img
      return {
        fonts:[{text:v.prizeName,fontColor:'#AA625B',top:'0.5rem',fontSize:'1.5rem'}],
        background:i%2===0?'#FFF2E6':'#FFFFFF',
        imgs:[{src:img,width:'5rem',height:'6rem',top:'2rem'}]
      }
    })
    isShow.value=true
    nextTick(()=>{
      myLucky.value.init()
    })
  })

})


const  buttons= [
  { radius: '20%', background: '#FFFFFF' },
  {imgs:[{src:require('../img/lottery.png'),top:'-5rem',width:'10rem',height:'10rem'}]}
]
const defaultConfig={
  stopRange:0.8,
}

const messageObj=ref({
  title:'',
})
const lotteryStart=ref(true)


const startLottery=()=>{
  // 执行埋点逻辑
  let referer = document.referrer
  if (referer === '') {
    referer = '直接访问'
  } else if (referer.indexOf('?') !== -1) {
    referer = referer.substring(0, referer.indexOf('?'))
  }

  let timer = Date.now()
  let data = {
    page: '抽奖活动页面', //来源页面
    targetPage: '抽奖', //目标页面
    clickEvent: '抽奖', //按钮名称
    client: 'PC', //终端
    visitTime: getTime(timer), //访问时间
    source: referer
  }
  safeButton(data)





  isLogin().then(()=>{
    startCallback()
  })
}



// 点击抽奖按钮会触发star回调
const startCallback= ()=> {
   if (!lotteryStart.value){
     return
   }
  lotteryStart.value=false
  lotteryDraw(info.value.activityNumber).then(res=>{
      if (res.code===200){
        // 调用抽奖组件的play方法开始游戏
        myLucky.value.play()

        //找到返回的奖品对应的下标
        let price= res.data.prizeName
        let index= prizes.value.findIndex(v=>v.fonts[0].text===price)

        if(price==='谢谢惠顾'){
          messageObj.value.title='未中奖通知'
        }else{
          messageObj.value.title=price
        }
        setTimeout(() => {
          // 调用stop停止旋转并传递中奖索引
          myLucky.value.stop(index)
        }, 3000)

      }else{
        ElMessage({
          message: res.message,
          type: "error",
        });
        lotteryStart.value=true
      }
  })
}



// 抽奖结束会触发end回调
const endCallback= (prize)=> {
  isShowLottery.value=true
  lotteryStart.value=true
}


defineExpose({
  isShowRecodes
})


</script>

<style scoped lang="less">
.lottery-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
</style>
