<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="menustatus">
    <div class="menustatusmiddle">
      <div style="display: flex;">
        <div class="fontstyle">1</div>
        <div class="fontsite">确认产品配置信息</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex;">
        <div class="fontstyleorther">2</div>
        <div class="fontsiteorther">支付订单</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">3</div>
        <div class="fontsiteorther">填写收货地址</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">4</div>
        <div class="fontsiteorther">填写发票信息</div>
      </div>
    </div>
  </div>
  <div class="containerContent">
    <div class="contentpart">
      <div class="diy">
        <div class="reset">
          <div style="display: flex;height: 32px;margin-bottom: 17px;">
            <img src="./image/title-1.png">
            <div class="reset-1">购买商品</div>
          </div>
          <div class="reset-2">
              <img :src="pictures" style="width: 160px;height: 160px;position: absolute;left: 0;">
              <div v-if="productName" class="reset-3">{{ productName }}</div>
              <div v-else class="reset-3">{{ planNameData }} {{ configNameData }}</div>
              <div class="reset-4">{{configType}}</div>
              <div class="reset-5">主机数量：x{{num}}</div>
              <div class="reset-6">主机价格：
                <span class="reset-8">¥ {{(configPrice*1).toFixed(2)}}</span>
              </div>
              <div class="reset-9">
                <div style="display: flex;">
                  <img src="./image/ticked.png" />
                  <div class="reset-9-1">精选正品货源</div>
                </div>
                <div style="display: flex;margin-left: 33px;">
                  <img src="./image/ticked.png" />
                  <div class="reset-9-1">24H顺丰发货</div>
                </div>
                <div style="display: flex;margin-left: 33px;">
                  <img src="./image/ticked.png" />
                  <div class="reset-9-1">7天无理由退换</div>
                </div>
                <div style="display: flex;margin-left: 33px;">
                  <img src="./image/ticked.png" />
                  <div class="reset-9-1">整机3年质保</div>
                </div>
              </div>
              <div class="reset-10" @click="CPMPopUp">产品配置信息</div>
          </div>
        </div>
        <div class="reset-11" v-if="productCustomizedMaterialPOS?.length > 0 && !checkStateshow">
          <div style="display: flex;height: 32px;margin-bottom: 18px;">
            <img src="./image/title-2.png">
            <div class="reset-1">宁美企业客制</div>
          </div>
          <div :class="item.changeState == 1 && item.checkState == 1 ? 'reset-12-plus' : 'reset-12'" :style="item.type == 0 && item.changeState == 1 ? 'height: 11.69rem;' : ''" v-for="(item,index) in productCustomizedMaterialPOS" v-show="item?.checkState == 1">
            <div class="reset-13">
              <img :src="item.checkState == 1 ? require('./image/ischeck.png') : require('./image/checkcopy.png')">
            </div>
            <div :class="item.changeState == 1 ? 'reset-14-plus' : 'reset-14'">{{item.materialName}}</div>
            <div class="reset-15">{{item.standard}}</div>
            <div class="reset-15-plus" v-if="item.type == 1 && item.changeState == 1">
              <div class="reset-15-plus-1">
                <img :src="item.customerLogos[0].img" style="width: 48px;height: 48px;">
              </div>
              <div class="reset-15-plus-2">{{item.originalFilename}}</div>
            </div>
            <div :class="item.changeState == 1 ? 'reset-16-plus' : 'reset-16'">x{{num}}</div>
            <div :class="item.changeState == 1 ? 'reset-17-plus' : 'reset-17'">¥ {{item.price}}</div>
            <div class="reset-18" @click="customMade(item.id,index)">{{item.changeState == 0 ? '我要定制' : item.type == 0 ? '修改图片' : '修改选项'}}</div>
            <div class="reset-19" v-if="item.changeState == 1 && item.type == 0">
              <div class="reset-19-1">
                <div class="reset-19-2">
                  <img :src="item.customerLogos[0].img" style="width: 48px;height: 48px;">
                </div>
                <div class="reset-19-3">{{item.originalFilename}}</div>
              </div>
              <div style="height: 70px;display: flex;">
                <div class="reset-19-4" v-for="(littleImg,index) in item.customizedMaterialSamplePOS">
                  <img :src="littleImg.samplePictures[0].img" style="width: 48px;height: 48px;">
                  <div class="reset-19-5">{{littleImg.samplePictureName}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-list">
          <el-dialog
            style="width: 62%; height: 76%; --el-dialog-margin-top: 10vh"
            v-model="popUpList"
          >
            <div class="border-content">
              <el-table
                :data="orderMaterialVOSMaterial"
                height="32.5rem"
                stripe
                style="width: 100%"
              >
                <el-table-column prop="materialName" label="物料名称" width="380" />
                <el-table-column prop="count" label="数量" align="center"/>
                <el-table-column prop="materialType" label="物料类型" align="center"/>
                <el-table-column prop="materialCode" label="物料编码" align="center"/>
              </el-table>
              <div class="qOk" @click="popUpList = false">确定</div>
            </div>
          </el-dialog>
        </div>
        <HostCustome v-model="showCustom" />
	      <!-- 优惠券 -->
	      <div class="coupon-container" v-if="couponList.length">
		      <div class="title">
			      <img
					      src="./image/title-2.png"
					      alt=""
					      style="width: 32px; height: 32px"
			      />
			      <span>优惠信息</span>
		      </div>
		      <div class="coupon-list" v-if="couponList.length">
			      <div v-for="item in couponList" class="coupon-li">
				      <div class="coupon-li-left">
					      <div class="amount"><span>￥</span>{{ item.amount }}</div>
					      <span>满 {{ item.amountOver }} 元可用</span>
					      <span>有效期至 {{ item.validityEnd }}</span>
				      </div>
				      <div class="coupon-li-right" @click="checkCoupon(item)">
					      <img v-if="item.isCheck" src="./image/isTrue.png" alt="" />
					      <img v-else src="./image/isFalse.png" alt="" />
				      </div>
			      </div>
		      </div>
		      <div style="text-align: center" v-else>
			      <!--<img src="./image/default1.png" style="width: 320px;height: 200px;">
			      <div style="font-family: Microsoft YaHei;font-weight: 400;font-size: 18px;color: #999999;">
				      暂无优惠券
			      </div>-->
		      </div>
	      </div>
        <!-- <div class="diy21"></div> -->
        <div class="diy22plus">
          <div class="diy22line"></div>
          <div class="fixed-container">
            <div style="display:flex;align-items: center">
	            <div class="diy22">请选择购买数量</div>
	            <div class="diy23">
		            <el-input-number
				            @change="totalNum"
				            class="plusmiuse"
				            v-model="num"
				            :min="1"
				            :step="1"
				            step-strictly
		            />
	            </div>
	            <button class="diy24" @click="btnNum(10)">10</button>
	            <button class="diy25" @click="btnNum(20)">20</button>
	            <button class="diy26" @click="btnNum(30)">30</button>
            </div>
	          <div style="display:flex;align-items: center;">
		          <div class="autodiy">
			          <div style="display: flex">
				          <div class="diy27">订单金额：</div>
				          <div class="diy28">¥ {{ (configPrice * num - discountedPrice).toFixed(2) }}</div>
			          </div>
			          <div class="autodiy-bottom" v-if="discountedPrice">
				          <div>已优惠金额：</div>
				          <div>-￥{{discountedPrice}}</div>
			          </div>
		          </div>
		          <el-button class="diy29" @click="submitOrd">提交订单</el-button>
	          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppHeader from "@/components/AppHeader";
import HostCustome from "@/components/hostCustomer";
import {getFollow, submitOrder} from "@/api/order";
import {PostCustomizedOrder} from "../../api/customize/index.js";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {Encrypt} from "@/utils/aes";
import emitter from "@/utils/eventBus";
import {couponListMy} from "@/api/coupon";

const route = useRoute();
const timevalue = ref(Date.now() + 1000 * 60 * 60 * 7);
const isCheck = ref(1);
const selectPay = (val) => {
  isCheck.value = val;
};
const num = ref(1);
const supplies = ref([]);
const giftlist = ref([]);
const totalData = ref([]);
const configPrice = ref();
const pictures = ref();
const productName = ref();
const configCodeData = ref(); //定制页面的关键字
const countNumber = ref(); // 定制页面数量
const unitPrice = ref(); // 定制页面单价
const followIdData = ref();
const followNameData = ref();
const followMobileData = ref();
const configNameData = ref();
const configCodeDataList = ref();
const planNameData = ref();
const configType = ref('');
const orderMaterialVOSMaterial = ref([]);
const productCustomizedMaterialPOS = ref([]);
const popUpList = ref(false);
const pictureImage = ref();
const id = ref();
const showCustom = ref(false);
emitter.on("confimCustomClose", (val) => {
  showCustom.value = false
})
const customMade = (id,inx) => {
  const token = localStorage.getItem("token");
  if (token) {
    showCustom.value = true
    emitter.emit("customId", { customId: id, configPrice : configPrice.value, productList : productCustomizedMaterialPOS.value,proId:inx});
  }else {
    emitter.emit("openLogin", { openLogin: true });
  }
}
emitter.on("customClose", (val) => {
  if(val.customClose){
    productCustomizedMaterialPOS.value = val.productCustomizedMaterialPOS
    if(val.configPrice){
      configPrice.value = val.configPrice
    }
    showCustom.value = false
  }
})
const CPMPopUp = () => {
  popUpList.value = true;
  orderMaterialVOSMaterial.value = []
  orderMaterialVOSMaterial.value = JSON.parse(JSON.stringify(supplies.value))
  giftlist.value.forEach(item=>{
    let data = {
      materialName : item.giftName,
      materialCode : item.giftCode,
      count : item.count,
      materialType : '赠品',
    }
    orderMaterialVOSMaterial.value.push(data)
  })
	console.log(giftlist.value,"物料")
};
const btnNum = (val) => {
  num.value = val;
  totalNum();
};
const totalNum = () => {
  if (countNumber.value) {
    countNumber.value = num.value;
  } else {
    totalData.value.orderInfoPO.count = num.value;
    totalData.value.orderInfoPO.amount = Number(num.value * configPrice.value);
  }
	getCouponList(num.value * configPrice.value);
};

const discountedPrice = ref(0); // 优惠金额
const receiveNumber = ref(); // 选择的优惠券领取id
// 勾选优惠券
const checkCoupon = (couponData) => {
	if(couponData.isCheck){
		discountedPrice.value = 0;
		couponData.isCheck = false;
		receiveNumber.value = null;
		discountedPrice.value = 0;
	}else{
		couponList.value.forEach((item) => {
			if (item.isCheck == true) {
				item.isCheck = false;
			}
		});
		couponData.isCheck = true;
		receiveNumber.value = couponData.receiveId;
		discountedPrice.value = couponData.amount;
	}
};
const couponList = ref([]);
// 查询可用优惠券
const getCouponList = (orderAmount) => {
	discountedPrice.value = 0;
	receiveNumber.value = null; // 重置优惠券
	couponListMy(Number(orderAmount))
			.then((res) => {
				if (res.code == 200) {
					res.data.forEach((item) => {
						item.isCheck = false;
					})
					couponList.value = res.data;
				}
			})
			.catch((err) => {
				console.log(err);
			});
};

const router = useRouter();
let flag = ref(true); // 防重点击
const submitOrd = () => {
  if (flag.value) {
    flag.value = false;
    if (configCodeData.value) {
      const dataValue = {
        customizedConfigGiftPOS: giftlist.value || [], // 赠品
        customizedSupportPOS: supplies.value, // 数组
        configPrice: Math.round((configPrice.value * countNumber.value - discountedPrice.value)*100)/100, // 总价价格
        countNumber: countNumber.value, // 数量
        unitPrice: unitPrice.value, // 单价
        configName: configNameData.value,
        configCode: configCodeDataList.value,
        planName: planNameData.value,
        followId: followIdData.value,
        followName: followNameData.value,
        followMobile: followMobileData.value,
        picture: pictures.value,
        productCustomizedMaterialPOS: productCustomizedMaterialPOS.value,
        id: id.value,
	      receiveId: receiveNumber.value
      };
      if (countNumber.value && configPrice.value) {
        let aesData = Encrypt(JSON.stringify(dataValue));
        let datas = {
          encryptData: aesData,
        };
        PostCustomizedOrder(datas).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "提交订单成功",
              type: "success",
            });
            setTimeout(function () {
              router.push({
                path: "/payorder",
                query: {
                  orderNumber: res.data.msg,
                },
              });
            }, 500);
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
          flag.value = true;
        });
      }
    } else {
      totalData.value.orderProductVO.configPrice =
          (totalData.value.orderProductVO.orderMaterialVOS.configPrice * 1).toFixed(2);
      totalData.value.orderProductVO.configType =
          totalData.value.orderProductVO.orderMaterialVOS.configType;
      totalData.value.orderProductVO.num =
          totalData.value.orderProductVO.orderMaterialVOS.num;
      totalData.value.orderProductVO.sort =
          totalData.value.orderProductVO.orderMaterialVOS.sort;
      totalData.value.orderInfoPO.key = new Date();
	    totalData.value.orderInfoPO.amount = Math.round((totalData.value.orderInfoPO.amount - discountedPrice.value)*100)/100 ; // 减去优惠金额
      totalData.value.orderProductVO.orderGiftVOS.forEach((item, index) => {
        item.materialName = item.giftName;
        item.materialCode = item.giftCode;
      });
      delete totalData.value.orderProductVO.orderMaterialVOS;
      delete totalData.value.orderInfoPO.behaviourPictures;
      delete totalData.value.orderInfoPO.detailPictures;
      delete totalData.value.orderInfoPO.normsPictures;
      delete totalData.value.orderInfoPO.policyPictures;
      delete totalData.value.orderInfoPO.publicizePictures;
      delete totalData.value.orderInfoPO.reviews;
      delete totalData.value.orderInfoPO.videos;
      totalData.value.orderProductVO.orderMaterialVOS = supplies.value;
      totalData.value.orderProductVO.productCustomizedMaterialPOS = productCustomizedMaterialPOS.value;
			totalData.value.receiveId = receiveNumber.value;
      if (num.value && configPrice.value) {
        let aesData = Encrypt(JSON.stringify(totalData.value));
        let datas = {
          encryptData: aesData,
        };
        submitOrder(datas).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "提交订单成功",
              type: "success",
            });
            setTimeout(function () {
              router.push({
                path: "/payorder",
                query: {
                  orderNumber: res.data.msg,
                },
              });
            }, 500);
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
          flag = true;
        });
      }
    }
  } else {
    ElMessage({
      message: "请勿重复点击！",
      type: "warning",
    });
  }
};
const checkStateshow = ref(false)
onMounted(() => {
  const token = localStorage.getItem("token");
  if (!token) {
    emitter.emit("openLogin", { openLogin: true });
  }
  const tempdata = JSON.parse(route.query.item);
  const customizationId = route.query.id;
  // 定制方案
  if (customizationId) {
    productCustomizedMaterialPOS.value = tempdata.CustomizedConfig.productCustomizedMaterialPOS
    if(productCustomizedMaterialPOS.value){
      checkStateshow.value = productCustomizedMaterialPOS.value.every(item => {
        return item.checkState == 0
      })
    }
    configType.value = tempdata.CustomizedConfig.configName
    configNameData.value = tempdata.CustomizedConfig.configName;
    configCodeDataList.value = tempdata.CustomizedConfig.configCode;
    planNameData.value = tempdata.CustomizedConfig.planName;
    configCodeData.value = tempdata.CustomizedConfig.configCodeValue;
    configPrice.value = (tempdata.CustomizedConfig.configPrice*1);
    giftlist.value = tempdata.CustomizedConfig.customizedConfigGiftPOS;
    countNumber.value = tempdata.CustomizedConfig.count;
    unitPrice.value = tempdata.CustomizedConfig.configPrice;
    followIdData.value = tempdata.CustomizedConfig.followId;
    followNameData.value = tempdata.CustomizedConfig.followName;
    followMobileData.value = tempdata.CustomizedConfig.followMobile;
    pictures.value = tempdata.CustomizedConfig.pictures;
    pictureImage.value = tempdata.CustomizedConfig.picture;
    id.value = tempdata.CustomizedConfig.id;
    getFollow().then((res) => {
      if (res.code == 200) {
        followIdData.value = res.data.followId;
        followNameData.value = res.data.followName;
        followMobileData.value = res.data.followMobile;
      }
    });
    const templies = tempdata.CustomizedConfig.customizedSupportPOS;
    if (templies) {
      if (templies.slice(0, 1) && templies.slice(0, 1)[0].materialSwitch == 0) {
        templies.slice(0, 1).forEach((element1) => {
          supplies.value.push({
            materialType: "CPU",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(1, 2) && templies.slice(1, 2)[0].materialSwitch == 0) {
        templies.slice(1, 2).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "显卡",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(2, 3) && templies.slice(2, 3)[0].materialSwitch == 0) {
        templies.slice(2, 3).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "主板",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(3, 4) && templies.slice(3, 4)[0].materialSwitch == 0) {
        templies.slice(3, 4).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "内存",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(4, 5)) {
        templies.slice(4, 5).forEach((element1) => {
          // console.log(element1);
          element1.children.forEach((element2) => {
            if (element2.materialName != "" && element2.materialSwitch == 0) {
              supplies.value.push({
                materialType: "硬盘" + element2.sort,
                materialName: element2.materialName,
                materialCode: element2.materialCode,
                count: element2.count,
              });
            }
          });
        });
      }
      if (templies.slice(5, 6) && templies.slice(5, 6)[0].materialSwitch == 0) {
        templies.slice(5, 6).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "散热器",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(6, 7) && templies.slice(6, 7)[0].materialSwitch == 0) {
        templies.slice(6, 7).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "电源",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(7, 8) && templies.slice(7, 8)[0].materialSwitch == 0) {
        templies.slice(7, 8).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "机箱",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(8, 9) && templies.slice(8, 9)[0].materialSwitch == 0) {
        templies.slice(8, 9).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "机箱风扇",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(9, 10)){
        if (templies.slice(9, 10) && templies.slice(9, 10)[0].materialSwitch == 0) {
          templies.slice(9, 10).forEach((element1) => {
            // console.log(element1);
            supplies.value.push({
              materialType: "外设及配件",
              materialName: element1.materialName,
              materialCode: element1.materialCode,
              count: element1.count,
            });
          });
        }
      }
      if (templies.slice(10, 11) && templies.slice(10, 11)[0].materialSwitch == 0) {
        templies.slice(10, 11).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "显示器",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(11, 12) && templies.slice(11, 12)[0].materialSwitch == 0) {
        templies.slice(11, 12).forEach((element1) => {
          // console.log(element1);
          supplies.value.push({
            materialType: "系统",
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            count: element1.count,
          });
        });
      }
      if (templies.slice(12, 13)) {
        templies.slice(12, 13).forEach((element1) => {
          // console.log(element1);
          element1.children.forEach((element2) => {
            if (element2.materialName != "" && element2.materialSwitch == 0) {
              supplies.value.push({
                materialType: "技术支持" + element2.sort,
                materialName: element2.materialName,
                materialCode: element2.materialCode,
                count: element2.count,
              });
            }
          });
        });
      }
    }
	  getCouponList(configPrice.value);
  } else {
    const templies = tempdata.orderProductVO.orderMaterialVOS;
    productCustomizedMaterialPOS.value = tempdata.productCustomizedMaterialPOS;
    if(productCustomizedMaterialPOS.value){
      checkStateshow.value = productCustomizedMaterialPOS.value.every(item => {
        return item.checkState == 0
      })
    }
    configType.value = tempdata.orderProductVO.orderMaterialVOS.configType;
    tempdata.orderProductVO.configType = tempdata.orderProductVO.orderMaterialVOS.configType
    if(tempdata.orderProductVO.number){
      num.value = tempdata.orderProductVO.number
    }
    configPrice.value = (tempdata.orderProductVO.orderMaterialVOS.configPrice*1).toFixed(2);
    pictures.value = tempdata.orderInfoPO.pictures[0].img;
    productName.value = tempdata.orderInfoPO.productName;
    tempdata.orderInfoPO.price = (configPrice.value*1).toFixed(2);
    tempdata.orderInfoPO.productId = route.query.productId;
    tempdata.orderInfoPO.count = num.value;
    tempdata.orderInfoPO.amount =(configPrice.value*num.value).toFixed(2);
    tempdata.orderProductVO.picture = pictures.value;
    tempdata.orderProductVO.productCode = tempdata.orderInfoPO.productCode;
    delete tempdata.orderInfoPO.id;
    giftlist.value = tempdata.orderProductVO.orderGiftVOS;
    totalData.value = tempdata;
    getFollow().then((res) => {
      if (res.code == 200) {
        totalData.value.orderInfoPO.followId = res.data.followId;
        totalData.value.orderInfoPO.followName = res.data.followName;
        totalData.value.orderInfoPO.followMobile = res.data.followMobile;
      }
    });
    if (templies) {
      if (templies.configParent1ype01) {
        templies.configParent1ype01.forEach((element1, index) => {
          supplies.value.push({
            materialType: element1.typeName,
            materialName: element1.materialName,
            materialCode: element1.materialCode,
            materialId: element1.materialId,
            count: element1.count,
          });
        });
      }
      if (templies.configParent2ype02) {
        templies.configParent2ype02.forEach((element2, index) => {
          if (element2.materialName != "") {
            supplies.value.push({
              materialType: element2.typeName,
              materialName: element2.materialName,
              materialCode: element2.materialCode,
              materialId: element2.materialId,
              count: element2.count,
            });
          }
        });
      }
      if (templies.configParent3ype03) {
        templies.configParent3ype03.forEach((element3, index) => {
          supplies.value.push({
            materialType: element3.typeName,
            materialName: element3.materialName,
            materialCode: element3.materialCode,
            materialId: element3.materialId,
            count: element3.count,
          });
        });
      }
      if (templies.configParent4ype04) {
        templies.configParent4ype04.forEach((element4, index) => {
          if (element4.materialName != "") {
            supplies.value.push({
              materialType: element4.typeName,
              materialName: element4.materialName,
              materialCode: element4.materialCode,
              materialId: element4.materialId,
              count: element4.count,
            });
          }
        });
      }
      if (templies.configParent5ype05) {
        templies.configParent5ype05.forEach((element5, index) => {
          supplies.value.push({
            materialType: element5.typeName,
            materialName: element5.materialName,
            materialCode: element5.materialCode,
            materialId: element5.materialId,
            count: element5.count,
          });
        });
      }
      if (templies.configParent6ype06) {
        templies.configParent6ype06.forEach((element6, index) => {
          if (element6.materialName != "") {
            supplies.value.push({
              materialType: element6.typeName,
              materialName: element6.materialName,
              materialCode: element6.materialCode,
              materialId: element6.materialId,
              count: element6.count,
            });
          }
        });
      }
    }
		getCouponList(configPrice.value);
  }

});
</script>
<style lang="less" scoped>
.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;
    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }
    .el-dialog__close:hover {
      color: #ce1200;
    }
  }
  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;
    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.containerContent {
	margin: 0 auto;
  width: 1920px;
  min-height: 723px;
  background: #f2f2f2;
  /* position: relative; */
  .contentpart {
    /* position: absolute;
    left: 360px;
    top: 40px; */
    /* min-height: 633px; */
    margin-top: 40px !important;
	  margin: 0 auto;
    width: 1200px;
    /* height: 869px; */
    background: #ffffff;
    .diy {
      overflow: hidden;
      .reset-11{
        /* height: 242px; */
        width: 998px;
        margin-left: 101px;
        margin-bottom: 33px;
        .reset-1{
          line-height: 32px;
          margin-left: 6px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }
        .reset-12-plus{
          width: 998px;
          height: 98px;
          background: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #ce1200;
          position: relative;
          margin-bottom: 14px;
          .reset-13{
            position: absolute;
            top: 36px;
            left: 23px;
          }
          .reset-14{
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            position: absolute;
            top: 24px;
            left: 61px;
          }
          .reset-14-plus{
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #ce1200;
            position: absolute;
            top: 24px;
            left: 61px;
          }
          .reset-15{
            width: 517px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: 56px;
            left: 61px;
          }
          .reset-15-plus{
            height: 61px;
            width: 48px;
            position: absolute;
            top: 15px;
            right: 328px;
            .reset-15-plus-2{
              text-align: center;
              width: 48px;
              margin-top: 5px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .reset-16{
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 710px;
          }
          .reset-16-plus{
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #ce1200;
            position: absolute;
            top: 39px;
            left: 710px;
          }
          .reset-17{
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 761px;
          }
          .reset-17-plus{
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #ce1200;
            position: absolute;
            top: 39px;
            left: 761px;
          }
          .reset-18{
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #AAAAAA;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 32px;
            &:hover{
              color: #ce1200;
              border: 1px solid #ce1200;
              cursor: pointer;
            }
          }
          .reset-19{
            height: 40px;
            width: 517px;
            display: flex;
            margin-top: 92px;
            margin-left: 64px;
            .reset-19-4{
              width: 48px;
              text-align: center;
              margin-right: 24px;
              .reset-19-5{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                width: 48px;
                margin-top: 6px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .reset-19-1{
              width: 108px;
              height: 40px;
              text-align: center;
              margin-right: 44px;
              .reset-19-2{
                margin: 0 auto;
                width: 48px;
                height: 48px;
                border: 1px solid #E1E1E1;
              }
              .reset-19-3{
                margin-top: 6px;
                width: 108px;
                height: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
        .reset-12{
          width: 998px;
          height: 98px;
          background: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #D9D9D9;
          position: relative;
          margin-bottom: 14px;
          .reset-13{
            position: absolute;
            top: 36px;
            left: 23px;
          }
          .reset-14{
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            position: absolute;
            top: 24px;
            left: 61px;
          }
          .reset-15{
            width: 517px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: 56px;
            left: 61px;
          }
          .reset-16{
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 710px;
          }
          .reset-17{
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 761px;
          }
          .reset-18{
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #AAAAAA;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 32px;
            &:hover{
              color: #ce1200;
              border: 1px solid #ce1200;
              cursor: pointer;
            }
          }
          .reset-19{
            height: 40px;
            width: 517px;
            display: flex;
            margin-top: 92px;
            margin-left: 64px;
            .reset-19-4{
              width: 48px;
              text-align: center;
              margin-right: 24px;
              .reset-19-5{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                width: 48px;
                margin-top: 6px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .reset-19-1{
              width: 108px;
              height: 40px;
              text-align: center;
              margin-right: 44px;
              .reset-19-2{
                margin: 0 auto;
                width: 48px;
                height: 48px;
                border: 1px solid #E1E1E1;
              }
              .reset-19-3{
                margin-top: 6px;
                width: 108px;
                height: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
      .reset{
        height: 242px;
        width: 998px;
        margin: 48px 0 0 101px;
        .reset-1{
          line-height: 32px;
          margin-left: 6px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }
        .reset-2{
          width: 998px;
          height: 160px;
          position: relative;
          .reset-3{
            position: absolute;
            left: 192px;
            top: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 17px;
            color: #333333;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          .reset-4{
            position: absolute;
            left: 192px;
            top: 46px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #555555;
          }
          .reset-5{
            position: absolute;
            left: 192px;
            top: 72px;
            height: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #555555;
          }
          .reset-6{
            position: absolute;
            left: 330px;
            top: 72px;
            height: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #555555;
            .reset-8{
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 15px;
              color: #ce1200;
            }
          }
          .reset-9{
            height: 16px;
            position: absolute;
            left: 192px;
            top: 121px;
            display: flex;
            .reset-9-1{
              //width: 73px;
              height: 13px;
              margin-left: 5px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
            }
          }
          .reset-10{
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #ce1200;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 20px;
          }
        }
      }
      .diy1 {
        position: absolute;
        left: 150px;
        top: 97px;
        width: 240px;
        height: 240px;
      }
      .diy2 {
        position: absolute;
        width: 540px;
        // height: 18px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        left: 491px;
        top: 64px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .diy3 {
        position: absolute;
        left: 491px;
        top: 107px;
        width: 89px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
      }
      .diy4 {
        position: absolute;
        left: 592px;
        top: 106px;
        width: 11px;
        height: 14px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #555555;
      }
      .diy5 {
        position: absolute;
        left: 610px;
        top: 100px;
        width: 86px;
        height: 18px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #555555;
      }
      .diy6 {
        position: absolute;
        left: 493px;
        top: 153px;
        width: 85px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
      .diy7 {
        position: absolute;
        left: 163px;
        top: 363.5px;
        width: 16px;
        height: 16px;
      }
      .diy8 {
        position: absolute;
        left: 184px;
        top: 364px;
        width: 73px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .diy9 {
        position: absolute;
        left: 294px;
        top: 365px;
        width: 16px;
        height: 16px;
      }
      .diy10 {
        position: absolute;
        left: 315px;
        top: 364px;
        width: 75px;
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .diy11 {
        position: absolute;
        left: 163px;
        top: 405px;
        width: 16px;
        height: 16px;
      }
      .diy12 {
        position: absolute;
        left: 184px;
        top: 405px;
        width: 83px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .diy13 {
        position: absolute;
        left: 294px;
        top: 404.5px;
        width: 16px;
        height: 16px;
      }
      .diy14 {
        position: absolute;
        left: 315px;
        top: 404.5px;
        width: 74px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .checkborder {
        border: 1px solid #ce1200 !important;
        position: absolute;
        left: 70px;
        top: 342px;
        width: 220px;
        height: 60px;
      }

      .diy16 {
        padding-left: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .diy17 {
        padding-left: 35px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .diy18 {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .diy20 {
        /* position: absolute;
        left: 491px;
        top: 183px; */
        padding: 183px 0 0 491px;
        width: 540px;
        /* background: red; */
        .diy15 {
          width: 540px;
          background: #f2f2f2;
          margin-top: 26px;
        }
        .diy19 {
          width: 28px;
          height: 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ce1200;
          margin-top: 26px;
          margin-bottom: 14px;
        }
      }
      .diy21 {
        margin-top: 176px;
        margin-left: 18px;
        width: 1160px;
        height: 1px;
        background: #e1e1e1;
      }
      /* .diy21 {
        margin-top: 60px;
        width: 1160px;
        height: 1px;
        background: #e1e1e1;
      }
      .diy22 {
        position: absolute;
        left: 71px;
        top: 829px;
        width: 85px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
      }
      .diy23 {
        position: absolute;
        left: 177px;
        top: 822px;
        .plusmiuse {
          width: 100px;
          height: 32px;
        }
      }
      .diy24 {
        position: absolute;
        left: 304px;
        top: 824px;
        width: 32px;
        height: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background-color: #fff;
      }
      .diy24:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
      }
      .diy25 {
        position: absolute;
        left: 343px;
        top: 824px;
        width: 32px;
        height: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background-color: #fff;
      }
      .diy25:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
      }
      .diy26 {
        position: absolute;
        left: 382px;
        top: 824px;
        width: 32px;
        height: 30px;
        border: 1px solid #d9d9d9;
        background-color: #fff;
        border-radius: 5px;
      }
      .diy26:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
      }
      .diy27 {
        position: absolute;
        left: 714px;
        top: 832px;
        width: 70px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .diy28 {
        position: absolute;
        left: 787px;
        top: 824px;
        width: 120px;
        height: 18px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ce1200;
      }
      .diy29 {
        position: absolute;
        left: 930px;
        top: 816px;
        width: 200px;
        height: 48px;
        background: #ce1200;
        border-radius: 5px;
        color: #ffffff;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      } */
    }
  }
}

.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}
.menustatus {
  width: 100%;
  height: 84px;
  background-color: #fff;
}
.menustatusmiddle {
  position: relative;
  top: 32%;
  display: flex;
	justify-content: center;
}
.fontstyle {
  width: 30px;
  height: 30px;
  background: #ce1200;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.fontstyleorther {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  text-align: center;
  line-height: 30px;
  color: #aaaaaa;
}
.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}
.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}
.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}
::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
.coupon-container {
	width: 998px;
	margin-left: 101px;
	margin-bottom: 100px;
	.title {
		display: flex;
		justify-content: left;
		align-items: center;
		span {
			margin-left: 6px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}
	.coupon-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 21px;
		margin-bottom: 14px;
		.coupon-li {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-left: 14px;
			margin-bottom: 14px;
			width: 315px;
			height: 112px;
			background: url("./image/background.png");
			background-size: cover;
			cursor: default;
			.coupon-li-left {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.amount {
					display: flex;
					align-items: center;
					font-family: Microsoft YaHei;
					font-weight: bold;
					font-size: 22px;
					color: #ce1200;
					span {
						font-weight: 400 !important;
						font-size: 14px !important;
					}
				}
				& > span:nth-child(2) {
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 12px;
					color: #ce1200;
				}
				& > span:nth-child(3) {
					margin-top: 4px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 12px;
					color: #999999;
				}
			}
			.coupon-li-right {
				cursor: pointer;
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
.diy22plus {
	width: 1200px;
	height: 68px;
	//line-height: 68px;
	background: #fff;
	/* border-top: 1px solid #E1E1E1; */
	position: fixed;
	bottom: 0px;

	.diy22line {
		width: 1150px;
		height: 1px;
		text-align: center;
		background-color: #E1E1E1;
		margin-left: 25px;
	}
	.fixed-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 0.6rem;
		.diy22 {
			margin-left: 71px;
			width: 85px;
			height: 13px;
			line-height: 13px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #595959;
		}
		.diy23 {
			margin-left: 22px;
			.plusmiuse {
				width: 100px;
				height: 32px;
			}
		}
		.diy24 {
			margin-left: 27px;
			width: 32px;
			height: 30px;
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			background-color: #fff;
		}
		.diy24:hover {
			color: #ce1200;
			border: 1px solid #ce1200;
		}
		.diy25 {
			margin-left: 7px;
			width: 32px;
			height: 30px;
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			background-color: #fff;
		}
		.diy25:hover {
			color: #ce1200;
			border: 1px solid #ce1200;
		}
		.diy26 {
			margin-left: 7px;
			width: 32px;
			height: 30px;
			border: 1px solid #d9d9d9;
			background-color: #fff;
			border-radius: 5px;
		}
		.diy26:hover {
			color: #ce1200;
			border: 1px solid #ce1200;
		}
		.autodiy {
			height: 100%;
			/* width: 60px; */
			display: flex;
			flex-direction: column;
			justify-content: center;
			//margin-top: 18px;
			.diy27 {
				height: 15px;
				line-height: 15px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
			}
			.diy28 {
				height: 18px;
				line-height: 18px;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ce1200;
			}
			.autodiy-bottom {
				display: flex;
				margin-top: 6px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 12px;
				color: #999999;
			}
		}
		.diy29 {
			margin-left: 32px;
			margin-right: 71px;
			width: 200px;
			height: 48px;
			background: #ce1200;
			border-radius: 5px;
			color: #ffffff;
			font-size: 15px;
			font-family: Microsoft YaHei;
			font-weight: 400;
		}
	}
}
</style>
