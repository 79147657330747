<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="menustatus">
    <div class="menustatusmiddle">
      <div style="display: flex;">
        <div class="fontstyle">1</div>
        <div class="fontsite">确认产品配置信息</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex;">
        <div class="fontstyle">2</div>
        <div class="fontsite">支付订单</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex" v-if="!isService">
        <div class="fontstyleorther">3</div>
        <div class="fontsiteorther">填写收货地址</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">{{isService?3:4}}</div>
        <div class="fontsiteorther">填写发票信息</div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="containerContent">
      <div class="contentpart">
        <div class="diy">
          <div class="order-money">
            <div style="display: flex;height: 40px;line-height: 40px;">
              <div class="orderMoneyText">订单总金额</div>
              <div class="orderMoneySym">￥</div>
              <div class="orderMoneyNum">{{orderInfoPO.amount}}</div>
	            <div class="orderDicsountMoney" v-if="orderInfoPO.couponAmount">（已优惠金额：￥{{orderInfoPO.couponAmount}}）</div>
            </div>
            <div class="diy10" v-if="orderInfoPO.sellOne == 0 && hiddenCount">
              <el-image
                  class="diy11"
                  :src="require('./image/alarm.png')"
              ></el-image>
              <div class="diy12">
                <el-countdown :value="totalSeconds" />
              </div>
            </div>
          </div>
          <div class="order-product-list">
            <div v-for="(item,index) in orderProductList" class="order-product">
              <el-image v-if="item.picture" class="order-product-img" :src="item.picture"></el-image>
              <div style="margin-left: 12px;">
                <div :title="item.productName" class="order-product-title">{{ item.productName }}</div>
                <div class="order-product-number">
                  <div>¥</div>
                  <div style="margin-left: 4px;">{{ item.price }}</div>
                  <div style="margin-left: 13px;">数量:</div>
                  <div style="margin-left: 7px;">x{{ item.count }}</div>
                  <div style="margin-left: 29px;">商品金额:</div>
                  <div style="margin-left: 8px;font-weight: bold;font-size: 18px;">¥</div>
                  <div style="margin-left: 5px;font-weight: bold;font-size: 24px;">{{ item.amount }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-bottom">
            <div class="container-bottom-text">选择以下支付方式付款</div>
            <div class="cut"></div>
            <div class="pay-methods">
              <div
                  @click="selectPay(1)"
                  :class="isCheck == 1 ? 'diy15 checkborder' : 'diy15'"
              >
                <div :class="isCheck == 1 ? 'checkUp' : ''"></div>
                <el-image
                    class="diy16"
                    :src="require('./image/aplay.png')"
                ></el-image>
              </div>
              <div
                  @click="selectPay(2)"
                  :class="isCheck == 2 ? 'diy17 checkborder' : 'diy17'"
              >
                <div :class="isCheck == 2 ? 'checkUp' : ''"></div>
                <el-image
                    class="diy18"
                    :src="require('./image/grop.png')"
                ></el-image>
              </div>
              <div
                  @click="selectPay(3)"
                  :class="isCheck == 3 ? 'diy19-1 checkborder' : 'diy19-1'"
              >
                <div :class="isCheck == 3 ? 'checkUp' : ''"></div>
                <el-image
                    class="diy20-1"
                    :src="require('./image/bank.png')"
                ></el-image>
              </div>
              <div
                  @click="selectPay(4)"
                  :class="isCheck == 4 ? 'diy19 checkborder' : 'diy19'"
              >
                <div :class="isCheck == 4 ? 'checkUp' : ''"></div>
                <el-image
                    class="diy20"
                    :src="require('./image/wechatpay.png')"
                ></el-image>
              </div>
              <div
                  @click="selectPay(5)"
                  :class="isCheck == 5 ? 'diy17 checkborder' : 'diy17'"
              >
                <div :class="isCheck == 5 ? 'checkUp' : ''"></div>
                <el-image
                    class="diy18"
                    :src="require('./image/unionPay.png')"
                ></el-image>
              </div>
            </div>
            <div v-if="isCheck != 3">
              <el-button class="diy21" @click="openfrom">立即支付</el-button>
              <div class="diy22"></div>
              <div class="diy23">
                TIPs：如您想走对公转账，或者单笔订单金额超过10万元人民币，请联系我们销售人员；我们将及时为您服务并跟进您的订单
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isCheck == 3">
        <div class="diy24">
          <div class="diy24-1">
            <div class="diy24-2">
              <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="17%"
                class="demo-ruleForm"
                :size="formSize"
                status-icon
                label-position="left"
              >
                <el-row>
                  <el-col :span="24"
                    ><div class="grid-content ep-bg-purple-dark">
                      <el-form-item label="付款人名称" prop="payerName">
                        <el-input
                          v-model="ruleForm.payerName"
                          placeholder="请填写付款人名称"
                        />
                      </el-form-item></div
                  ></el-col>
                </el-row>
                <el-row>
                  <el-col :span="24"
                    ><div class="grid-content ep-bg-purple-dark">
                      <el-form-item label="转账凭证" prop="title">
                        <div class="fontlittle">
                          （请上传清晰转账图片,以便于及时确认您的支付订单。只支持图片类型，最大支持10MB）
                        </div>
                      </el-form-item>
                    </div></el-col
                  >
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label-width="10px"
                      label=""
                      prop="transactionEvidence"
                      style="margin-top: -8px"
                    >
                      <el-upload
                        v-model:file-list="fileList"
                        :action="uploadBrand.url"
                        :headers="uploadBrand.headers"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="uploadSuccessOne"
                        :before-upload="beforeLogoUpload"
                      >
                        <el-icon style="position: absolute; bottom: 80px"
                          ><Plus
                        /></el-icon>
                        <div
                          style="
                            width: 49px;
                            height: 13px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #666666;
                            position: relative;
                            top: 19px; ;
                          "
                        >
                          点击上传
                        </div>
                      </el-upload>
                      <el-dialog v-model="dialogVisible">
                        <img style="width: 100%" :src="dialogImageUrl" />
                      </el-dialog>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="explain"> 补充说明 </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24"
                    ><div class="grid-content ep-bg-purple-dark">
                      <el-form-item label-width="0px">
                        <el-input
                          :rows="3"
                          type="textarea"
                          v-model="ruleForm.explains"
                          placeholder="选填，最多支持200字"
                          maxlength="200"
                          show-word-limit
                        />
                      </el-form-item></div
                  ></el-col>
                </el-row>
              </el-form>
            </div>
            <div class="diy24-3">
              <div class="diy24-4">
                <div class="diy24-5">宁美对公银行账户</div>
                <div class="diy24-6">
                  公司名称： 武汉宁美数字科技有限公司
                </div>
                <div class="diy24-7">开户行： 中国建设银行股份有限公司武汉新华支行</div>
                <div class="diy24-8">银行账号： 42050117004300001150</div>
              </div>
              <div class="diy24-9">
                <div class="diy24-10">销售经理：韩经理</div>
                <div class="diy24-11">联系电话：13995648183</div>
              </div>
            </div>
            <div class="diy25"></div>
            <div class="diy28">
              <div class="diy26">
                TIPs：如您想走对公转账，或者单笔订单金额超过10万元人民币，请联系我们销售人员；我们将及时为您服务并跟进您的订单
              </div>
              <el-button class="diy27" @click="submitForm(ruleFormRef)"
                >我已转账，下一步</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    style="width: 20%; height: 46%; position: relative"
    :show-close="false"
    destroy-on-close="true"
    v-model="isWechatVisible"
  >
    <div class="apliy-1">
      <el-image
        class="apliy1-1"
        :src="require('./image/minipay.png')"
      ></el-image>
      <div class="apliy2-1">
        微信支付
      </div>
      <div class="apliy1-2" @click="isWechatVisible1">
      </div>
    </div>
    <div class="apliy3">
      <qrcode-vue :value="wechatUrl" size="250" level="H" />
    </div>
    <div class="apliy2-3">
      请使用
      <div style="color: #ce1200">微信</div>
      扫一扫二维码完成支付
    </div>
  </el-dialog>
  <el-dialog
    style="border-radius: 20px; width: 23%; height: 30rem; position: relative"
    :show-close="false"
    destroy-on-close="true"
    v-model="isVisible"
  >
    <div class="apliy">
      <el-image
        class="apliy1"
        :src="require('./image/saoyisao.png')"
      ></el-image>
      <div class="apliy2">
        请使用
        <div style="color: #ce1200">微信、支付宝APP</div>
        扫码支付
      </div>
    </div>
    <div class="apliy3-1">
      <qrcode-vue :value="codeUrl" size="250" level="H" />
    </div>
    <div class="apliy4">
      <div class="apliy5">
        <el-image :src="require('./image/wechat2.png')"></el-image>
        <div>微信</div>
      </div>
      <div class="apliy6">
        <el-image :src="require('./image/apliy.png')"></el-image>
        <div>支付宝</div>
      </div>
    </div>
  </el-dialog>
  <div class="unionDialog">
    <el-dialog v-model="isShow">
      <template #header="{ titleId }">
        <div style="display: flex;">
          <img style="width: 39px;height: 24px;" src="./image/union.png" alt="">
          <div :id="titleId" class="unionDialog_title unionDialog_text">银行卡支付</div>
        </div>
      </template>
      <div style="text-align: center;">
        <div class="unionDialog_content unionDialog_text">
          点击支付按钮跳至
          <p style="color: #ce1200">盛付通收银台</p>
          进行支付
        </div>
        <form
            name="form1"
            id="form1"
            action="https://olbankcounter.shengpay.com/cashier.htm"
            method="post"
        >
          <table border="1" style="width: 100%" v-show="false">
            <tr>
              <td width="30%">版本号</td>
              <td>
                <input name="version" type="text" id="version" value="V5.0.0" />
              </td>
            </tr>
            <tr>
              <td width="30%">字符集</td>
              <td>
                <input
                    name="charset"
                    type="text"
                    id="charset"
                    v-model="sftParFromData.charset"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">商户号</td>
              <td>
                <input
                    name="mchId"
                    type="text"
                    id="mchId"
                    v-model="sftParFromData.mchId"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">商户平台会员号</td>
              <td>
                <input
                    name="outMemberId"
                    type="text"
                    id="outMemberId"
                    v-model="sftParFromData.outMemberId"
                />
              </td>
            </tr>
            <tr>
              <td>订单号</td>
              <td>
                <input
                    name="mchOrderNo"
                    type="text"
                    id="mchOrderNo"
                    v-model="sftParFromData.mchOrderNo"
                />
              </td>
            </tr>
            <tr>
              <td>订单金额</td>
              <td>
                <input
                    name="orderAmount"
                    type="text"
                    id="orderAmount"
                    v-model="sftParFromData.orderAmount"
                />
              </td>
            </tr>
            <tr>
              <td>提交时间</td>
              <td>
                <input
                    name="orderTime"
                    type="text"
                    id="orderTime"
                    v-model="sftParFromData.orderTime"
                />
              </td>
            </tr>
            <tr>
              <td>货币类型</td>
              <td>
                <input
                    name="currency"
                    type="text"
                    id="currency"
                    v-model="sftParFromData.currency"
                />
              </td>
            </tr>
            <tr>
              <td>随机数</td>
              <td>
                <input
                    name="nonceStr"
                    type="text"
                    id="nonceStr"
                    v-model="sftParFromData.nonceStr"
                />
              </td>
            </tr>
            <tr>
              <td>支付渠道</td>
              <td>
                <input
                    name="payChannel"
                    type="text"
                    id="payChannel"
                    v-model="sftParFromData.payChannel"
                />
              </td>
            </tr>
            <tr>
              <td>付款机构代码</td>
              <td>
                <input
                    name="instCode"
                    type="text"
                    id="instCode"
                    v-model="sftParFromData.instCode"
                />
              </td>
            </tr>
            <tr>
              <td>支付成功回调地址</td>
              <td>
                <input
                    name="pageUrl"
                    type="text"
                    id="pageUrl"
                    v-model="sftParFromData.pageUrl"
                />
              </td>
            </tr>
            <tr>
              <td>服务端通知地址</td>
              <td>
                <input
                    name="backUrl"
                    type="text"
                    id="backUrl"
                    v-model="sftParFromData.backUrl"
                />
              </td>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>
                <input
                    name="productName"
                    type="text"
                    id="productName"
                    v-model="sftParFromData.productName"
                />
              </td>
            </tr>
            <tr>
              <td>商品描述</td>
              <td>
                <input
                    name="productDesc"
                    type="text"
                    id="productDesc"
                    v-model="sftParFromData.productDesc"
                />
              </td>
            </tr>
            <tr>
              <td>买家IP地址</td>
              <td>
                <input
                    name="buyerIp"
                    type="text"
                    id="buyerIp"
                    v-model="sftParFromData.buyerIp"
                />
              </td>
            </tr>
            <tr>
              <td>扩展</td>
              <td>
                <input
                    name="ext"
                    type="text"
                    id="ext"
                    v-model="sftParFromData.ext"
                />
              </td>
            </tr>
            <tr>
              <td>签名类型</td>
              <td>
                <input
                    name="signType"
                    type="text"
                    id="signType"
                    v-model="sftParFromData.signType"
                />
              </td>
            </tr>
            <tr>
              <td>签名</td>
              <td>
                <input
                    name="sign"
                    type="text"
                    id="sign"
                    v-model="sftParFromData.sign"
                />
              </td>
            </tr>
          </table>
          <input
              type="submit"
              class="myBtn1"
              value="支&nbsp;&nbsp;付"
              style="width: 100px"
          />
        </form>
      </div>
    </el-dialog>
  </div>
  <HomeBackup />
  <!-- <AppFooter style="float: left" /> -->
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import HomeBackup from "@/views/home/components/HomeBackup";
import dayjs from "dayjs";
import { Calendar } from "@element-plus/icons-vue";
import {ref, reactive, onMounted, onUpdated, onBeforeUnmount, computed, onUnmounted} from "vue";
import { FormInstance, FormRules } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { getToken } from "@/utils/auth";
import { UploadProps, UploadUserFile, ElMessage } from "element-plus";
import QrcodeVue from "qrcode.vue";
import global from '@/global-variable.js'
import {
  submitTrade,
  submitTradeWeb,
  submitTransfer,
  OrderNumber,
  expired,
  OrderPay,
  trade,
  outTradeNo,
  weresult,
  orderTrans,
  wcplace,
  sftPlace,
} from "@/api/order/index";
import { useRoute, useRouter } from "vue-router";
import { Encrypt, Decrypt } from "@/utils/aes";
import { useUserStore } from "@/store/user";

const codeUrl = ref();
const wechatUrl = ref();
const route = useRoute();
const fileList = ref([]);
const tempFile = ref([]);
const orderInfoPO = ref({}); // 订单信息
const isVisible = ref(false);
const router = useRouter();
//process.env.VUE_APP_BASE_API +
const uploadBrand = reactive({
  headers: { Authorization: "Bearer " + getToken() },
  url: "/pay/common/upload",
});
const dialogImageUrl = ref("");
const dialogVisible = ref(false);

const handleRemove = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles);
};

//是否为服务订单跳转过来的页面(是则不需要填写收货地址)
const isService = route.query.isService;




const beforeLogoUpload = (file) => {
  const isJPG = file.type.indexOf("image/") > -1;
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isJPG) {
    ElMessage({
      message: "文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件!",
      type: "error",
    });
  }
  if (!isLt2M) {
    ElMessage({
      message: "上传头像图片大小不能超过 10MB!",
      type: "error",
    });
  }
  if (fileList.value.length > 2) {
    ElMessage({
      message: "最多上传三张图片",
      type: "error",
    });
    fileList.value.pop();
  }
  return isJPG && isLt2M;
};

const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url;
  fileList.value.push();
  dialogVisible.value = true;
};

const uploadSuccessOne = (file) => {
  if (file.code == 200) {
    tempFile.value.push(file.data.fileName);
    ruleForm.transactionEvidence = tempFile.value.toString();
  }
};

const formSize = ref("default");
const ruleFormRef = ref();
const ruleForm = reactive({
  payerName: "",
  title: " ",
  transactionEvidence: "",
});

const rules = reactive({
  payerName: [{ required: true, message: "请填写付款人名称", trigger: "blur" }],
  transactionEvidence: [
    { required: true, message: "请上传转账凭证", trigger: "blur" },
  ],
  title: [{ required: true, message: "", trigger: "blur" }],
});

const productName = ref();
const picture = ref();
const totalSeconds = ref(0);
const producdId = ref(null);
const hiddenCount = ref(true);
let orderProductList = ref([]); // 订单商品信息
const orderInfo = () => {
  const orderNumber = route.query.orderNumber;
  OrderNumber(orderNumber).then((res) => {
    if (res.code == 200) {
      let data = Decrypt(res.data)
      let secret = JSON.parse(data)
      orderProductList.value = secret.productVOS
      // 价格保留2位小数
      orderProductList.value.forEach((item) => {
        item.amount = item.amount.toFixed(2);
        item.price = item.price.toFixed(2);
      });
      productName.value = secret.productVOS[0].productName;
      picture.value = secret.productVOS[0].picture;
      producdId.value = secret.productVOS[0].productId;
      orderInfoPO.value = secret.orderInfoPO
      // 价格保留2位小数
      orderInfoPO.value.amount = orderInfoPO.value.amount.toFixed(2)
      if(orderInfoPO.value.sellOne == 0) { // 非开单订单
        if (secret.orderInfoPO.expireTime >= 0) {
          totalSeconds.value = Date.now() + 1000 * secret.orderInfoPO.expireTime;
        } else if (secret.orderInfoPO.expireTime == -1) {
          hiddenCount.value = false;
        } else {
          ElMessage({
            message: "该笔订单已支付或已超时，请前往订单中心查看",
            type: "error",
          });
          setTimeout(function () {
            router.push("/orderCenter");
          }, 500);
        }
      }
    }
  });
};
onBeforeUnmount(() => {
  clearInterval(timer.value);
});
const padto = (num) => {
  return num.toString().padStart(2, "0");
};
onMounted(() => {
  orderInfo();
});
onUnmounted(()=>{
	clearInterval(wetimer.value);
});
onUpdated(() => {});
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      const orderNumber = route.query.orderNumber;
      expired(orderNumber).then((res) => {
        if(res.data){
          ElMessage({
            message: "该笔订单已支付或已超时，请前往订单中心查看",
            type: "success",
          });
        }else{
          let data = {
            payerName: ruleForm.payerName,
            payEvidence: ruleForm.transactionEvidence,
            orderNumber: route.query.orderNumber,
            productName: orderProductList.value[0].productName,
            price: orderProductList.value[0].price,
            count: orderProductList.value[0].count,
            amount: orderInfoPO.value.amount,
            changeState: orderInfoPO.value.changeState,
            payType: 3,
            explains: ruleForm.explains,
          };
          let aesData = Encrypt(JSON.stringify(data));
          let datas = {
            encryptData: aesData,
          };
          submitTransfer(datas)
            .then((res) => {
              if (res.code == 200) {
                OrderPay(route.query.orderNumber).then((res) => {
                  if (res.code == 200) {
                    ElMessage({
                      message: "提交成功!",
                      type: "success",
                    });
                    setTimeout(function () {
                      if (isService){
                        router.push({
                          path: "/InvoiceInfo",
                          query: {
                            orderNumber: route.query.orderNumber,
                            isService:1
                          },
                        });
                      }else{
                        router.push({
                          path: "/fillinadress",
                          query: {
                            orderNumber: route.query.orderNumber,
                          },
                        });
                      }
                    }, 500);
                  } else {
                    ElMessage({
                      message: res.message,
                      type: "error",
                    });
                  }
                });
              } else {
                ElMessage({
                  message: "订单支付超时，请重新提交订单！",
                  type: "error",
                });
                setTimeout(function () {
                  /*router.push({
                    path: `/detailsGoods/${producdId.value}/${1}`,
                  });*/
	                router.push({
		                name: "detailsGoods",
		                query: {
			                id: producdId.value,
			                houseNo: 1
		                }
	                });
                }, 500);
              }
            })
        }
      })
    } else {
      console.log("error", fields);
    }
  });
};
const isWechatVisible1 = () => {
  isWechatVisible.value = false
}
const isWechatVisible = ref(false)
const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};
const isCheck = ref(2);
const selectPay = (val) => {
  isCheck.value = val;
};
const payJump = ref();
const webBank = ref();
const timer = ref();
const wetimer = ref();
const isShow = ref(false);
const sftParFromData = ref();
const openfrom = () => {
  if (isCheck.value == 1) {
    let Urlhost = window.location.hostname;
    let returnURL=''
    if (isService){
      returnURL="http://" + Urlhost + "/#/InvoiceInfo?isService=1"
    }else{
      returnURL="http://" + Urlhost + "/#/fillinadress"
    }

    let data = {
      amount: orderInfoPO.value.amount,
      orderNumber: route.query.orderNumber,
      productName: productName.value,
      returnURL,
    };
    let aesData = Encrypt(JSON.stringify(data));
    let datas = {
      encryptData: aesData,
    };
    submitTrade(datas)
      .then((res) => {
      if (res.code == 400) {
        ElMessage({
          message: res.message,
          type: "error",
        });
      } else if (res.code == 401) {
        ElMessage({
          message: "登录已过期，请重新登录",
          type: "error",
        });
        localStorage.removeItem("token");
        useUserStore().removeUser(); // 删除本地存储的用户信息
      } else {
        payJump.value = res;
        document.write(payJump.value);
      }
      })
  } else if (isCheck.value == 4) {
    let data = {
      description: productName.value,
      outTradeNo: route.query.orderNumber,
      total: orderInfoPO.value.amount
    };
    let aesData = Encrypt(JSON.stringify(data));
    let datas = {
      encryptData: aesData,
    };
    clearInterval(wetimer.value);
    wcplace(datas).then((res) => {
      if (res.code == 200) {
        wechatUrl.value = res.data
        isWechatVisible.value = true
        wetimer.value = setInterval(() => {
          weresult(route.query.orderNumber).then((res) => {
            if (res.data == "支付成功") {
              clearInterval(wetimer.value);
              ElMessage({
                message: "支付成功!",
                type: "success",
              });
              setTimeout(function () {
                if (isService){
                  router.push(
                    {
                      path: "/InvoiceInfo",
                      query: {
                        orderNumber: route.query.orderNumber,
                        isService:1
                      },
                    },
                    500
                  );
                }else{
                  router.push(
                    {
                      path: "/fillinadress",
                      query: {
                        orderNumber: route.query.orderNumber,
                      },
                    },
                    500
                  );
                }
              });
            }
          });
        }, 4000);
      } else {
        ElMessage({
          message: res.message,
          type: "error",
        });
      }
    })
    // submitTradeWeb(datas)
    //   .then((res) => {
    //     if (res.code == 400) {
    //       ElMessage({
    //         message: res.message,
    //         type: "error",
    //       });
    //     } else if (res.code == 401) {
    //       ElMessage({
    //         message: "登录已过期，请重新登录",
    //         type: "error",
    //       });
    //       localStorage.removeItem("token");
    //       useUserStore().removeUser(); // 删除本地存储的用户信息
    //     } else {
    //       window.location.href = res;
    //     }
    //   })
  } else if (isCheck.value == 5) {
    let pageUrl=''
    if (isService){
      pageUrl=global.ossPath +
        "/#/InvoiceInfo?orderNumber=" +
        orderInfoPO.value.orderNumber+'&isService=1'
    }else{
      pageUrl=global.ossPath +
        "/#/fillinadress?orderNumber=" +
        orderInfoPO.value.orderNumber
    }

    let data = {
      mchOrderNo: route.query.orderNumber,
      orderAmount: orderInfoPO.value.amount,
      pageUrl,
      productName: productName.value,
    };
    // 加密
    const aesData = Encrypt(JSON.stringify(data));
    let datas = {
      encryptData: aesData,
    };
    sftPlace(datas)
      .then((res) => {
        if (res.code == 200) {
          isShow.value = true;
          console.log(res.data);
          sftParFromData.value = res.data;
        } else if (res.code == 400) {
          ElMessage({
            message: res.message,
            type: "error",
          });
        } else if (res.code == 401) {
          ElMessage({
            message: "登录已过期，请重新登录",
            type: "error",
          });
          localStorage.removeItem("token");
          useUserStore().removeUser(); // 删除本地存储的用户信息
        }
      })
      .then((res) => {
        console.log(res);
      });
  } else {
    if (orderInfoPO.value.amount >= 100) {
      var data = {
        billNo: route.query.orderNumber,
        totalAmount: orderInfoPO.value.amount,
        installmentNumber: "3",
        goods: [{
          goodsName: productName.value,
          goodsId: orderProductList.value[0].productId,
          quantity: orderProductList.value[0].count,
          price: orderProductList.value[0].price,
        }],
      };
    } else {
      var data = {
        billNo: route.query.orderNumber,
        totalAmount: orderInfoPO.value.amount,
        goods: [{
          goodsName: productName.value,
          goodsId: orderProductList.value[0].productId,
          quantity: orderProductList.value[0].count,
          price: orderProductList.value[0].price,
        }],
      };
    }
    let aesData = Encrypt(JSON.stringify(data));
    let datas = {
      encryptData: aesData,
    };
    clearInterval(timer.value);
    trade(datas)
      .then((res) => {
        if (res.code == 200) {
          codeUrl.value = res.data;
          isVisible.value = true;
          timer.value = setInterval(() => {
            outTradeNo(route.query.orderNumber).then((res) => {
              if (res.code == 200 && res.data == "支付成功") {
                clearInterval(timer.value);
                ElMessage({
                  message: "支付成功!",
                  type: "success",
                });
                setTimeout(function () {
                  if (isService){
                    router.push(
                      {
                        path: "/InvoiceInfo",
                        query: {
                          orderNumber: route.query.orderNumber,
                          isService:1
                        },
                      },
                      500
                    );
                  }else {
                    router.push(
                      {
                        path: "/fillinadress",
                        query: {
                          orderNumber: route.query.orderNumber,
                        },
                      },
                      500
                    );
                  }
                });
              }
            });
          }, 4000);
        } else {
          ElMessage({
            message: res.message,
            type: "error",
          });
        }
      })
      .catch((e) => {
        if (e.err.response.data.code != 200) {
          ElMessage({
            message: e.err.response.data.message,
            type: "error",
          });
          setTimeout(() => {
            router.push("/orderCenter");
          }, 1000);
        }
      });
  }
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  .containerContent {
    background: #f2f2f2;
    padding-bottom: 90px;
    .contentpart {
      position: relative;
      margin: 0 auto;
      margin-top: 40px !important;
      width: 1200px;
      background: #ffffff;
      .diy {
        width: 1060px;
        display: flex;
        flex-direction: column;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .order-money{
          display: flex;
          justify-content: space-between;
          //padding-top: 72px;
          padding-top: 36px;
          .diy10 {
            width: 160px;
            height: 40px;
            background: #ce1200;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .diy11 {
              width: 20px;
              height: 20px;
            }
            .diy12 {
              width: 86px;
              margin-left: 10px;
              font-size: 15px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }
          }
          .orderMoneyText{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
          }
          .orderMoneySym{
            width: 11px;
            margin-left: 20px;
            margin-right: 6px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #ce1200;
          }
          .orderMoneyNum{
            width: 101px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 24px;
            color: #ce1200;
          }
	        .orderDicsountMoney{
		        margin-left: 5px;
		        font-family: Microsoft YaHei;
		        font-weight: 400;
		        font-size: 14px;
		        color: #666666;
	        }
        }
        .order-product-list{
          width: 1060px;
          //margin-top: 24px;
          margin-top: 12px;
          padding-top: 24px;
          display: flex;
          //justify-content: space-around;
          flex-wrap: wrap;
          background: #F7F7F7;
          .order-product{
            width: 480px;
            height: 80px;
            display: flex;
            margin-left: 30px;
            margin-bottom: 24px;
          }
          .order-product-img{
            width: 80px;
            height: 80px;
          }
          .order-product-title{
            width: 389px;
            margin-bottom: 24px;
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 17px;
            color: #333333;
          }
          .order-product-number{
            height: 18px;
            line-height: 18px;
            display: flex;
            align-items: end;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
          }
        }
        .container-bottom{
          display: flex;
          flex-direction: column;
          .container-bottom-text {
            width: 180px;
            height: 18px;
            //margin-top: 40px;
            margin-top: 20px;
            margin-bottom: 26px;
            margin-left: 10px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .cut {
            width: 1080px;
            height: 1px;
            background: #e1e1e1;
          }
          .pay-methods{
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;
            padding-left: 10px;
            .diy15 {
              width: 220px;
              height: 60px;
              border: 1px solid #d9d9d9;
              margin-right: 20px;
              margin-bottom: 20px;
              position: relative;
              .checkUp {
                background: url("./image/check.png") no-repeat;
                width: 24px;
                height: 24px;
                position: absolute;
                right: -10px;
                top: -10px;
              }
              .diy16 {
	              width: 97px;
	              height: 37px;
                left: 61px;
                top: 11px;
              }
            }
            .diy17 {
              width: 220px;
              height: 60px;
              border: 1px solid #d9d9d9;
              position: relative;
              margin-right: 20px;
              margin-bottom: 20px;
              position: relative;
              .checkUp {
                background: url("./image/check.png") no-repeat;
                width: 24px;
                height: 24px;
                position: absolute;
                right: -10px;
                top: -10px;
              }
              .diy18 {
	              width: 180px;
	              height: 33px;
                left: 19px;
                top: 14px;
              }
            }
            .checkborder {
              border: 1px solid #ce1200 !important;
              width: 220px;
              height: 60px;
            }
          }
          .diy19 {
            width: 220px;
            height: 60px;
            border: 1px solid #d9d9d9;
            position: relative;
            .checkUp {
              background: url("./image/check.png") no-repeat;
              width: 24px;
              height: 24px;
              position: absolute;
              right: -10px;
              top: -10px;
            }
            .diy20 {
              display: inline-block;
	            width: 264px;
              height: 32px;
              left: 38px;
              top: 14px;
            }
          }
          .diy19-1 {
            width: 220px;
            height: 60px;
            border: 1px solid #d9d9d9;
            margin-right: 20px;
            position: relative;
            .checkUp {
              background: url("./image/check.png") no-repeat;
              width: 24px;
              height: 24px;
              position: absolute;
              right: -10px;
              top: -10px;
            }
            .diy20-1 {
	            width: 183px;
	            height: 32px;
              left: 16px;
              top: 15px;
            }
          }
          .diy21 {
            border: none;
            width: 200px;
            height: 48px;
            background: #ce1200;
            border-radius: 5px;
            color: #ffffff;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
          .diy22 {
            width: 1160px;
            height: 1px;
            background: #e1e1e1;
            margin-top: 17px;
            margin-left: -40px;
          }
          .diy23 {
            height: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
          }
        }
        .diy2 {
          position: absolute;
          width: 480px;
          // height: 18px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          left: 276px;
          top: 97px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .diy3 {
          position: absolute;
          left: 276px;
          top: 171px;
          width: 11px;
          height: 14px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #555555;
        }
        .diy4 {
          position: absolute;
          left: 294px;
          top: 167px;
          width: 86px;
          height: 18px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #555555;
        }
        .diy5 {
          position: absolute;
          left: 430px;
          top: 176px;
          width: 33px;
          height: 14px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .diy6 {
          position: absolute;
          left: 470px;
          top: 176px;
          width: 13px;
          height: 11px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .diy7 {
          position: absolute;
          left: 601px;
          top: 176px;
          width: 70px;
          height: 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .diy8 {
          position: absolute;
          left: 674px;
          top: 171px;
          width: 11px;
          height: 14px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ce1200;
        }
        .diy9 {
          position: absolute;
          left: 692px;
          top: 167px;
          width: 86px;
          height: 18px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ce1200;
        }
      }
    }
    .diy24 {
      width: 100%;
      background-color: #f2f2f2;
      .diy24-1 {
        width: 1200px;
        background-color: #fff;
        margin: 0 auto;
        .diy24-2 {
          padding-left: 68px;
          display: inline-block;
          width: 650px;
          .fontlittle {
            width: 486px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-left: -30px;
          }
          .explain {
            width: 60px;
            height: 13px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            margin: 0 0 20px 0;
            color: #333333;
          }
        }
        .diy24-3 {
          display: inline-block;
          position: absolute;
          .diy24-4 {
            position: absolute;
            left: 60px;
            width: 425px;
            height: 210px;
            background: rgba(255, 105, 0, 0.05);
            border: 1px solid #ce1200;
            border-radius: 20px;
            .diy24-5 {
              position: absolute;
              left: 41px;
              top: 37px;
              width: 258px;
              height: 19px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
            .diy24-6 {
              position: absolute;
              left: 41px;
              top: 84px;
              width: 310px;
              height: 16px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
            .diy24-7 {
              position: absolute;
              left: 41px;
              top: 116px;
              width: 382px;
              height: 16px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
            .diy24-8 {
              position: absolute;
              left: 41px;
              top: 148px;
              width: 308px;
              height: 19px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
          }
          .diy24-9 {
            position: absolute;
            left: 60px;
            top: 243px;
            width: 425px;
            height: 130px;
            background: rgba(255, 105, 0, 0.05);
            border: 1px solid #ce1200;
            border-radius: 20px;
            .diy24-10 {
              position: absolute;
              left: 41px;
              top: 39px;
              width: 129px;
              height: 17px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
            .diy24-11 {
              position: absolute;
              left: 41px;
              top: 69px;
              width: 189px;
              height: 17px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
            }
          }
        }
        .diy25 {
          width: 1160px;
          height: 1px;
          background: #e1e1e1;
          margin: 0 auto;
        }
        .diy28 {
          height: 69px;
          .diy26 {
            width: 668px;
            height: 48px;
            line-height: 13px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            float: left;
            margin-top: 29px;
            margin-left: 70px;
          }
          .diy27 {
            border: none;
            float: right;
            width: 200px;
            height: 48px;
            margin-top: 10px;
            margin-right: 69px;
            background: #ce1200;
            border-radius: 5px;
            color: #ffffff;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.hide {
  ::v-deep .el-upload--picture-card {
    display: none !important;
  }
}
::v-deep .el-upload-list__item {
  transition: none !important;
}
.apliy {
  display: flex;
	margin-top: 28px;
  .apliy1 {
    margin-left: 56px;
    margin-top: -13px;
    width: 22px;
    height: 22px;
  }
  .apliy2 {
    display: flex;
    margin-top: -15px;
    margin-left: 10px;
    height: 19px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.apliy2-3 {
  display: flex;
  position: relative;
  top: 282px;
  left: 42px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.apliy-1 {
  display: flex;
  width: 384px;
  height: 60px;
  background: #F2F2F2;
  position: relative;
  top: -34px;
  left: -16px;
  .apliy1-1 {
    width: 29px;
    height: 24px;
    position: absolute;
    top: 18px;
    left: 20px;
  }
  .apliy1-2 {
    width: 12px;
    height: 12px;
    position: absolute;
    background: url('./image/close1.png');
	  background-size: cover;
    top: 24px;
    right: 20px;
    &:hover {
      background: url('./image/close2.png');
	    background-size: cover;
      width: 24px;
      height: 24px;
      top: 18px;
      right: 16px;
      cursor: pointer;
    }
  }
  .apliy2-1 {
    display: flex;
    position: absolute;
    top: 17px;
    left: 54px;
    height: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
  }
}
.apliy3 {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 96px;
  left: 66px;
  text-align: center;
}
.apliy3-1 {
	width: 100%;
	display: flex;
	justify-content: center;
  margin-top: 23px;
}
.apliy4 {
  display: flex;
	justify-content: center;
	padding-top: 14px;
  .apliy5 {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
  }
  .apliy6 {
    margin-left: 73px;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
  }
}
.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}
.menustatus {
  width: 100%;
  height: 84px;
  background-color: #fff;
}
.menustatusmiddle {
  position: relative;
  top: 32%;
  display: flex;
	justify-content: center;
}
.fontstyle {
  width: 30px;
  height: 30px;
  background: #ce1200;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.fontstyleorther {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  text-align: center;
  line-height: 30px;
  color: #aaaaaa;
}
.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}
.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}
.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}
::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
.myBtn1{
  width: 160px !important;
  height: 48px;
  background: #ce1200;
  border: 0px !important;
  border-radius: 5px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
  margin-top: 36px;
}
.unionDialog_text{
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.unionDialog_title{
  margin-left: 7px;
}
.unionDialog_content{
  display: flex;
  justify-content: center;
}
.unionDialog :deep(.el-dialog){
  width: 684px;
  height: 240px;
  background-color: #f2f2f2;
}
.unionDialog :deep(.el-dialog__body){
  padding: 40px 0 !important;
  background-color: #fff;
}
</style>
