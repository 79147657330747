<template>
    <div class="content">
        <div class="content-head"/>
        <div class="content-box">
          <div :class="'content-'+(index+1)" v-for="(item,index) in imgArr" :key="index">
            <img :src="item" alt="">
          </div>

          <div class="content-5">
            活动时间: 2025年2月4日 至 2025年3月31日
          </div>

          <div class="content-6">
              <div>活动说明</div>
              <div>
                <div v-for="(item,index) in resultArr" :key="index" class="tips" >
                    <span>{{index+1}}、</span>
                    <span>{{item.tips1}}</span>
                    <span v-if="item.link" class="item-link" @click="linkToPoints">{{item.link}}</span>
                    <span>{{item.tips2}}</span>
                </div>
              </div>
          </div>
        </div>
    </div>
</template>


<script setup>
import {useRouter} from 'vue-router'

import content1 from '../img/content-1.png'
import content2 from '../img/content-2.png'
import content3 from '../img/content-3.png'
import content4 from '../img/content-4.png'
//图片数组
const imgArr=[content1,content2,content3,content4]

//活动说明数组
const resultArr=[
  {tips1:'积分使用规则，请到',link:'积分中心',tips2:'页面查看；'},
  {tips1:'赠送的外设，不支持指定品牌、型号；'},
  {tips1:'赠送的服务，需要提前向我方预约实施时间；'},
  {tips1:'如果产生退款，该订单产生的积分将清零，赠品需按原包装退还我方。'}
]

const router=useRouter()
const linkToPoints=()=>{
  router.push({
    path:'/pointsCenter'
  })
}

</script>


<style scoped lang="less">
.public-start-work(@height,@mTop){
  width: 1149px;
  height: @height;
  margin-top: @mTop;
  img{
    width: 100%;
    height: 100%;
  }
}

.content{
  width: 100%;

  .content-head{
    width: 1920px;
    height: 750px;
    background: url("../img/start-work-head.png") no-repeat;
    background-size: 100%;
  }
  .content-box{
    width: 100%;
    height: auto;
    background: #F14342;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-1{
      .public-start-work(319px,45px)
    }
    .content-2{
      .public-start-work(423px,37px)
    }
    .content-3{
      .public-start-work(423px,37px)
    }
    .content-4{
      .public-start-work(316px,37px)
    }
    .content-5{
      width: 1149px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 30px;
    }
    .content-6{
      width: 1147px;
      height: 237px;
      border-radius: 26px;
      background: #FFFFFF;
      border: 2px solid #DF2726;
      margin-bottom: 160px;
      padding: 41px;
      box-sizing: border-box;

      &>div:nth-child(1){
        color: #282423;
        font-size: 24px;
        font-weight: bold;
      }
      &>div:nth-child(2){
        margin-top: 20px;

        .tips{
          margin-top: 5px;
          font-size: 14px;
          color: #282423;

          .item-link{
            color: #5DA2FB;
            margin: 0 3px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
