<template>
  <div v-if="isVisible" class="overlay">
    <div class="modal-content">
      <div class="lottery-dialog-box">
        <div class="close" @click="close"></div>
	      <div class="dialog-box-title">请完善企业名称</div>
        <div class="dialog-box">
          <el-form :rules="rules" :model="info" ref="_form" size="large">
            <el-form-item label="" prop="name">
              <el-input
                placeholder="请输入公司名称"
                v-model="info.name"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
	        <div @click="getCoupon" class="confirm-img" v-track="{event:'完善企业名称',page:'抽奖活动页面'}">
		        <img src="../img/confirm_button.png" alt="">
	        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 控制遮罩显示/隐藏
import { ref, onMounted, reactive } from "vue";
import { updateNickName } from "@/api/activity";

const isVisible = ref(true);
const emits = defineEmits(["close", "find"]);
const _form = ref(null);
const info = reactive({
  name: "",
});

// 在组件挂载时禁用页面滚动
onMounted(() => {
  document.body.style.overflow = "hidden";
});

const rules = {
  name: [{ required: true, message: "请输入公司名称" }],
};

//填写公司名称
const getCoupon = () => {
  _form.value.validate().then(() => {
    updateNickName({ company: info.name })
      .then((res) => {
        if (res.code === 200) {
          emits("find");
        }
      })
      .finally(() => {
        close();
      });
  });
};

// 关闭遮罩函数
const close = () => {
  isVisible.value = false;
  document.body.style.overflow = "";
  emits("close");
};
</script>

<style scoped lang="less">
/* 全屏遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 弹窗内容 */
.modal-content {
  position: relative;
  .lottery-dialog-box {
    background: url("../img/fillout_box.png") no-repeat;
    width: 1083px;
    height: 483px;
    background-size: 100%;
    position: relative;

    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url("../img/recodes-close.png");
      background-size: 100%;
      top: 27px;
      right: 27px;
      z-index: 1;
      cursor: pointer;
    }
    .dialog-box {
	    margin-top: 24px !important;
	    margin: 0 auto;
      width: 94%;
      height: 52px;
    }
	  .dialog-box-title{
		  padding-top: 122px;
		  padding-left: 33px;
		  font-family: FZLanTingHeiS-B-GB;
		  font-weight: 400;
		  font-size: 24px;
		  color: #9C2F09;
	  }
	  .confirm-img{
		  margin-top: 96px !important;
		  margin: 0 auto;
		  width: 360px;
		  height: 61px;
		  cursor: pointer;
	  }
  }
}

/* 隐藏滚动条，但仍可滚动 */
.scrollable {
  overflow: auto; /* 保持内容可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
}

/* 针对IE和Edge的隐藏滚动条样式 */
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none; /* IE 和 Edge 下隐藏滚动条 */
}
</style>
