<template>
  <div class="content" v-if="IdValue === 1">
    <!-- 头部 -->
    <div class="order-input">
      <div class="order-content">
        <ul class="tabBox">
          <li
            class="tabItem"
            v-for="(item, index) in orderContent"
            :key="item.id + index"
            :class="item.id === currentActive ? 'active' : ''"
            @click.self="changeTab(item)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <!-- 搜索栏 -->
      <div class="content-input">
        <el-input
          v-focus
          v-model="param"
          placeholder="输入售后单号、主机SN号"
          class="input-with-select"
          @keydown.enter="searchEnterFun(param)"
          clearable
          @clear="handelClear"
        >
          <template #append>
            <el-button class="input-icon" @click="handelSearch(param)">
              <img src="./image/search.png" alt=""
            /></el-button>
          </template>
        </el-input>
      </div>
    </div>
    <!-- 内容区域 -->
    <div v-if="SaleList">
      <div
        class="content-theme"
        v-for="(item, index) in SaleList"
        :key="item.id + index"
      >
        <div class="theme-1-1">
          <p class="theme-1">
            <img src="./image/sh.png" v-if="item.status === '审核中'" />
            <img src="./image/gb.png" v-if="item.status === '已拒绝'" />
            <img src="./image/cl.png" v-if="item.status === '处理中'" />
            <img src="./image/wc.png" v-if="item.status === '已完成'" />
            <span>{{ item.status }}</span>
          </p>
          <div class="theme-2">
            <span>售后单号：</span><span>{{ item.afterSaleOrderNumber }}</span>
          </div>
          <div class="theme-2" style="margin-left: 398px;">
            <span>售后类型：</span><span>{{ item.typeName }}</span>
          </div>
        </div>
        <div class="border"></div>
        <div class="btn-price">
          <div class="btn-content">
            <div class="g-1">
              <p class="g-1-1">
                <span>主机SN号：</span
                ><span :title="item.masterSnCode">{{ item.masterSnCode }}</span>
              </p>
              <p class="g-1-2">
                <span>关联订单号：</span><span>{{ item.orderNumber }}</span>
              </p>
            </div>
            <!-- <div class="s-b">
              <p class="s-b-1">
                <span>商品单价：</span><span>￥{{ item.price }}</span>
              </p>
              <p class="s-b-2">
                <span>商品数量：</span><span>{{ item.count }}</span>
              </p>
              <p class="s-b-3">
                <span>订单金额：</span><span>￥{{ item.amount }}</span>
              </p>
              <p class="s-b-4">
                <span>订单状态：</span
                ><span
                  ><img src="./image/wc1.png" alt="" />{{
                    item.orderState
                  }}</span
                >
              </p>
            </div> -->
          </div>
          <div class="btn-p-btn">
            <p class="btn-p-btn-1" @click="handelCheckOut(item)">查看详情</p>
            <p v-if="item.typeName == '申请换货' || item.typeName == '申请退货'">
              <p v-if="item.status === '处理中' && item.expressNumber == ''" class="btn-p-btn-1" @click="handelfill(item)">填写退货单号</p>
              <p v-if="item.status === '处理中' && item.expressNumber != ''" class="btn-p-btn-1" @click="handelfill(item)">修改退货单号</p>
            </p>
            <p
              class="btn-p-btn-2"
              v-if="item.status === '已拒绝'"
              @click="handleShe(item)"
            >
              重新提交
            </p>
          </div>
        </div>
      </div>
    </div>
    <noContent style="margin-left: -80px" v-if="SaleList == ''" />
    <div class="demo-pagination-block" v-if="SaleLists.total">
      <el-pagination
        :current-page="SaleLists.pageNum"
        :page-size="SaleLists.pageSize"
        :small="small"
        background
        layout="total, prev, pager, next, jumper"
        :total="SaleLists.total"
        next-text="下一页"
        prev-text="上一页"
        @current-change="handleCurrentChange"
      />
      <el-button class="btn-cl">确定</el-button>
    </div>
    <div v-else></div>
  </div>

  <SaleDetail
    @changeId="handleChangeId"
    :numValue="numValue"
    :orderid="orderid"
    v-if="IdValue === 2"
  ></SaleDetail>
  <ApplyAfter
    @changeId="handleChangeId"
    :AfterApply="AfterApply"
    v-if="IdValue === 3"
  />
  <el-dialog v-model="openfill" destroy-on-close="true" title="填写退货物流信息" @close="clean" width="28%">
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" class="fillstyle">
      <el-form-item label="快递公司" :label-width="formLabelWidth" prop="expressCompany">
        <el-input v-model="ruleForm.expressCompany" autocomplete="off" />
      </el-form-item>
      <el-form-item  label="快递单号" :label-width="formLabelWidth" prop="expressNumber">
        <el-input v-model="ruleForm.expressNumber" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="openfill = false">取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import SaleDetail from "./components/SaleDetail.vue";
import noContent from "@/components/directives/noContent";
import {AfterResubmit, express, OrderSaleList, SaleAfterDetail} from "../../api/after/index.js";
import ApplyAfter from "./components/ApplyAfter";
import {useRoute} from "vue-router";
import {ElMessage} from "element-plus";

const param = ref("");
const currentActive = ref(1);
const IdValue = ref(1);
const orderContent = ref([
  { title: "全部售后单", num: "", id: 1 },
  { title: "审核中", status: 0, id: 2 },
  { title: "处理中", status: 1, id: 7 },
  { title: "已完成", status: 2, id: 3 },
  { title: "审核拒绝", reviewStatus: 0, id: 4 },
]);
const route = useRoute();
// const dialogOpen = ref(false);

// 订单详情
const numValue = ref({});
const orderid = ref();
const handelCheckOut = async (item) => {
  let res = await SaleAfterDetail(item.id);
  if (res.code === 200) {
    numValue.value = res.data;
    orderid.value = item.id;
    console.log(numValue.value, "1111");
    IdValue.value = 2;
  }
};
// 重新提交
const AfterApply = ref({});
const handleShe = async (item) => {
  const afterSaleOrderNumber = ref(item.afterSaleOrderNumber);
  try {
    let res = await AfterResubmit(afterSaleOrderNumber.value);
    if (res.code === 200) {
      AfterApply.value = res.data;
      IdValue.value = 3;
      console.log(AfterApply.value, "重新提交");
    }
  } catch (error) {
    console.log(error);
  }
};
// 子组件传递给父组件
const handleChangeId = (i) => {
  console.log(i);
  IdValue.value = i;
  getOrderSaleList();
};
// const handleAfterSucVal = (i) => {
//   console.log(i);
//   IdValue.value = i;
//   getOrderSaleList();
// };

// 我的订单列表
const dataValue = reactive({
  pageNum: null,
  pageSize: null,
  param: null,
  status: null,
  reviewStatus: null,
});
const SaleList = ref([]);
const SaleLists = ref({});
const openfill = ref(false)
const formLabelWidth = '120px'
const ruleFormRef = ref();
const clean = (formEl) => {
  if (!formEl) return
  ruleFormRef.value?.resetFields();
};
const handelfill = (item) => {
  ruleForm.id = item.id
  if(item.expressNumber!=""){
    ruleForm.expressCompany = item.expressCompany;
    ruleForm.expressNumber = item.expressNumber;
  }
  openfill.value = true
}
const ruleForm = reactive({
  expressCompany: "",
  expressNumber: "",
  id: "",
});
const rules = reactive({
  expressCompany: [{ required: true, message: "请输入快递公司", trigger: "blur" }],
  expressNumber: [{ required: true, message: "请输入快递单号", trigger: "blur" }],
}); 
const submitForm  = async (formEl) =>{
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      express(ruleForm).then((res)=>{
        if(res.code == 200){
          ElMessage({
            message: "提交成功",
            type: "success",
            offset: 80
          });
          changeTab({ title: "处理中", status: 1, id: 7 })
          openfill.value = false
        }else{
          ElMessage({
              message: res.message,
              type: "error",
              offset: 80
            });
        }
      })
    } 
  })
}
const getOrderSaleList = async () => {
  try {
    let res = await OrderSaleList(dataValue);
    if (res.code === 200) {
      SaleLists.value = res.data;
      SaleList.value = res.data.list;
    }
  } catch (error) {
    console.log(error);
  }
};
// 切换状态
const changeTab = (item) => {
  console.log(item);
  currentActive.value = item.id;
  if (item.title === "审核拒绝") {
    dataValue.reviewStatus = item.reviewStatus;
    dataValue.status = null;
    getOrderSaleList(dataValue.reviewStatus);
  } else if (item.title != "审核拒绝") {
    dataValue.status = item.status;
    dataValue.reviewStatus = null;
    getOrderSaleList(dataValue.status);
  } else if (item.title === "全部售后单") {
    getOrderSaleList(dataValue);
  }
};
// 搜索
const searchEnterFun = (item) => {
  dataValue.param = item;
  getOrderSaleList(dataValue.param);
};
// 搜索按钮
const handelSearch = async (item) => {
  console.log(item);
  dataValue.param = item;
  getOrderSaleList(dataValue.param);
};
// 清除
const handelClear = () => {
  dataValue.param = null;
  getOrderSaleList(dataValue.param);
};
// 分页
const handleCurrentChange = (Num) => {
  console.log(Num);
  dataValue.pageNum = Num;
  getOrderSaleList(dataValue.pageNum);
};

onMounted(() => {
  getOrderSaleList();
  if(route.query.orderNumber){
    console.log(route.query.orderNumber,123123);
}
});
</script>

<style lang="less" scoped>
.fillstyle{
  /deep/.el-input--small .el-input__inner {
    --el-input-inner-height: calc(var(--el-input-height, 1.5rem) - 0.125rem);
    height: 30px;
  }
  ::v-deep.el-button:focus, .el-button:hover {
    color: var(--el-button-hover-text-color);
    border-color: var(--el-button-hover-border-color);
    background-color: var(--el-button-hover-bg-color);
    outline: 0;
  }
}
.el-input {
  width: 300px;
}
.content {
  .order-input {
    display: flex;
    justify-content: space-between;
    .order-content {
      // display: flex;
      .tabBox {
        display: flex;
        margin-top: 10px;
        .tabItem {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          border-right: 1px solid #e1e1e1;
          padding: 0 15px;
          cursor: pointer;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            border-right: none;
          }
        }
        .active {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }
        :hover {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }
      }
    }
    .content-input {
      margin-right: 25px;
      width: 320px;
      .input-with-select {
        height: 40px;
        .input-icon {
          // background: #f2f2f2;
          display: inline-block;
          img {
            display: inline-block;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .content-theme {
    width: 885px;
    height: 192px;
    border: 1px solid #aaaaaa;
    margin-top: 33px;
    .theme-1-1 {
      width: 885px;
      height: 60px;
      display: flex;
      .theme-1 {
        margin-left: 30px;
        margin-right: 41px;
        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 5px;
          margin-top: -5px;
        }
        span {
          display: inline-block;
          font-size: 18px;
          margin-top: 20px;
          font-weight: 400;
          color: #ce1200;
        }
      }
      .theme-2 {
        margin-top: 24px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .border {
    border-bottom: 1px solid #e1e1e1;
  }
  .btn-price {
    display: flex;
    justify-content: space-between;
    .btn-content {
      margin-bottom: 32px;
      margin-left: 32px;
      .g-1 {
        margin-top: 24px;
        .g-1-1 {
          width: 550px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .g-1-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          // margin-bottom: 27px;
        }
      }
      .s-b {
        display: flex;
        //justify-content: baseline;
        .s-b-1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }
        .s-b-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }
        .s-b-3 {
          margin-top: 13px;
          margin-right: 60px;
          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          :nth-child(2) {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .s-b-4 {
          margin-top: 23px;
          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          :nth-child(2) {
            img {
              display: inline-block;
              width: 15px;
              height: 15px;
            }
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }
    }
    .btn-p-btn {
      margin-right: 30px;
      .btn-p-btn-1 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
        cursor: pointer;
        .hoverBorder();
      }
      .btn-p-btn-2 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
        cursor: pointer;
        .hoverBorder();
      }
    }
  }
}
.demo-pagination-block {
  width: 980px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .btn-cl {
    width: 65px;
    height: 35px;
    margin-left: 10px;
    font-size: 14px;
  }
}

::v-deep .el-input-group__append {
  background: #f2f2f2;
}
::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

::v-deep .el-divider--horizontal {
  margin: 0;
}
::v-deep .el-input__wrapper {
  width: 240px;
  background-color: #f2f2f2f2;
}
// ::v-deep .el-input__wrapper.is-focus {
//   box-shadow: 0 0 0 1px #999;
// }
::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
}
::v-deep .el-pagination.is-background .el-pager li.is-active {
  background-color: #ce1200;
  color: #fff;
  border: 1px solid #ce1200;
}
::v-deep .el-pager li:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}
::v-deep .el-pagination button:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}
::v-deep .el-pagination.is-background .btn-next {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
::v-deep .el-pagination.is-background .btn-prev {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
::v-deep .el-input__wrapper {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
/* ::v-deep .el-button:focus,
.el-button:hover {
  color: #ce1200;
  border-color: #ce1200;
  background-color: transparent;
  outline: 0;
} */
</style>
