<template>
  <div class="content" v-if="IdValue === 1">
    <!-- 头部 -->

    <div class="toAddService">
      <div>质保查询</div>
      <div class="order-input" >
        <!-- 搜索栏 -->
        <div class="content-input">
          <el-input
            v-focus
            v-model="keyCode"
            placeholder="查询主机SN、订单号"
            class="input-with-select"
            @keydown.enter="searchEnterFun(keyCode)"
            clearable
            @clear="handelClear"
          >
            <template #append>
              <el-button class="input-icon" @click="handelSearch(keyCode)">
                <img src="../../../assets//images/search.png" alt=""
                /></el-button>
            </template>
          </el-input>
        </div>
      </div>
    </div>

    <!-- 内容区域 -->
    <div v-if="orderData">
      <div
        class="content-theme"
        v-for="(item, index) in orderData"
        :key="item.id + index"
      >
        <div class="border"></div>
        <div class="btn-price">
          <div class="btn-price-1">
            <div style="display: flex">
              <div class="btn-price-1-1">
                <el-form-item label="发货日期:">{{item.createdTime}}</el-form-item>
                <el-form-item label="主机SN:">{{item.masterSnCode}}</el-form-item>
                <el-form-item label="商品名称:">{{item.productName}}</el-form-item>
                <el-form-item label="关联订单号:">{{item.orderNumber}}</el-form-item>
                <el-form-item label="主机质保期:">{{splitTime(item.qualityStartTime) }}~{{ splitTime(item.qualityEndTime)}}</el-form-item>
              </div>
            </div>
            <div class="btn-price-2">
              <p class="btn-price-2-2" @click="handelCheckOut(item)">
                质保详情
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <noContent style="margin-left: -80px" v-if="orderData == ''" />
    <div class="demo-pagination-block" v-if="orderLists.total">
      <el-pagination
        :current-page="orderLists.current"
        page-size="10"
        :small="small"
        background
        layout="total, prev, pager, next, jumper"
        :total="orderLists.total"
        next-text="下一页"
        prev-text="上一页"
        @current-change="handleCurrentChange"
      />
      <el-button class="btn-cl">确定</el-button>
    </div>
    <div v-if="orderLists.total === ''"></div>
  </div>

  <QualityDetails
    @changeId="handleChangeId"
    :rowData="numValue"
    v-if="IdValue === 2"
  >
  </QualityDetails>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import QualityDetails from './qualityDetails.vue'

import { qualityList } from "@/api/orderCenter";
import noContent from "../../../components/directives/noContent";

const keyCode = ref("");

const IdValue = ref(1);

const dialogOpen = ref(false);


// 订单详情
const numValue = ref("");
const handelCheckOut = (item) => {
  numValue.value = item;
  IdValue.value = 2;
};
// 子组件传递给父组件
const handleChangeId = (i) => {
  IdValue.value = i;
  getOrderList();
};

// 我的订单列表
const dataValue = reactive({
  pageNum: 1,
  pageSize: 10,
  param:''
});
const orderData = ref([]);
const orderLists = ref({});
const getOrderList = async () => {
  try {
    let data = await qualityList(dataValue);
    orderData.value = data.data.list;
    orderLists.value = data.data;
  } catch (error) {
    console.log(error);
  }
};

//质保时间不需要时分秒
const splitTime=(time)=>{
  if (!time){
    return
  }
  return time.split(' ')?.[0]
}


// 搜索
const searchEnterFun = (item) => {
  dataValue.param = item;
  getOrderList();
};
// 搜索按钮
const handelSearch = (item) => {
  dataValue.param = item;
  getOrderList();
};
// 清除
const handelClear = () => {
  dataValue.param = "";
  getOrderList();
};

// 分页
const handleCurrentChange = (Num) => {
  dataValue.pageNum = Num;
  getOrderList(dataValue.pageNum);
};



const offTitle = () => {
  dialogOpen.value = false;
  getOrderList();
};



onMounted(() => {
  getOrderList();
});
</script>

<style lang="less" scoped>
.toAddService {
  display: flex;
  padding-bottom: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;

  .el-button:focus,
  .el-button:hover {
    color: transparent;
    border-color: transparent;
    background-color: transparent;
    outline: 0;
  }
}

.content {
  .order-input {
    display: flex;
    justify-content: space-between;

    .order-content {
      // display: flex;
      .tabBox {
        display: flex;
        margin-top: 10px;

        .tabItem {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          border-right: 1px solid #e1e1e1;
          padding: 0 15px;
          cursor: pointer;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }
        }

        .active {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }

        :hover {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }
      }
    }

    .content-input {
      margin-right: 25px;
      width: 320px;

      ::v-deep [data-v-73d05719] .el-input__wrapper {
        background: transparent;
        border: 0px solid #d9d9d9 !important;
        border-radius: 5px;
      }

      ::v-deep .el-input__wrapper.is-focus {
        background: transparent;
        border: 1px solid #d9d9d9 !important;
        border-radius: 5px;
      }

      .input-with-select {
        height: 40px;

        ::v-deep .el-input__inner {
          padding-left: 10px !important;
        }

        .input-icon {
          // background: #f2f2f2;
          display: inline-block;
          margin-bottom: 3px;

          img {
            display: inline-block;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .content-theme {
    width: 885px;
    /* height: 192px; */
    border: 1px solid #aaaaaa;
    margin-top: 33px;

    .theme-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 30px;

      img {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        margin-top: -5px;
      }

      span {
        display: inline-block;
        font-size: 18px;
        margin-top: 20px;
        font-weight: 400;
        color: #ce1200;
      }

      .rightText {
        padding: 17px 20px 0 0;
        color: #1dc779;
      }
    }

    .theme-2 {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 900px;

      .theme-3 {
        margin-left: 31px;
        display: flex;

        .child1 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          margin-right: 36px;
        }

        .child2 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }

      .theme-4 {
        margin-right: 31px;

        .nth-1 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }

        .nth-2 {
          display: inline-block;
          font-size: 24px;
          font-weight: bold;
          color: #ce1200;
          align-items: center;
        }
      }
    }

    .border {
      border-bottom: 1px solid #e1e1e1;
    }

    .btn-price {
      .btn-price-1 {
        display: flex;
        justify-content: space-between;

        .btn-img {
          width: 80px;
          height: 80px;
          margin: 15px 26px 16px 32px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .btn-price-1-1 {
          padding: 20px;
           /deep/ .el-form-item{
             margin-bottom: 10px !important;
           }


        }

        .btn-price-2 {
          margin-top: 20px;
          margin-right: 30px;

          .btn-price-2-2 {
            width: 120px;
            height: 30px;
            line-height: 28px;
            border: 1px solid #aaaaaa;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 10px;
            cursor: pointer;
            .hoverBorder();
          }

          .btn-price-2-3 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-top: 10px;
            cursor: pointer;
            .hoverBorder();
          }
        }
      }

      .customerorder-1 {
        width: 884px;
        margin-bottom: 31px;

        .customerorder-2 {
          width: 766px;
          height: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
          margin: 1px 0 4px 87px;

          img {
            width: 20px;
            height: 20px;
          }

          .customerorder-3 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            margin-left: 6px;
          }
        }

        .customerorder-4 {
          width: 766px;
          height: 78px;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          margin-left: 87px;
          margin-bottom: 12px;
          position: relative;

          .customerorder-4-1 {
            width: 309px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            position: absolute;
            top: 28px;
            left: 61px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .customerorder-4-2 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 28px;
            left: 434px;
          }

          .customerorder-4-3 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 28px;
            left: 496px;
          }

          .customerorder-4-4 {
            position: absolute;
            top: 14px;
            right: 83px;
          }

          .customerorder-4-6 {
            width: 129px;
            height: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            position: absolute;
            top: 48px;
            right: 34px;
          }

          .customerorder-4-8 {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #ce1200;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #ce1200;
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 52px;
          }

          .customerorder-4-9 {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }

          .customerorder-4-10 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #f8392d;
          }

          .customerorder-5 {
            width: 260px;
            height: 70px;
            margin: 58px 0 0 61px;
            display: flex;

            .customerorder-one {
              height: 70px;
              width: 108px;
              margin-right: 44px;

              .customerorder-5-1 {
                width: 48px;
                height: 48px;
                border: 1px solid #e1e1e1;
                margin: 0 auto;
              }

              .customerorder-5-2 {
                text-align: center;
                width: 108px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                margin-top: 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .customerorder-8-1 {
            position: absolute;
            top: 20px;
            right: 6px;
            height: 50px;
            width: 60px;
            align-items: center;
            display: flex;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .customerorder-8 {
            position: absolute;
            top: 15px;
            right: 78px;

            .customerorder-8-2 {
              width: 48px;
              height: 48px;
              border: 1px solid #e1e1e1;
            }
          }

          .customerorder-6-3 {
            position: absolute;
            top: 38px;
            right: 83px;
          }

          .customerorder-6-4 {
            position: absolute;
            top: 77px;
            right: 26px;
            width: 144px;
            height: 35px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            text-align: center;
          }

          .customerorder-6 {
            width: 48px;
            height: 70px;
            margin-right: 24px;

            .customerorder-6-1 {
              width: 48px;
              height: 48px;
              border: 1px solid #e1e1e1;
            }

            .customerorder-6-2 {
              margin-top: 4px;
              text-align: center;
              width: 48px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .demo-pagination-block {
    width: 980px;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .btn-cl {
      width: 65px;
      height: 35px;
      margin-left: 10px;
      font-size: 14px;
    }
  }
}

::v-deep .el-input-group__append {
  background: #f2f2f2;
}

::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

::v-deep .el-divider--horizontal {
  margin: 0;
}

::v-deep .el-input__wrapper {
  width: 240px;
  background-color: #f2f2f2f2;
}

// ::v-deep .el-input__wrapper.is-focus {
//   box-shadow: 0 0 0 1px #999;
// }
::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
}

::v-deep .el-pagination.is-background .el-pager li.is-active {
  background-color: #ce1200;
  color: #fff;
  border: 1px solid #ce1200;
}

::v-deep .el-pager li:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}

::v-deep .el-pagination button:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}

::v-deep .el-pagination.is-background .btn-next {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-pagination.is-background .btn-prev {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-input__wrapper {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-button:focus,
.el-button:hover {
  color: #ce1200;
  border-color: #ce1200;
  background-color: transparent;
  outline: 0;
}
</style>
