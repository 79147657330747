<template>
  <div class="box">
    <el-dialog
      title="查看收货地址"
      v-model="dialogVisible"
      width="20%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="address-box">
        <div>收货人：{{info.name}}</div>
        <div>手机号：{{info.phone}}</div>
        <div>收货地址：{{info.address}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="handleClose" size="default">好的</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
const dialogVisible=ref(true)
const emits=defineEmits(['close'])


const props=defineProps({
  currentRow:{
    type:Object
  }
})
const info=reactive({
  name:'',
  phone:'',
  address:''
})



onMounted(()=>{
  if (props.currentRow.address!=='null'){
    info.address=props.currentRow.address
  }else{
    info.address='暂无收货地址'
  }
  info.name=props.currentRow.recipient
  info.phone=props.currentRow.userMobile
})


const handleClose=()=>{
  close()
}



const close=()=>{
  emits('close')
}

</script>

<style scoped lang="less">
.address-box{
  &>div{
    margin-top: 10px;
  }
}
.dialog-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.box{
  :deep(.el-dialog){
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  :deep(.el-dialog .el-dialog__body) {
    flex:1;
    overflow: auto;
  }
}


</style>
