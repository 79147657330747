<template>
	<router-view :key="$route.fullPath" />
</template>
<style>
	.warp-img ol>li {
		list-style: decimal;
		margin-left: -23px;
	}

	.warp-img ul>li {
		list-style-type: disc;
		margin-left: 20px;
	}
</style>
<!-- <style lang="less">
.el-popper.custom-1.is-light {
  background: rgba(#000, 0.29);
  border: none;
}
.el-popper.custom-2.is-light {
  background: rgba(#000, 0.7);
  border: none;
}
.el-menu--horizontal {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0 !important;
  border-right: none;
}

</style> -->
<script setup>
// import request from "@/utils/request";
// request.get("/message").then((res) => {
//   console.log("hello msw", res);
// });
import {onMounted} from "vue";
import {checkSafe} from "@/api/Home";
import {getButtonName} from '@/utils/point'

onMounted(() => {

		// 后管统计网站访问量
		let url = window.location.href
		let referer = document.referrer
		if(url.length > 240){ // 数据库限制page长度255
			url = url.substring(0, 240)
		}
		if (referer == '') {
			referer = '直接访问'
		} else if (referer.indexOf('?') != -1) {
			referer = referer.substring(0, referer.indexOf('?'))
		}

		const query = {
			client: "PC",
			page: url,
			source: referer
		}
		checkSafe(query)



		let isHandlingClickEvent=false
		document.addEventListener('click', e =>getButtonName(e,isHandlingClickEvent) , true)
		//兼容浏览器前进或者后退
		document.addEventListener('popstate', (e)=>{
			if(!isHandlingClickEvent){
				localStorage.setItem('buttonName', '返回')
			}
		});
	})
</script>

<style lang="less">
	/* @import "./assets/styles/variables"; */
	// #app {
	//   -webkit-font-smoothing: antialiased;
	//   -moz-osx-font-smoothing: grayscale;
	// }
</style>
