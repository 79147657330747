<template>
  <div class="point-page">
    <div class="container-top">
      <el-image class="img-box" :src="BgPic"></el-image>
    </div>

    <!--积分显示-->
    <div class="content-box">
      <div class="container-center">
        <div class="content-1">
          <div class="my-points">
            <div class="my-points-title">
              <img src="../img/points-icon.png" alt="" />
              <span>我的积分</span>
            </div>
            <div
              class="my-points-value"
              v-if="isLogin"
            >
              {{ myPoints }}
            </div>
            <div class="my-points-value" v-else>****</div>
          </div>

          <div
            class="point-recodes-button"
            v-if="isLogin"
          >
            <div class="recodes-point" @click="toPointsRecodes"  v-track="{event:'积分记录',page:'积分中心'}">
              <img src="../img/recodes-icon.png" alt="" />
              <span>积分记录</span>
            </div>
            <div class="recodes-give" @click="toGiveRecodes" v-track="{event:'兑换记录',page:'积分中心'}">
              <img src="../img/give-icon.png" alt="" />
              <span >兑换记录</span>
            </div>
          </div>
          <div v-else class="point-recodes-button">
            <div class="login-button" @click="toLogin" v-track="{event:'积分中心登录',page:'积分中心'}">登录</div>
          </div>
        </div>
        <div class="content-2">
          <div class="num">{{getRuleNum}}</div>
          <div class="title">积分</div>
          <div class="tips" >
            <img v-if="!signIs" src="../img/give-points.png" alt="" @click="signToPoints" v-track="{event:'签到领积分',page:'积分中心'}">
            <img v-else src="../img/sign-over.png" alt="">
          </div>
        </div>
      </div>

      <!--tabs切换-->
      <div class="container-tabs">
        <el-tabs v-model="activeName" @tab-click="tabsClick">
          <el-tab-pane label="积分兑换" name="first" >
            <!--商品显示及分页-->
            <div>
              <div class="container-box" v-if="orderDataList.length > 0">
                <div
                  class="container-item"
                  v-for="(item, index) in orderDataList"
                  :key="index"
                >
                  <div class="goodsItem" @click="toDetail(item)">
                    <img :src="item.picture" alt="" />
                    <p class="title-1">{{ item.goodsName }}</p>
                    <div class="title-2">
                      <div>{{ item.described }}</div>
                    </div>
                    <div>
                      <div class="btn">{{ item.price }}积分</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="empty">
                <img src="@/assets/images/noContent.png" alt="" />
              </div>

              <div class="demo-pagination-block" v-if="orderData.total">
                <el-pagination
                  :current-page="orderData.current"
                  :page-size="12"
                  background
                  layout="prev, pager, next,total, jumper"
                  :total="orderData.total"
                  next-text="下一页"
                  prev-text="上一页"
                  @current-change="handleCurrentChange"
                />
                <el-button class="btn-cl">确定</el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="获取积分" name="second" >
            <div class="text-bg">
              <div class="text"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  <points-recodes v-if="showPointsRecodes" @close="showPointsRecodes=false"></points-recodes>
  <give-recodes v-if="showGiveRecodes" @close="showGiveRecodes=false"></give-recodes>
</template>

<script setup>
import { ref } from "vue";
import {useRouter} from 'vue-router'
import { productPointsList, pointsMy, pointsBySign, pointsIsSign, pointsGiveNum } from "@/api/points";
import ossPath from "@/global-variable";
import { ElMessage } from "element-plus";
import BgPic from "../img/bg.png";
import emitter from "@/utils/eventBus";
import PointsRecodes from './pointsRecodes.vue'
import GiveRecodes from './giveRecodes.vue'
import { getTime } from "@/utils/utils";
import { safeButton } from "@/api/login";

const router=useRouter()
const activeName = ref("first");

const orderData = ref({});
const orderDataList = ref([]);
const query = ref({
  pageNum: 1,
  pageSize: 12,
});

//积分记录
const showPointsRecodes=ref(false)
const toPointsRecodes=()=>{
  showPointsRecodes.value=true
}
//兑换记录
const showGiveRecodes=ref(false)
const toGiveRecodes=()=>{
  showGiveRecodes.value=true
}

// 分页
const handleCurrentChange = (Num) => {
  query.value.pageNum = Num;
  getOrderList();
};

//点击tabs切换埋点
const tabsClick=(e)=>{
  let label= e.props.label
  let referer = document.referrer
  if (referer === '') {
    referer = '直接访问'
  } else if (referer.indexOf('?') !== -1) {
    referer = referer.substring(0, referer.indexOf('?'))
  }
  let timer=localStorage.getItem('buttonTimer')
  timer=Number(timer)

  let data = {
    page: '积分中心', //来源页面
    targetPage: label, //目标页面
    clickEvent: label, //按钮名称
    client: 'PC', //终端
    visitTime: getTime(timer), //访问时间
    source: referer
  }
  safeButton(data)
}


//获取积分商品
const getOrderList = () => {
  productPointsList(query.value).then((res) => {
    orderData.value = res.data;
    res.data.list.forEach((v) => {
      v.picture = ossPath.ossPath + "/goods" + v.picture;
      v.detailPicture=v.detailPicture.split(',').map(o=>{
       return ossPath.ossPath + "/goods" + o
      }).join(',');
    });
    orderDataList.value = res.data.list;
  });
};

//我的积分
const myPoints = ref();
const isLogin=ref(true)
isLogin.value=!!localStorage.getItem("token");
const getMyPoints = () => {
  if (isLogin.value){
    pointsMy().then((res) => {
      myPoints.value = res.data;
    });
  }
};

//签到领取积分
const signToPoints = () => {
  pointsBySign().then((res) => {
    if (res.code === 200) {
      ElMessage({
        message: "签到成功",
        type: "success",
      });
      isSign();
      getMyPoints();
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};

//积分商品详情
const  toDetail=(item)=>{
  if (!isLogin.value){
    toLogin()
    return
  }

  let copy=JSON.parse(JSON.stringify(item))
  delete copy.createdTime
  delete copy.updatedTime
  localStorage.setItem('pointsDetails',JSON.stringify(copy))
  router.push({
    path:'/pointsDetails',
  })
}

//登录
const toLogin=()=>{
  emitter.emit("openLogin",{openLogin:true})
}


//查询是否已经签到过
const signIs=ref(false)
const isSign=()=>{
  if (isLogin.value){
    pointsIsSign().then(res=>{
      signIs.value= res.data
    })
  }
}

//查询签到一次获取多少积分
const getRuleNum=ref()
const getNum=()=>{
  pointsGiveNum().then(res=>{
    getRuleNum.value=res.data
  })
}


isSign();
getNum()
getMyPoints();
getOrderList();
</script>

<style scoped lang="less">
.text-bg{
  margin-top:26px;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 16px;
  box-sizing: border-box;
  .text{
    width: 100%;
    height: 2300px;
    background: #FFFFFF url("../img/text-bg.png") no-repeat;
    background-size: 100%;
  }
}
.public-css() {
  width: 168px;
  border-radius: 8px;
  background: #f2f2f2;
  padding: 19px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    color: #333333;
    font-size: 16px;
  }

  img {
    width: 16px;
    height: 16px;
  }
}

.point-page {
  width: 100%;

  .container-top {
    .img-box {
      width: 100%;
      height: 400px;
    }
  }

  .content-box {
    width: 100%;
    padding: 42px 360px;
    box-sizing: border-box;

    .container-center {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 156px;

      .content-1 {
        width: 792px;
        height: 100%;
        background: #ffffff;
        border-radius: 12px;
        padding: 42px 83px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .my-points {
          width: 150px;

          .my-points-title {
            & > img {
              width: 16px;
              height: 16px;
            }

            & > span {
              margin-left: 5px;
              color: #999999;
              font-size: 14px;
            }
          }

          .my-points-value {
            color: #333333;
            font-size: 48px;
          }
        }

        .point-recodes-button {
          width: 360px;
          height: 60px;
          display: flex;
          justify-content: space-between;

          .recodes-give {
            .public-css();
          }

          .recodes-point {
            .public-css();
          }

          .login-button {
            color: #ffffff;
            background: #ce1200;
            width: 168px;
            height: 44px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }

      .content-2 {
        background: url("../img/right-bg.png");
        background-size: 100%;
        width: 396px;
        height: 100%;
        border-radius: 12px;
        padding: 56px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .num {
          color: #333333;
          font-size: 48px;
          height: 44px;
          line-height: 44px;
        }

        .title {
          color: #999999;
          font-size: 14px;
          height: 44px;
          display: flex;
          align-items: flex-end;
          margin: 0 10px 0 5px;
        }

        .tips {
          width: 149px;
          height: 44px;
          cursor: pointer;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .container-tabs {
      margin-top: 42px;

      :deep(.el-tabs__nav-wrap::after) {
        position: static !important;
      }

      :deep(.el-tabs__item) {
        color: #999999 !important;
        font-size: 20px !important;
      }

      :deep(.el-tabs__item.is-active) {
        color: #ce1200 !important;
      }

      :deep(.el-tabs__active-bar) {
        background-color: #ce1200 !important;
      }
    }

    .container-box {
      width: 100%;
      margin: 30px auto 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;

      .container-item {
        .goodsItem {
          cursor: pointer;
          border-radius: 5px;
          height: 396px;
          background-color: #ffffff;
          .hoverShadow ();
          padding: 24px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 240px;
            height: 240px;
          }

          .title-1 {
            width: 237px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 10px;
          }

          .title-2 {
            height: 50px;

            & > div {
              width: 237px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-top: 10px;
            }
          }

          .btn {
            width: 237px;
            margin-top: 10px;
            color: #ce1200;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}

.demo-pagination-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  :deep(.is-active){
    background: #ce1200 !important;
  }

  :deep(.number:hover){
    color: #ce1200 !important;
  }
  :deep(.is-active:hover){
    color: #FFFFFF !important;
  }

  .btn-cl {
    width: 65px;
    height: 35px;
    margin-left: 10px;
    font-size: 14px;
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;

  & > img {
    width: 50%;
    height: 100%;
  }
}
</style>
