<template>
  <div v-if="isVisible" class="overlay" >
    <div class="modal-content">
      <div class="lottery-dialog-box">
         <div class="title">{{info.title}}</div>
        <div>
          <img class="lottery-img" :src="info.src" alt="">
        </div>
        <div class="get-lottery-box">
          <img src="../img/getLottery.png" alt="" class="get-lottery" @click="close">
          <div class="tooltip" v-if="info.title!=='鼠标垫' && info.title!=='显示器'">请在我的优惠券中查看</div>
        </div>
      </div>
      <div class="close-box">
        <img class="close-img" @click="close" alt="" src="../img/close.png"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
const emits=defineEmits(['close'])
const info=reactive({
  title:'',
  src:''
})
const props=defineProps({
  messageObj:{
    type:Object
  }
})
let arr=[
  {name:'20元',title:'20元无门槛优惠券',img:require('../img/get-lottery/20.png')},
  {name:'50元',title:'50元无门槛优惠券',img:require('../img/get-lottery/50.png')},
  {name:'80元',title:'80元无门槛优惠券',img:require('../img/get-lottery/80.png')},
  {name:'100元',title:'100元无门槛优惠券',img:require('../img/get-lottery/100.png')},
  {name:'显示器',title:'显示器',img:require('../img/get-lottery/monitor.png')},
  {name:'鼠标垫',title:'鼠标垫',img:require('../img/get-lottery/mouse-pad.png')}
]



// 控制遮罩显示/隐藏
const isVisible = ref(true);


// 在组件挂载时禁用页面滚动
onMounted(() => {
  let obj=arr.find(v=>v.name===props.messageObj.title)
  info.title=obj.title
  info.src=obj.img
  document.body.style.overflow = 'hidden';
});




// 关闭遮罩函数
const close = () => {
  isVisible.value = false;
  document.body.style.overflow = '';
  emits('close')
};
</script>

<style scoped lang="less">
/* 全屏遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


/* 弹窗内容 */
.modal-content {
  text-align: center;
  position: relative;

  .lottery-dialog-box{
    width: 800px;
    height: 800px;
    background: url("../img/lottery-dialog.png"),no-repeat;
    background-size: 100%;
    padding-top: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
      color: #6C655C;
      font-size: 24px;
    }
    .lottery-img{
      margin-top: 20px;
      width: 234px;
      height: 227px;
    }
    .get-lottery-box{
      position: relative;
      .get-lottery{
        width: 382px;
        height: 133px;
        margin-top: 20px;
        cursor: pointer;
      }
      .tooltip{
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        color: #9D9182;
        font-size: 18px;
      }
    }

  }

  .close-img{
    width: 110px;
    height: 110px;
    cursor: pointer;
  }
}





</style>
