import request from "@/utils/request";

//积分商品列表
export function productPointsList(data) {
  return request.get("/goods/points/query/list",data);
}

//积分兑换商品
export function pointsRedeem(data) {
  return request.post("/order/points/redeem/redeem",data);
}

//兑换记录
export function pointsRedeemList(data) {
  return request.get("/order/points/redeem/query/list",data);
}


//查询我的积分
export function pointsMy() {
  return request.get("/customer/points/balance/my");
}

//签到领取积分
export function pointsBySign() {
  return request.post("/customer/points/balance/sign");
}

//积分记录
export function pointsRecodesList(params) {
  return request.get("/customer/points/balance/flow/list",params);
}

//判断是否已经签到
export function pointsIsSign(params) {
  return request.get("/customer/points/balance/is/sign",params);
}

//获取后台规则，签到获取多少积分
export function pointsGiveNum(params) {
  return request.get("/customer/points/balance/sign/give/num",params);
}







