<template>
  <AppLayout>
    <div class="lx">
      <div class="lx-top">
        <div class="lx-top-text">
          <div class="lx-top-1">我们为您定制专属生产力工具</div>
          <div class="lx-top-2">
            用心服务客户超14年，专业技术团队，提交您的需求，我们为您优中选甄
          </div>
          <div class="lx-top-2">
            为您精选高性能的硬件，配置强大的多线程处理器保障多任务的执行，
          </div>
          <div class="lx-top-2">
            确保每一台宁美出厂的硬件100%具有稳定均衡的应用场景
          </div>
        </div>
      </div>
      <!--<img src="./img/top.jpg" />-->
      <div class="bill">
        <img src="./img/order.png" class="bill-1" />
        <div class="bill-2">
          <div class="bill-3">客户及订单信息</div>
          <div class="bill-4">
            <div class="bill-4-1">开单编号：</div>
            <div class="bill-4-2">{{ billInfo.sellNumber }}</div>
          </div>
          <div class="bill-5">
            <div class="bill-5-1">客户手机号：</div>
            <div class="bill-5-2">{{ billInfo.customerPhone }}</div>
          </div>
          <div class="bill-5">
            <div class="bill-5-1">客户名称：</div>
            <div class="bill-5-2">{{ billInfo.customerName }}</div>
          </div>
          <div class="bill-8"></div>
          <div class="bill-9">订单总金额</div>
          <div class="bill-4">
            <div class="bill-4-1">订单金额：</div>
            <div class="bill-order-money">￥{{ billInfo.orderAmount }}</div>
          </div>
          <div class="bill-8"></div>
          <div class="bill-9">订单及商品信息</div>
          <div v-for="(item, index) in billInfo.sellOneDTO4s">
            <div class="goods-1">
              <div class="goods-2">商品{{ index + 1 }}</div>
              <img :src="item.picture" class="goods-3" />
              <div class="goods-4">商品编码： {{ item.goodsCode }}</div>
              <div class="goods-5">商品名称： {{ item.goodsName }}</div>
              <div class="goods-6">订购数量： {{ item.goodsNum }}</div>
              <div class="goods-7">
                订单金额：
                <span class="orderMoney">￥{{ item.orderAmount }}</span>
              </div>
              <div class="goods-8" @click="goodssetting(item)">
                商品配置信息
              </div>
            </div>
          </div>
          <div class="bill-10">
            <div class="bill-10-1" @click="confirmOrder">确认订单</div>
            <div class="bill-10-2" @click="reject">我不同意</div>
          </div>
        </div>
      </div>
      <el-dialog
        v-model="popUpList"
        style="width: 50%; height: 85%; --el-dialog-margin-top: 10vh"
      >
        <div style="display: flex; margin-left: 51px; margin-top: -16px">
          <div class="dialog-list-1">商品配置信息</div>
          <div class="dialog-list-2">
            <div class="container">
              <div class="container-top-t">
                <div
                  class="container-top-left"
                  v-if="tapPane?.sysCore.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">系统核心</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane.sysCore"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 1)"
                                  >
                                    <div class="img-top-content-m-1">
                                      {{ item.materialName }}
                                    </div>
                                    <div class="img-top-content-m-2">
                                      ×{{ item.amount }}
                                    </div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div
                  class="container-top-left"
                  v-if="tapPane?.store.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">存储</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane?.store"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 2)"
                                  >
                                    <div
                                      :class="
                                        item.materialName != ''
                                          ? 'img-top-content-m-1'
                                          : 'img-top-content-m-1-1'
                                      "
                                    >
                                      {{
                                        item.materialName != ""
                                          ? item.materialName
                                          : "请选择"
                                      }}
                                    </div>
                                    <div
                                      class="img-top-content-m-2"
                                      v-if="item.count != 0"
                                    >
                                      ×{{ item.count }}
                                    </div>
                                    <div
                                      class="img-top-content-m-2-2"
                                      v-else
                                    ></div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div
                  class="container-top-left"
                  v-if="tapPane?.radiator.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">散热</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane.radiator"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 3)"
                                  >
                                    <div class="img-top-content-m-1">
                                      {{ item.materialName }}
                                    </div>
                                    <div class="img-top-content-m-2">
                                      ×{{ item.count }}
                                    </div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div
                  class="container-top-left"
                  v-if="tapPane?.component.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">配件</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane.component"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 4)"
                                  >
                                    <div class="img-top-content-m-1">
                                      {{ item.materialName }}
                                    </div>
                                    <div class="img-top-content-m-2">
                                      ×{{ item.count }}
                                    </div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div
                  class="container-top-left"
                  v-if="tapPane?.softWare.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">软件</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane.softWare"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 5)"
                                  >
                                    <div class="img-top-content-m-1">
                                      {{ item.materialName }}
                                    </div>
                                    <div class="img-top-content-m-2">
                                      ×{{ item.count }}
                                    </div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div
                  class="container-top-left"
                  v-if="tapPane?.service.length > 0"
                >
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">服务</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in tapPane.service"
                        :key="item.id + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.typeName }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div v-if="item.optionalSupport == 1">
                                  <div
                                    class="img-top-content-m"
                                    @click="handlePopUpList(item, index, 6)"
                                  >
                                    <div
                                      :class="
                                        item.materialName != ''
                                          ? 'img-top-content-m-1'
                                          : 'img-top-content-m-1-1'
                                      "
                                    >
                                      {{
                                        item.materialName != ""
                                          ? item.materialName
                                          : "请选择"
                                      }}
                                    </div>
                                    <div
                                      class="img-top-content-m-2"
                                      v-if="item.count != 0"
                                    >
                                      ×{{ item.count }}
                                    </div>
                                    <div
                                      class="img-top-content-m-2-2"
                                      v-else
                                    ></div>
                                  </div>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.amount }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
                <div class="container-top-left" v-if="giftInfo?.length > 0">
                  <div class="title-text">
                    <span class="text-span"></span>
                    <div class="container-top-l">赠品</div>
                  </div>
                  <div class="container-top-l-lx">
                    <ui>
                      <li
                        class="top-l-lx-cpu"
                        v-for="(item, index) in giftInfo"
                        :key="item.materialCode + index"
                      >
                        <div>
                          <div class="img-top-content">
                            <div class="img-top-content-text">
                              <p class="img-top-content-l">
                                {{ item.materialType }}{{ index + 1 }}
                              </p>
                              <div class="img-top-content-r">
                                <div class="img-top-text-r">
                                  <span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                                </div>
                                <div class="input-number-num">
                                  <p class="num-span">×{{ item.count }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ui>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-list-3" @click="closegoods">确定</div>
        </div>
      </el-dialog>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { onMounted, ref } from "vue";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "../home/components/HomeBackup.vue";
import { billdetail, confirm } from "@/api/order/index";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";

const route = useRoute();
const sellNum = ref("");
const billInfo = ref({});
const tapPane = ref([]);
const giftInfo = ref([]); // 赠品信息
const router = useRouter();
const popUpList = ref(false);
const getDetailGoods = (num) => {
  billdetail(num).then((res) => {
    billInfo.value = res.data;
  });
};
const closegoods = () => {
  popUpList.value = false;
};
const confirmOrder = () => {
  let data = {
    sellNumber: route.query.sellNum,
    status: 3,
  };
  confirm(data).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: "确认订单成功",
        type: "success",
      });
      setTimeout(function () {
        router.push({
          path: "/payorder",
          query: {
            orderNumber: res.data,
          },
        });
      }, 500);
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};
const goodssetting = (data) => {
  popUpList.value = true;
  giftInfo.value = data.giftVOS;
  tapPane.value = data.infoVO;
};
const reject = async () => {
  let res = await ElMessageBox.confirm("是否不同意该订单?", {
    confirmButtonText: "是",
    cancelButtonText: "否",
    type: "warning",
  });
  if (res == "confirm") {
    let data = {
      sellNumber: route.query.sellNum,
      status: 4,
    };
    confirm(data).then((res) => {
      if (res.code == 200) {
        router.push({ path: "/" });
      } else {
        ElMessage({
          message: res.message,
          type: "warning",
          center: true,
        });
      }
    });
  }
};
onMounted(() => {
  getDetailGoods(route.query.sellNum);
});
</script>

<style lang="less" scoped>
.bill-order-money {
  font-family: MicrosoftYaHei-Bold;
  font-size: 24px;
  color: #ce1200;
}
.orderMoney {
  font-family: MicrosoftYaHei;
  color: #ce1200;
  font-size: 15px;
}
.dialog-list-1 {
  height: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 22px;
  color: #333333;
}
.dialog-list-3 {
  width: 180px;
  height: 40px;
  background: #ce1200;
  cursor: pointer;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  bottom: 32px;
  left: 380px;
  line-height: 38px;
}
.dialog-list-2 {
  width: 820px;
  height: 590px;
  overflow: auto;
  background: #f7f7f7;
  border-radius: 10px;
  position: absolute;
  top: 94px;
  overflow-x: hidden;
  .container {
    margin-left: 39px;
    margin-top: 26px;
    .container-top-t {
      .container-top-left {
        .title-text {
          height: 20px;
          display: flex;
          margin-bottom: 3px;
          margin-left: 13px;
          .text-span {
            margin-top: 3px;
            left: 0;
            border-left: 3px solid #ce1200;
          }
          .container-top-l {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 3px;
            margin-left: 6px;
          }
        }
        .container-top-l-lx {
          width: 100%;
          .img-top-content {
            display: flex;
            .img-top-content-text {
              display: flex;
              margin-left: 130px;
              height: 26px;
              .img-top-content-l {
                width: 76px;
                line-height: 40px;
                height: 40px;
                font-size: 15px;
                font-weight: 400;
                color: #333333;
              }
              .img-top-content-r {
                width: 438px;
                height: 36px;
                line-height: 38px;
                display: flex;
                justify-content: space-between;
                margin-left: 32px;
                .img-top-text-r {
                  .img-top-content-r-text {
                    font-size: 11px;
                    font-weight: 400;
                    color: #555555;
                  }
                }
                .num-inputs {
                  display: flex;
                  margin-top: 3px;
                  .btn_minute {
                    width: 32px;
                    height: 32px;
                    background: #f2f2f2;
                    border: 1px solid #d9d9d9;
                    cursor: pointer;
                  }
                  .btn_add {
                    width: 32px;
                    height: 32px;
                    background: #f2f2f2;
                    border: 1px solid #d9d9d9;
                    cursor: pointer;
                  }
                  .btn-input {
                    width: 37px;
                    height: 32px;
                    border-top: 1px solid #d9d9d9;
                    border-bottom: 1px solid #d9d9d9;
                    border-left: 0;
                    border-right: 0;
                    background-color: #ffffff;
                    padding-left: 12px;
                  }
                }
                .img-top-content-m {
                  width: 405px;
                  height: 40px;
                  line-height: 40px;
                  border: 1px solid #d9d9d9;
                  display: flex;
                  .img-top-content-m-1 {
                    padding-left: 10px;
                    font-size: 11px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    width: 350px;
                    color: #555555;
                    cursor: pointer;
                  }
                  .img-top-content-m-1-1 {
                    padding-left: 10px;
                    width: 350px;
                    font-size: 11px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    cursor: pointer;
                  }
                  .img-top-content-m-2 {
                    font-size: 11px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #555555;
                  }
                  .img-top-content-m-2-2 {
                    font-size: 11px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #555555;
                    margin-left: 15px;
                  }
                  .img-top-content-m-3 {
                    width: 15px;
                    height: 8px;
                    margin: 0 0 3px 10px;
                  }
                }
                .input-number-num {
                  .num-span {
                    font-size: 11px;
                    font-weight: 400;
                    color: #555555;
                  }
                }
              }
            }
            .img-top-content-plus {
              font-size: 13px;
              font-weight: 800;
              font-weight: bold;
              color: #ce1200;
              height: 8px;
              line-height: 40px;
              margin-left: 10px;
            }
            .img-top-content-reduce {
              font-size: 13px;
              font-weight: 800;
              font-weight: bold;
              color: #04ca17;
              height: 8px;
              line-height: 40px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
.goods-1 {
  width: 840px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  margin-left: 100px;
  margin-bottom: 16px;
  position: relative;
  .goods-2 {
    position: absolute;
    top: 16px;
    left: 41px;
  }
  .goods-3 {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 48px;
    left: 41px;
  }
  .goods-4 {
    height: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    position: absolute;
    top: 52px;
    left: 181px;
  }
  .goods-5 {
    height: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    position: absolute;
    top: 82px;
    left: 181px;
  }
  .goods-6 {
    height: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    position: absolute;
    top: 112px;
    left: 181px;
  }
  .goods-7 {
    height: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    position: absolute;
    top: 142px;
    left: 181px;
  }
  .goods-8 {
    width: 120px;
    height: 30px;
    border: 1px solid #aaaaaa;
    position: absolute;
    top: 138px;
    right: 31px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
  .goods-8:hover {
    width: 120px;
    height: 30px;
    background: #ce1200;
    position: absolute;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    border: none;
    top: 138px;
    right: 31px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
  }
}
.lx {
  width: 1920px;
  background-color: #f7f7f7;
  .lx-top {
    height: 300px;
    background-image: url("./img/top1.jpg");
    background-size: cover;
    .lx-top-text {
      margin-left: 300px;
      height: 100%;
      line-height: 1.7;
      padding-top: 100px;
      /*display: flex;
		  flex-direction: column;
		  justify-content: center;*/
      .lx-top-1 {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 32px;
        color: #4c1c0e;
      }
      .lx-top-2 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #4c1c0e;
      }
    }
  }
  .bill {
    width: 1920px;
    background: #f7f7f7;
    padding: 0 0 40px 0;
    .bill-1 {
      margin: 60px 0 40px 896px;
    }
    .bill-2 {
      width: 1040px;
      background: #ffffff;
      overflow: hidden;
      margin: 0 auto;
      .bill-3 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #333333;
        margin: 60px 0 0 100px;
      }
      .bill-4 {
        display: flex;
        align-items: center;
        margin: 16px 0 0 100px;
        .bill-4-1 {
          width: 80px;
          height: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
          margin-right: 23px;
        }
        .bill-4-2 {
          height: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
        }
      }
      .bill-5 {
        display: flex;
        margin: 14px 0 0 100px;
        .bill-5-1 {
          width: 102px;
          height: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
        }
        .bill-5-2 {
          height: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
        }
      }
      .bill-6 {
        display: flex;
        margin: 14px 0 0 138px;
        .bill-6-1 {
          width: 102px;
          height: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
        }
        .bill-6-2 {
          height: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 24px;
          margin-top: -8px;
          color: #ce1200;
        }
      }
      .bill-8 {
        width: 760px;
        height: 1px;
        background: #eeeeee;
        margin: 36px 0 0 100px;
      }
      .bill-9 {
        width: 162px;
        height: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #333333;
        margin: 43px 0 27px 100px;
      }
      .bill-10 {
        margin: 29px 0 68px 100px;
        display: flex;
        .bill-10-1 {
          width: 414px;
          height: 54px;
          background: #ce1200;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #ffffff;
          text-align: center;
          line-height: 54px;
          margin-right: 12px;
          cursor: pointer;
        }
        .bill-10-2 {
          width: 414px;
          height: 54px;
          background: #f2f2f2;
          border-radius: 5px;
          font-family: Microsoft YaHei;
          line-height: 54px;
          font-weight: 400;
          font-size: 15px;
          text-align: center;
          color: #333333;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
