<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="menustatus">
    <div class="menustatusmiddle">
      <div style="display: flex">
        <div class="fontstyle">1</div>
        <div class="fontsite">确认产品配置信息</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">2</div>
        <div class="fontsiteorther">支付订单</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">3</div>
        <div class="fontsiteorther">填写收货地址</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">4</div>
        <div class="fontsiteorther">填写发票信息</div>
      </div>
    </div>
  </div>
  <div class="containerC">
    <div class="containerContent">
      <img src="./image/shop.png" class="containerimg" />
      <div class="container1">商品信息</div>
      <div class="container2">
        <div class="container3" v-for="(item, index) in tempdata">
          <div v-if="item.ischeck">
            <img :src="item.picture" class="containera" />
            <div class="containerf">{{ item.productName }}</div>
            <div class="containerd">{{ item.configType }}</div>
            <div class="containerc">￥{{ item.price }}</div>
            <div class="container4">x{{ item.productAmount }}</div>
            <div class="container5">
              合计：￥{{ item.price * item.productAmount }}
            </div>
            <div class="container6" @click="querysetting(item)">配置信息</div>
            <div class="center"></div>
            <div v-if="item.customerMaterials">
              <div
                class="orderlist-4"
                v-for="(custome, inx) in item.customerMaterials"
              >
                <div class="orderlist-4-1">客制</div>
                <div class="orderlist-4-2">{{ custome.materialName }}</div>
                <div class="orderlist-4-3">¥{{ custome.price }}</div>
                <div class="orderlist-4-4">x{{ item.productAmount }}</div>
                <img
                  :src="custome.customerLogos[0].img"
                  class="orderlist-4-5"
                />
                <div class="orderlist-4-6">{{ custome.originalFilename }}</div>
                <div
                  class="orderlist-4-7"
                  @click="customMade(custome.id, inx, item, index)"
                >
                  {{ custome.type == 0 ? "修改图片" : "修改选项" }}
                </div>
              </div>
            </div>
            <div class="floor"></div>
          </div>
        </div>
      </div>
      <!-- 优惠券 -->
      <div class="coupon-container" v-if="couponList.length">
        <div class="title">
          <img
            src="./image/title-2.png"
            alt=""
            style="width: 32px; height: 32px"
          />
          <span>优惠信息</span>
        </div>
        <div class="coupon-list" v-if="couponList.length">
          <div v-for="item in couponList" class="coupon-li">
            <div class="coupon-li-left">
              <div class="amount"><span>￥</span>{{ item.amount }}</div>
              <span>满 {{ item.amountOver }} 元可用</span>
              <span>有效期至 {{ item.validityEnd }}</span>
            </div>
            <div class="coupon-li-right" @click="checkCoupon(item)">
              <img v-if="item.isCheck" src="./image/isTrue.png" alt="" />
              <img v-else src="./image/isFalse.png" alt="" />
            </div>
          </div>
        </div>
        <div style="text-align: center" v-else>
          <!--<img src="./image/default1.png" style="width: 320px;height: 200px;">
			    <div style="font-family: Microsoft YaHei;font-weight: 400;font-size: 18px;color: #999999;">
				    暂无优惠券
			    </div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="conbottom">
    <div class="conbottom1"></div>
    <div class="conbottom2">订购总数量{{ count }}件</div>
    <div class="conbottom3">
      <div style="display: flex">
        <div class="conbottom33">订单总金额：</div>
        <div class="conbottom4">¥ {{ total }}</div>
      </div>
      <div class="autodiy-bottom" v-if="discountedPrice">
        <div>已优惠金额：</div>
        <div>-￥{{ discountedPrice }}</div>
      </div>
    </div>
    <div class="conbottom5" @click="submitOrd">提交订单</div>
  </div>
  <div class="conbottom6"></div>
  <HostCustome v-model="showCustom" />
  <div class="dialog-list">
    <el-dialog
      style="width: 62%; height: 76%; --el-dialog-margin-top: 10vh"
      v-model="popUpList"
    >
      <div class="border-content">
        <el-table
          :data="orderMaterialVOSMaterial"
          height="32.5rem"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="materialName" label="物料名称" width="380" />
          <el-table-column prop="amount" label="数量" align="center" />
          <el-table-column prop="typeName" label="物料类型" align="center" />
          <el-table-column
            prop="materialCode"
            label="物料编码"
            align="center"
          />
        </el-table>
        <div class="qOk" @click="popUpList = false">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import AppHeader from "@/components/AppHeader";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { shopplace, shopSetting } from "@/api/shop/index";
import HostCustome from "@/components/hostCustomer";
import emitter from "@/utils/eventBus";
import { couponListMy } from "@/api/coupon";

const route = useRoute();
const num = ref(1);
const count = ref(0);
const popUpList = ref(false);
const total = ref(0);
const inxx = ref(0);
const totalData = ref([]);
const orderMaterialVOSMaterial = ref([]);
const tempdata = ref([]);
const showCustom = ref(false);
const configPrice = ref();
const pictures = ref();
const customMade = (id, inx, item, index) => {
  showCustom.value = true;
  inxx.value = index;
  emitter.emit("customId", {
    customId: id,
    configPrice: item.price,
    productList: item.customerMaterials,
    proId: inx,
  });
};
const querysetting = (val) => {
  shopSetting(val.id).then((res) => {
    orderMaterialVOSMaterial.value = res.data;
    popUpList.value = true;
  });
};
emitter.on("customClose", (val) => {
  tempdata.value[inxx.value].customerMaterials =
    val.productCustomizedMaterialPOS;
  tempdata.value[inxx.value].price = val.configPrice;
  showCustom.value = false;
});
const router = useRouter();
const submitOrd = () => {
  let array = [];
  tempdata.value.forEach((element) => {
    if (element.ischeck) {
      array.push({
        id: element.id,
        productAmount: element.productAmount,
      });
    }
  });
  let data = {
    orderCartDTO2s: array,
    source: 0,
    receiveId: receiveNumber.value,
  };
  shopplace(data).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: "提交订单成功",
        type: "success",
      });
      setTimeout(function () {
        router.push({
          path: "/payorder",
          query: {
            orderNumber: res.data,
          },
        });
      }, 500);
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};

const discountedPrice = ref(0); // 优惠金额
const receiveNumber = ref(); // 选择的优惠券领取id
// 勾选优惠券
const checkCoupon = (couponData) => {
  if (couponData.isCheck) {
    discountedPrice.value = 0;
    couponData.isCheck = false;
    receiveNumber.value = null;
    total.value += couponData.amount;
  } else {
    couponList.value.forEach((item) => {
      if (item.isCheck == true) {
        item.isCheck = false;
        total.value += item.amount;
      }
    });
    couponData.isCheck = true;
    receiveNumber.value = couponData.receiveId;
    discountedPrice.value = couponData.amount;
    total.value = Math.round((total.value - discountedPrice.value) * 100) / 100;
  }
};
const couponList = ref([]);
// 查询可用优惠券
const getCouponList = (orderAmount) => {
  discountedPrice.value = 0;
  couponListMy(Number(orderAmount))
    .then((res) => {
      if (res.code == 200) {
        res.data.forEach((item) => {
          item.isCheck = false;
        });
        couponList.value = res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  tempdata.value = JSON.parse(route.query.item);
  count.value = route.query.count;
  total.value = route.query.total;
  getCouponList(total.value);
});
</script>
<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.containerC {
  margin: 0 auto;
  width: 1920px;
  min-height: 638px;
  .containerContent {
    width: 1200px;
    background: #ffffff;
    margin-top: 131px !important;
    margin-bottom: 37px !important;
    margin: 0 auto;
    padding-bottom: 30px;
    .containerimg {
      position: relative;
      top: 54px;
      left: 101px;
    }
    .container1 {
      position: relative;
      top: 30px;
      left: 127px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
    }
    .container2 {
      margin: 50px 0 0 127px;
      .container3 {
        width: 973px;
        background: #f7f7f7;
        border-radius: 5px;
        position: relative;
        margin-bottom: 16px;
        .containera {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 20px;
          left: 24px;
        }
        .containerf {
          width: 260px;
          position: absolute;
          top: 32px;
          left: 116px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 17px;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .containerd {
          position: absolute;
          top: 65px;
          left: 116px;
          width: 192px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #555555;
        }
        .containerc {
          position: absolute;
          top: 49px;
          left: 453px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #555555;
        }
        .container4 {
          position: absolute;
          top: 49px;
          left: 569px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #555555;
        }
        .container5 {
          position: absolute;
          top: 49px;
          left: 644px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #ce1200;
        }
        .container6 {
          position: absolute;
          top: 44px;
          left: 825px;
          cursor: pointer;
          width: 100px;
          height: 32px;
          border-radius: 5px;
          border: 1px solid #aaaaaa;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          text-align: center;
          line-height: 30px;
          color: #666666;
        }
        .container6:hover {
          position: absolute;
          top: 44px;
          left: 825px;
          cursor: pointer;
          width: 100px;
          height: 32px;
          border-radius: 5px;
          border: 1px solid #ce1200;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          text-align: center;
          line-height: 30px;
          color: #ce1200;
        }
        .center {
          width: 1060px;
          height: 100px;
        }
        .orderlist-4 {
          height: 95px;
          width: 814px;
          margin-left: 113px;
          border-top: 1px solid #f2f2f2;
          .orderlist-4-1 {
            width: 44px;
            height: 24px;
            background: rgba(255, 105, 0, 0.1);
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 11px;
            color: #ce1200;
            text-align: center;
            line-height: 24px;
            position: relative;
            top: 36px;
          }
          .orderlist-4-2 {
            width: 295px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            top: 15px;
            left: 60px;
          }
          .orderlist-4-3 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #555555;
            position: relative;
            left: 328px;
            bottom: 6px;
          }
          .orderlist-4-4 {
            position: relative;
            left: 456px;
            bottom: 26px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #555555;
          }
          .orderlist-4-5 {
            width: 48px;
            height: 48px;
            border: 1px solid #e1e1e1;
            position: relative;
            left: 567px;
            bottom: 70px;
          }
          .orderlist-4-6 {
            width: 108px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            left: 538px;
            bottom: 64px;
          }
          .orderlist-4-7 {
            width: 100px;
            height: 32px;
            border-radius: 5px;
            border: 1px solid #aaaaaa;
            font-family: Microsoft YaHei;
            text-align: center;
            line-height: 30px;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            position: relative;
            left: 712px;
            bottom: 112px;
          }
          .orderlist-4-7:hover {
            width: 100px;
            height: 32px;
            border-radius: 5px;
            border: 1px solid #ce1200;
            font-family: Microsoft YaHei;
            text-align: center;
            line-height: 30px;
            font-weight: 400;
            font-size: 12px;
            color: #ce1200;
            cursor: pointer;
            position: relative;
            left: 712px;
            bottom: 112px;
          }
        }
        .floor {
          width: 1060px;
          height: 16px;
        }
      }
    }
    .coupon-container {
      width: 998px;
      margin-left: 101px;
      margin-bottom: 100px;
      .title {
        display: flex;
        justify-content: left;
        align-items: center;

        span {
          margin-left: 6px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }
      }
      .coupon-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 21px;
        margin-bottom: 14px;

        .coupon-li {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-left: 14px;
          margin-bottom: 14px;
          width: 315px;
          height: 112px;
          background: url("./image/background.png");
          background-size: cover;
          cursor: default;

          .coupon-li-left {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .amount {
              display: flex;
              align-items: center;
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 22px;
              color: #ce1200;

              span {
                font-weight: 400 !important;
                font-size: 14px !important;
              }
            }

            & > span:nth-child(2) {
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #ce1200;
            }

            & > span:nth-child(3) {
              margin-top: 4px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
            }
          }

          .coupon-li-right {
            cursor: pointer;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
.conbottom {
  height: 72px;
  width: 1200px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  .conbottom1 {
    width: 1160px;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    top: 99px;
    left: 20px;
  }
  .conbottom2 {
    width: 93px;
    height: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #595959;
    position: absolute;
    bottom: 28px;
    left: 71px;
  }
  .conbottom3 {
    position: absolute;
    //bottom: 16px;
    right: 269px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    line-height: 24px;
    .conbottom33 {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    .conbottom4 {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 24px;
      color: #ce1200;
    }
    .autodiy-bottom {
      display: flex;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
    }
  }
  .conbottom5 {
    position: absolute;
    line-height: 48px;
    bottom: 11px;
    right: 28px;
    width: 200px;
    height: 48px;
    background: #ce1200;
    border-radius: 5px;
    cursor: pointer;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
  }
}
.conbottom6 {
  width: 100%;
  height: 80px;
}
.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}
.menustatus {
  width: 100%;
  height: 84px;
  background-color: #fff;
  position: fixed;
  z-index: 1;
}
.menustatusmiddle {
  position: relative;
  top: 32%;
  display: flex;
  justify-content: center;
}
.fontstyle {
  width: 30px;
  height: 30px;
  background: #ce1200;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.fontstyleorther {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  text-align: center;
  line-height: 30px;
  color: #aaaaaa;
}
.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}
.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}
.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}
::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;
    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }
    .el-dialog__close:hover {
      color: #ce1200;
    }
  }
  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;
    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.diy22plus {
  float: left;
  width: 1200px;
  height: 68px;
  background: #fff;
  /* border-top: 1px solid #E1E1E1; */
  position: fixed;
  bottom: 0px;
  .diy22line {
    width: 1150px;
    height: 1px;
    text-align: center;
    background-color: #e1e1e1;
    margin-left: 25px;
  }
  .diy22 {
    position: fixed;
    left: 431px;
    bottom: 29px;
    width: 85px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
  }
  .diy23 {
    position: fixed;
    left: 537px;
    bottom: 18px;
    .plusmiuse {
      width: 100px;
      height: 32px;
    }
  }
  .diy24 {
    position: fixed;
    left: 664px;
    bottom: 19px;
    width: 32px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background-color: #fff;
  }
  .diy24:hover {
    color: #ce1200;
    border: 1px solid #ce1200;
  }
  .diy25 {
    position: fixed;
    left: 703px;
    bottom: 19px;
    width: 32px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background-color: #fff;
  }
  .diy25:hover {
    color: #ce1200;
    border: 1px solid #ce1200;
  }
  .diy26 {
    position: fixed;
    left: 742px;
    bottom: 19px;
    width: 32px;
    height: 30px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
  }
  .diy26:hover {
    color: #ce1200;
    border: 1px solid #ce1200;
  }
  .autodiy {
    /* height: 40px; */
    /* width: 60px; */
    display: flex;
    float: right;
    padding-right: 300px;
    margin-top: 18px;
    .diy27 {
      /* position: fixed;
      left: 1070px;
      bottom: 28px; */
      /* width: 70px; */
      height: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 8px;
    }
    .diy28 {
      /* position: fixed;
      left: 1147px;
      bottom: 32px; */
      /* width: 120px; */
      height: 18px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ce1200;
    }
  }
  .diy29 {
    position: fixed;
    left: 1290px;
    bottom: 8px;
    width: 200px;
    height: 48px;
    background: #ce1200;
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}
</style>
