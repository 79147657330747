<template>
  <AppLayout>
    <div class="page">
      <img src="./images/web_01.jpg" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_02.png" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_03.png" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_04.png" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_05.png" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_06.png" alt="" style="width: 100%; height: auto" />
      <img src="./images/web_07.png" alt="" style="width: 100%; height: auto" />
      <img
        src="./images/web_08.png"
        alt=""
        style="width: 100%; height: auto; cursor: pointer"
        @click="clickConsult"
      />
      <img src="./images/web_09.png" alt="" style="width: 100%; height: auto" />
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
import emitter from "@/utils/eventBus";

// 点击立即咨询
const clickConsult = () => {
  emitter.emit("onlineClient");
};
</script>

<style scoped lang="less">
.page {
  background-color: #f8f8f8;
  width: 1920px;
  margin: 0 auto;
}
</style>
