<template>
  <div class="details-con" v-if="amend === 1">
    <!-- 第一部分 -->
    <div class="con-t">
      <p class="con-t-x"><span @click="handelClick"></span>订单详情</p>
      <div class="con-t-m">
        <div>
          <p class="m-i">
            <img
              src="../../../assets/images/order/dzf.png"
              v-if="info.orderState === '待支付'"
              alt=""
            />
            <img
              src="../../../assets/images/order/dfk.png"
              v-if="info.orderState === '服务中'"
              alt=""
            />
            <img
              src="../../../assets/images/order/wc.png"
              v-if="info.orderState === '已完成'"
              alt=""
            />

            <img
              src="../../../assets/images/order/qx.png"
              v-if="info.orderState === '已取消(支付超时)'"
              alt=""
            />
            <img
              src="../../../assets/images/order/dsh.png"
              v-if="info.orderState === '待审核'"
              alt=""
            />
            {{ info.orderState }}
          </p>
        </div>
        <div style="display: flex">
          <div
            class="q-l-2"
            v-if="info.orderState === '服务中' || info.orderState === '已完成'"
          >
            <p class="m-k" @click="handleOpen">联系客服</p>
          </div>

          <div class="q-l-2" v-if="info.orderState === '待支付'">
            <p class="m-k" @click="handleUnpaid(info.orderNumber)">立即支付</p>
          </div>
        </div>
      </div>
    </div>

    <div class="cont-two">
      <div class="two-t">
        <div>
          <div class="two-t-row">
            <div>购买日期:</div>
            <div>{{ info.createTime }}</div>
          </div>
          <div class="two-t-row">
            <div>服务期限:</div>
            <div>{{ info.deadLine }}天</div>
          </div>
          <div class="two-t-row">
            <div>完成时间:</div>
            <div>{{ info.dealTime }}</div>
          </div>
          <div class="two-t-row">
            <div>完成备注:</div>
            <div>
              <el-input
                type="textarea"
                v-model="info.dealRemark"
                :rows="5"
                style="width: 500px"
                readonly
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 第三部分 -->
    <div class="cot-three">
      <div class="three-z">
        <div class="three-plus">
          <div style="display: flex">
            <p class="three-t" style="color: #1dc779">{{ info.type }}</p>
          </div>
          <div style="display: flex; align-items: center;cursor: pointer" @click="toServiceSupport">
            <el-icon>
              <QuestionFilled />
            </el-icon>
            <div style="font-size: 12px">服务介绍</div>
          </div>
        </div>
        <div class="three-c">
          <p class="three-i">
            <img :src="info.picture" alt="" />
          </p>
          <div class="three-ZP">
            <p class="three-n" :title="info.productName">
              {{ info.productName }}
            </p>
            <div style="display: flex; margin-top: 50px">
              <div class="ZW-two">
                <div class="sp1">商品单价：</div>
                <div class="sp2">￥{{ info.price }}</div>
              </div>
              <div class="n-numS">
                <div class="gm1">购买数量：</div>
                <div class="gm2">{{ info.count }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="two-t-row">
            <div>订单备注:</div>
            <div>
              <el-input
                type="textarea"
                v-model="info.remark"
                :rows="5"
                style="width: 500px"
                readonly
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 第四部分 -->
    <div class="con-four">
      <div class="four-cent">
        <p class="four-t">订单支付信息</p>
        <p class="four-d">
          <span>订单编号：</span>
          <span>{{ info.orderNumber }}</span>
        </p>
        <p class="times-one-1">
          <span>创建时间：</span><span>{{ info.createTime }}</span>
        </p>
        <p class="four-z">
          <span>支付状态：</span><span>{{ info.payState }}</span>
        </p>
        <div class="four-ZW">
          <div
            class="ZW-one"
            v-if="
              info.orderState != '已取消(支付超时)' ||
              info.orderState != '待支付'
            "
          >
            <div class="zf1">支付方式：</div>
            <div class="zf2">{{ info.payType }}</div>
          </div>
          <div class="ZW-two">
            <div class="sp1">商品总金额：</div>
            <div class="sp2">￥{{ info.goodsAmount }}</div>
          </div>
        </div>
        <div class="four-n">
          <div
            class="n-num"
            v-if="
              info.orderState != '已取消(支付超时)' ||
              info.orderState != '待支付'
            "
          >
            <div class="zfh1">支付交易号：</div>
            <div class="zfh2">{{ info.payNumber }}</div>
          </div>
          <div class="n-numS">
            <div class="gm1">优惠金额：</div>
            <div class="gm2">-￥{{ info.discountAmount }}</div>
          </div>
        </div>
        <div class="times-two">
          <div
            class="times-a"
            v-if="
              info.orderState != '已取消(支付超时)' ||
              info.orderState != '待支付'
            "
          >
            <div class="fk1">付款时间：</div>
            <div class="fk2">{{ info.payTime }}</div>
          </div>
          <div class="times-b">
            <div class="dd1">订单金额：</div>
            <div class="dd2">￥{{ info.amount }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第五部分 -->
    <div
      class="con-five"
      v-if="info.orderState === '已完成' || info.orderState === '待审核' || info.orderState === '服务中'"
    >
      <div class="five-all">
        <div class="five-one">
          <p class="five-f" style="margin-bottom: 20px">发票信息</p>
          <div class="five-2">
            <p
              class="five-2-1"
              v-if="invoiceData"
              @click="CheckTheInvoiceValue"
            >
              查看发票
            </p>
	          <p
		          class="five-2-2"
		          v-if="
                InvoiceNumber.makeOutState != 0 && invoiceData &&
               info.payState != '审核未通过'
              "
		          @click="recompose"
	          >
		          修改
	          </p>
	          <p
		          class="five-2-2"
		          v-if="!invoiceData"
		          @click="recompose"
	          >
		          新增发票
	          </p>
          </div>
        </div>
        <div class="five-two" v-if="invoiceData">
          <p>
            <span>发票类型：</span><span>{{ invoiceData.invoiceType }}</span>
          </p>
          <p>
            <span>发票抬头：</span><span>{{ invoiceData.invoiceHead }}</span>
          </p>
          <p v-if="invoiceData.invoiceType != '个人发票'">
            <span>单位税号：</span><span>{{ invoiceData.tariffLine }}</span>
          </p>
          <p v-if="invoiceData.invoiceType != '企业发票---增值税专用发票'">
            <span style="margin-right: 20px">邮箱地址：</span
            ><span>{{ invoiceData.mailAddress }}</span>
          </p>
          <p v-if="invoiceData.invoiceType === '个人发票'">
            <span style="margin-right: 34px">手机号：</span
            ><span>{{ invoiceData.mobile }}</span>
          </p>
          <p v-if="invoiceData.invoiceType === '企业发票---增值税专用发票'">
            <span>开户行:</span><span>{{ invoiceData.bankName }}</span>
          </p>
          <p v-if="invoiceData.invoiceType === '企业发票---增值税专用发票'">
            <span>银行账号：</span><span>{{ invoiceData.bankAccount }}</span>
          </p>
          <p v-if="invoiceData.invoiceType === '企业发票---增值税专用发票'">
            <span>企业电话：</span><span>{{ invoiceData.businessMobile }}</span>
          </p>
          <p v-if="invoiceData.invoiceType === '企业发票---增值税专用发票'">
            <span>企业地址：</span
            ><span>{{ invoiceData.businessAddress }}</span>
          </p>
        </div>
        <div v-else class="five-two">
          <p>暂无发票信息</p>
        </div>
      </div>
    </div>
    <!-- 第六部分 -->
    <div
      class="con-six"
      v-if="info.orderState === '服务中' || info.orderState === '已完成'"
    >
      <div class="six-all">
        <p class="six-t">订单跟进人员</p>
        <p class="six-n">
          <span>跟进人员：</span><span>{{ info.followName }}</span>
        </p>
        <p class="six-l">
          <span>联系方式：</span><span>{{ info.followMobile }}</span>
        </p>
      </div>
    </div>
    <div class="seven">
      <ui class="ui-li" v-for="(item, index) in Yes" :key="item.id + index">
        <li class="li-ui">
          <img :src="item.img" alt="" /><span>{{ item.title }}</span>
        </li>
      </ui>
    </div>
  </div>
	<InvoicePopup
			v-if="amend === 3"
			:amendValue="info.orderNumber"
			@noInvoice="handleNoInvoice"
	/>
  <CheckTheInvoice
    v-model="dialogOpen"
    v-if="dialogOpen"
    :checkValue="checkValue"
    @offTitle="offTitle"
  />
</template>

<script setup>
import {defineEmits, defineProps, onMounted, ref} from "vue";
import {addedDetail, InvoiceByOrderNumber} from "@/api/orderCenter/index.js";
import CheckTheInvoice from "./CheckTheInvoice";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";
import emitter from "@/utils/eventBus";
import oosPath from "@/global-variable";
import {expired} from "@/api/order";
import InvoicePopup from "@/views/orderCenter/components/InvoicePopup.vue";

const props = defineProps({
  numValue: {
    type: String,
    required: true,
  },
});

const dialogOpen = ref(false);
const amend = ref(1);
const Yes = ref([
  {
    id: 1,
    img: require("../../../assets/images/order/dg.png"),
    title: "精选正品货源",
  },
  {
    id: 2,
    img: require("../../../assets/images/order/dg.png"),
    title: "24H顺丰发货",
  },
  {
    id: 3,
    img: require("../../../assets/images/order/dg.png"),
    title: "7天无理由退换",
  },
  {
    id: 4,
    img: require("../../../assets/images/order/dg.png"),
    title: "整机3年质保",
  },
]);

// 子组件传值给父组件
const emit = defineEmits(["changeId"]);
const handelClick = () => {
  emit("changeId", 1);
};
// 咨询在线客服
const handleOpen = () => {
  emitter.emit("onlineClient");
};

const orderNumber = ref(props.numValue);
// 详情页面数据

const particularsDataThree = ref({}); //订单信息

// 待支付
const router = useRouter();
const handleUnpaid = async (item) => {
  try {
    let data = await expired(item);

    let TimeNum = data.data;
    if (TimeNum === true) {
      ElMessage.error("订单已超时");
    } else {
      await router.push({
        path: "payorder",
        query: { orderNumber: item, isService: 1 },
      });
    }
  } catch (error) {
    console.log(error);
  }
};


//延保服务
const toServiceSupport=()=>{
    router.push({
      path:'/serviceSupport',
      query:{
        introduce:info.value.introduce
      }
    })
}

let checkValue = ref();
const CheckTheInvoiceValue = () => {
  checkValue.value = invoiceData.value;

  dialogOpen.value = true;
};
const offTitle = () => {
  dialogOpen.value = false;
  getParticulars();
};

const info = ref({
  orderState: "",
});

const orderContent = [
  { title: "待支付", value: 0 },
  { title: "服务中", value: 1 },
  { title: "已完成", value: 2 },
  { title: "已取消(支付超时)", value: 3 },
  { title: "待审核", value: 4 },
];
const serviceArr = [
  { label: "延保服务", value: 0 },
  { label: "保养维修", value: 1 },
  { label: "驻场服务", value: 2 },
  { label: "硬件升级", value: 3 },
];
const payStateArr = [
  { label: "待支付", value: 0 },
  { label: "待审核", value: 1 },
  { label: "审核不通过", value: 2 },
  { label: "审核通过", value: 3 },
  { label: "支付成功", value: 4 },
  { label: "支付失败", value: 5 },
];

const invoiceData = ref({});

const getParticulars = async () => {
  let res = await addedDetail(orderNumber.value);
  let Invoice = await InvoiceByOrderNumber(res.data.orderNumber);
  info.value = res.data;

  info.value.orderState = orderContent.find(
    (v) => v.value === res.data.orderState
  ).title;
  info.value.picture = oosPath.ossPath + "/order" + res.data.picture;
  info.value.type = serviceArr.find((v) => v.value === res.data.type).label;
  info.value.payState = payStateArr.find(
    (v) => v.value === res.data.payState
  ).label;

  invoiceData.value = Invoice.data;

  if (invoiceData.value.invoiceType === 0) {
    invoiceData.value.invoiceType = "个人发票";
  } else if (invoiceData.value.invoiceType === 1) {
    invoiceData.value.invoiceType = "企业发票---普通发票";
  } else if (invoiceData.value.invoiceType === 2) {
    invoiceData.value.invoiceType = "企业发票---增值税专用发票";
  } else {
    invoiceData.value = null;
  }
};

// 修改发票
const recompose = () => {
	amend.value = 3;
};
const handleNoInvoice = (i) => {
	amend.value = i;
	getParticulars();
	getInvoiceByOrderNumber();
};

// 查看发票
const InvoiceNumber = ref({});
const getInvoiceByOrderNumber = async () => {
	try {
		let data = await InvoiceByOrderNumber(info.value.orderNumber);
		if (data.code === 200) {
			InvoiceNumber.value = data.data;
		}
	} catch (error) {
		console.log(error);
	}
};

onMounted(() => {
  getParticulars();
});
</script>

<style lang="less" scoped>
.snsCode {
  /* width: 140px; */
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #aaaaaa;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}

.snsCode1 {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #aaaaaa;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
  border-color: #ce1200;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}

.pack4 {
  display: flex;
  margin-top: 15px;

  .pack4-1 {
    width: 58px;
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .pack4-2 {
    margin-left: 20px;
    /* width: 53px; */
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}

.pack3 {
  display: flex;
  margin-top: 15px;

  .pack3-1 {
    width: 58px;
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .pack3-2 {
    margin-left: 20px;
    /* width: 53px; */
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}

.pack2 {
  display: flex;

  .pack2-1 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    color: #666666;
    cursor: pointer;
  }

  .pack2-2 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border-radius: 5px;
    background: #ce1200;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    cursor: pointer;
  }
}

.pack1 {
  display: flex;
  height: 17px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 26px 0 20px 0;

  .pack1-1 {
    margin-left: 633px;
    text-align: center;
    line-height: 30px;
    width: 120px;
    height: 30px;
    border: 1px solid #aaaaaa;
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
    color: #666666;
  }
}

.pack11 {
  width: 844px;
  height: 1px;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 24px;
}

.package1 {
  width: 60px;
  height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.details-con {
  .con-t {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 35px;

    .con-t-x {
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../../../assets/images/order/return2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 8px;
        position: relative;
        top: 5px;
        left: 0;
        cursor: pointer;

        &:hover {
          background-image: url("../../../assets/images/order/return3.png");
          cursor: pointer;
        }
      }
    }

    .q-l-1 {
      display: flex;
      justify-content: end;
      margin-right: 30px;

      .m-s {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        font-size: 11px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
        .hoverBorder();
      }
    }

    .con-t-m {
      display: flex;
      // justify-content: space-between;
      // margin-top: 26px;
      margin-left: 5px;
      margin-right: 68px;
      margin-bottom: 21px;

      .m-i {
        font-size: 18px;
        font-weight: 400;
        color: #ce1200;
        margin-right: 45px;
        // margin-right: 70px;
        img {
          display: inline-block;
          width: 24px;
          height: 22px;
          // margin-right: 6px;
        }
      }

      .m-x {
        // margin-right: 35px;
        // margin-left: 45px;
        display: flex;
        // width: 490px;
        :nth-child(2) {
          margin-left: 11px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
        }

        :nth-child(3) {
          margin-right: 12px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
          width: 60px;
        }

        :nth-child(4) {
          width: 200px;
          margin-right: 20px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
        }

        :nth-child(5) {
          font-size: 11px;
          font-weight: 400;
          color: #111111;
          cursor: pointer;
        }

        .m-2 {
          width: 90px;
          height: 30px !important;
        }
      }

      .q-l-2 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        font-size: 11px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-bottom: 5px;
        cursor: pointer;
        position: relative;
        left: 592px;
      }

      .q-l {
        .m-s {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-weight: 400;
          color: #666666;
          text-align: center;
          cursor: pointer;
          margin-bottom: 10px;
          .hoverBorder();
        }

        .m-k {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-weight: 400;
          color: #666666;
          text-align: center;
          margin-bottom: 5px;
          // margin-left: 50px;
          cursor: pointer;
          .hoverBorder();
        }
      }
    }
  }

  .cont-two {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 51px;

    .two-t {
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 13px;
      margin-top: 31px;
      margin-bottom: 11px;

      .two-t-row {
        display: flex;

        & > div {
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 400;
          color: #666;
          margin-right: 23px;
        }
      }

      .two-z {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .cot-three {
    border-bottom: 1px solid #e1e1e1;

    .three-z {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;
      margin-bottom: 11px;

      .three-plus {
        display: flex;
        justify-content: space-between;

        .three-t {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }

        .three-n {
          width: 460px;
          margin-left: 16px;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        .three-t-1 {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: center;
          cursor: pointer;

          &:hover {
            background-color: #ce1200;
            border: none;
            color: #ffffff;
          }
        }
      }

      .three-c {
        display: flex;
        margin-top: 28px;
        margin-bottom: 20px;

        .three-i {
          margin-right: 16px;

          img {
            display: inline-block;
            width: 90px;
            height: 90px;
          }
        }

        .three-ZP {
          .three-n {
            width: 460px;
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .ZW-two {
            display: flex;
            justify-content: flex-start;
            width: 204px;

            .sp1 {
              font-size: 13px;
              font-weight: 400;
              color: #666;
              margin-right: 23px;
            }

            .sp2 {
              font-size: 13px;
              font-weight: 400;
              color: #ce1200;
            }
          }

          .n-numS {
            display: flex;
            justify-content: flex-start;
            width: 204px;

            .gm1 {
              font-size: 13px;
              font-weight: 400;
              color: #666;
              margin-right: 25px;
            }

            .gm2 {
              font-size: 13px;
              font-weight: 400;
              color: #ce1200;
            }
          }

          .ZP-one {
            width: 728px;
            display: flex;
            flex-wrap: wrap;
            background-color: #f2f2f2;
            margin-top: 13px;
            padding-top: 21px;
            padding-bottom: 6px;

            li {
              .li-text {
                margin-bottom: 12px;
                margin-right: 20px;

                .span1 {
                  display: inline-block;
                  width: 60px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #999999;
                  margin-left: 32px;
                  margin-right: 18px;
                }

                .span2 {
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                }

                .span3 {
                  width: 180px;
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 34px;
                  position: relative;
                  top: 4px;
                  white-space: nowrap; /* 不换行 */
                  overflow: hidden; /* 超出隐藏 */
                  text-overflow: ellipsis; /* 显示省略号 */
                }
              }
            }
          }

          .z {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            margin: 15px 0;
          }

          .z-p {
            width: 728px;
            background: #f2f2f2;
            padding: 18px 33px;

            li {
              display: flex;
              justify-content: space-between;

              :nth-child(1) {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 12px;
                margin-right: 50px;
              }

              :nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }

      .two-t-row {
        display: flex;

        & > div {
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 400;
          color: #666;
          margin-right: 23px;
        }
      }
    }
  }

  .con-four {
    border-bottom: 1px solid #e1e1e1;

    .four-cent {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;

      .four-t {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }

      .four-d {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-top: 24px;
        margin-bottom: 15px;

        :nth-child(1) {
          margin-right: 24px;
        }
      }

      .four-z {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;

        :nth-child(1) {
          margin-right: 24px;
        }
      }

      .four-ZW {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .ZW-one {
          display: flex;

          .zf1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 24px;
          }

          .zf2 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
          }
        }

        .ZW-two {
          display: flex;
          width: 204px;

          .sp1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 23px;
          }

          .sp2 {
            font-size: 13px;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }

      .four-n {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .n-num {
          display: flex;

          .zfh1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 12px;
          }

          .zfh2 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
          }
        }

        .n-numS {
          display: flex;
          width: 204px;

          .gm1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 25px;
          }

          .gm2 {
            font-size: 13px;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }

      .times-one-1 {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        // margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 24px;
        }
      }

      .times-one {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        // margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 27px;
        }
      }

      .times-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;

        .times-a {
          display: flex;

          .fk1 {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            margin-right: 27px;
          }

          .fk2 {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          }
        }

        .times-b {
          display: flex;
          align-items: baseline;
          width: 204px;

          .dd1 {
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            margin-right: 21px;
          }

          .dd2 {
            font-size: 22px;
            font-weight: bold;
            color: #ce1200;
            // margin-left: 31px;
            width: 118px;
          }
        }
      }
    }
  }

  .con-five {
    border-bottom: 1px solid #e1e1e1;

    .five-all {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;

      .five-one {
        display: flex;
        justify-content: space-between;

        .five-f {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }

        .five-2 {
          .five-2-1 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            font-size: 11px;
            font-weight: 400;
            color: #666666;
            text-align: center;
            cursor: pointer;
            .hoverBorder();
          }

          .five-2-2 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            font-size: 11px;
            font-weight: 400;
            color: #666666;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
            .hoverBorder();
          }
        }
      }

      .five-two {
        font-size: 13px;
        font-weight: 400;
        color: #666666;

        :nth-child(1) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 19px;
          }
        }

        :nth-child(2) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 19px;
          }
        }

        :nth-child(3) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 20px;
          }
        }

        :nth-child(4) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 43px;
          }
        }

        :nth-child(5) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 20px;
          }
        }

        :nth-child(6) {
          margin-bottom: 15px;

          :nth-child(1) {
            margin-right: 21px;
          }
        }

        :nth-child(7) {
          margin-bottom: 20px;

          :nth-child(1) {
            margin-right: 21px;
          }
        }
      }
    }
  }

  .con-six {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 51px;

    .six-all {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;

      .six-t {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 24px;
      }

      .six-n {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 14px;

        :nth-child(1) {
          margin-right: 21px;
        }
      }

      .six-l {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 20px;

        :nth-child(1) {
          margin-right: 21px;
        }
      }
    }
  }

  .seven {
    width: 980px;
    margin: 0 210px;
    margin-top: 32px;
    display: flex;

    .li-ui {
      margin-right: 30px;

      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
        left: 0;
        margin-right: 5px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

//鼠标经过下拉框
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  color: #ce1200;
}

.el-select-dropdown__item {
  color: #999;
}

.el-select .el-input.is-focus .el-input__inner {
  color: #ce1200;
}

::v-deep .el-input__wrapper {
  height: 30px;
}

.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }

  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;

    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }

    .el-dialog__close:hover {
      color: #ce1200;
    }
  }

  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;

    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
