<template>
	<div class="goods-tabs">
		<div class="good-tabs-one">
			<div>
			</div>
			<div>
				<DetailsImages />
				<DetailsContactUs />
			</div>
			<div style="margin-left: 46px;width: 42rem;">
				<div class="goods-text-t">
					<p class="g-name">{{ detailData.productName }}</p>
					<p class="g-desc">
						{{ detailData.described }}
					</p>
				</div>
				<div class="tabs-tab-two">
					<div>
						<div class="coupon" @click="emitter.emit('couponPopupNew')" v-if="couponList.length">
							<div class="coupon-left">
								<template v-for="(item,index) in couponList">
									<div class="coupon-li" v-if="index < 3">
										满 {{item.amountOver}} 减 {{item.amount}}
									</div>
								</template>
							</div>
							<div class="coupon-right">
								<span :key="detailData.productName" v-track="{event:'领优惠券',page:detailData.productName+'的商品详情'}">领优惠券</span>
								<img src="./img/right.png" />
							</div>
						</div>
						<div class="commodity-tab">
							<div class="tags">
								<div class="dd">
									<a v-for="(item,index) in ArrayData" :key="item.id"
										:class="{ active: item.num === currentIndex }" href="javascript:"
										@click="updateReqParams(item.num,index)">{{ item.configType }}</a>
								</div>
							</div>
						</div>

            <div v-if="settingList.length>0">
              <div class="tabs-tab-two-1">配置详情</div>
              <div class="container-top-l-lx">
                <ui>
                  <li class="top-l-lx-cpu" v-for="(item,index) in settingList" :key="index">
                    <div class="img-top-content">
                      <div class="img-top-content-text">
                        <p class="img-top-content-l">
                          {{ item.typeName }}
                        </p>
                        <div class="img-top-content-r">
                          <div class="img-top-text-r"
                               >
																<span class="img-top-content-r-text">{{
                                    item.materialName
                                  }}</span>
                          </div>

                          <div class="input-number-num">
                            <p class="num-span">×{{ item.count }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ui>
              </div>
            </div>


<!--						<div class="tabs-tab-two-1" style="margin-top: 5px;"-->
<!--							v-if="tapPane[currentIndex]?.configParent6ype06.length > 0">技术支持</div>-->
<!--						<div class="tabs-tab-two-2" v-if="tapPane[currentIndex]?.configParent6ype06.length > 0" v-for="(item,index) in tapPane[currentIndex]?.configParent6ype06-->
<!--            ">-->
<!--              <div class="tabs-tab-two-2-1" @click="supportSelect(index)" >{{item.materialName}}</div>-->
<!--            </div>-->
            <div class="tabs-tab-two-1" v-if="giftData.length > 0" style="margin-top: 20px;">赠品</div>
            <div class="tabs-tab-two-3" v-if="giftData.length > 0">
              <div v-for="item in giftData" style="display: flex;margin-bottom: 6px;">
                <div class="tabs-tab-two-3-1">{{item.giftName}}</div>
                <div class="tabs-tab-two-3-2">x{{item.count}}</div>
              </div>
            </div>



            <div class="firmCustomize" >
              <div class="firmCustomize-1" v-if="productCustomizedMaterialPOS.length > 0">
                <div style="display: flex;">
                  <img src="./img/title-1.png" class="firmCustomize-2-1">
                  <div class="firmCustomize-2">宁美企业客制</div>
                </div>
                <div :class="item.changeState == 1 && item.checkState == 1 ? 'firmCustomize-3-plus' : 'firmCustomize-3'" :style="item.type == 0 && item.changeState == 1 ? 'height: 187px;' : ''" v-for="(item,index) in productCustomizedMaterialPOS">
                  <img :src="item.checkState == 1 ? require('./img/ischeck.png') : require('./img/check.png')" class="firmCustomize-4" @click="isShowCheck(item,index)">
                  <div :class="item.changeState == 1 && item.checkState == 1 ? 'firmCustomize-5-plus' : 'firmCustomize-5'">{{item.materialName}}</div>
                  <div class="firmCustomize-6">{{item.standard}}</div>
                  <div class="firmCustomize-6-1" v-if="item.type == 1 && item.changeState == 1">
                    <div class="firmCustomize-6-1-1">
                      <img :src="item.customerLogos[0].img" style="width: 48px;height: 48px;">
                    </div>
                    <div class="firmCustomize-6-1-2">{{item.originalFilename}}</div>
                  </div>
                  <div class="firmCustomize-8" @click="customMade(item.id,index)">{{item.changeState == 0 ? '我要定制' : item.type == 0 ? '修改图片' : '修改选项'}}</div>
                  <div class="firmCustomize-9" v-if="item.changeState == 1 && item.type == 0">
                    <div class="firmCustomize-9-1">
                      <div class="firmCustomize-9-2">
                        <img :src="item.customerLogos[0].img" style="width: 48px;height: 48px;">
                      </div>
                      <div class="firmCustomize-9-3">{{item.originalFilename}}</div>
                    </div>
                    <div style="height: 70px;display: flex;">
                      <div class="firmCustomize-9-4" v-for="(littleImg,index) in item.customizedMaterialSamplePOS">
                        <img :src="littleImg.samplePictures[0].img" style="width: 48px;height: 48px;">
                        <div class="firmCustomize-9-5">{{littleImg.samplePictureName}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="choose">
                <div class="choose-1">已选购</div>
                <div class="choose-2">
                  <div class="choose-3">{{ArrayData[tempindex]?.configType}}</div>
                  <div class="choose-4">￥{{(tapPane[currentIndex]?.configPrice - tempMany).toFixed(2)}}</div>
                </div>
                <div class="choose-2" v-for="(item,index) in showShops">
                  <div class="choose-3">{{item.materialName}}</div>
                  <div class="choose-4">￥{{item.price}}</div>
                </div>
              </div>
            </div>
            <div class="tabs-tab-two-1" style="margin-top: 20px;">数量</div>
            <div class="diy22plus">
              <div class="diy23">
                <el-input-number
                  class="plusmiuse"
                  v-model="num"
                  :min="1"
                  :step="1"
                  step-strictly
                />
              </div>
              <button class="diy24" @click="btnNum(10)">10</button>
              <button class="diy25" @click="btnNum(20)">20</button>
              <button class="diy26" @click="btnNum(30)">30</button>
            </div>
          </div>
        </div>
        <div class="g-price-btn">
          <!-- 配置即时价格 -->
          <div class="g-price">
            <p><span class="configuration">配置即时价格</span></p>
            <p>
              <span>{{getMoney(tapPane[currentIndex]?.configPrice,num)}}</span>
            </p>
          </div>
          <!-- 立即订购 -->
	        <div class="box-btn">
		        <div v-if="!soldOut" class="box-btn-2"  :key="detailData.productName"
		             v-track="{event:'加入购物车',page:detailData.productName+'的商品详情'}"
		             @click="addshopping(tapPane[currentIndex])">加入购物车</div>
		        <el-button v-if="!soldOut" class="box-btn-l"
		                   @click="immediate(tapPane[currentIndex])">立即订购</el-button>
		        <div v-if="soldOut" class="box-btn-2" style="width: 100%;border-color: gray;background-color: gray;color: white;">
			        已售罄
		        </div>
	        </div>
          <!-- 现在订购 -->
          <div class="order-t">
            <span><img src="../../../assets/banner/jiantou.png" alt="" /></span
            ><span>现在订购，产品将于5个工作日内送货上门！</span>
          </div>
          <!-- 正品货源 -->
          <div class="order-list" id="unsite1">
            <ul v-for="(item, index) in order" :key="item.id + index">
              <li>
                <img src="../../../assets/banner/gouxuan.png" alt="" />
                <span>{{ item.text }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="detailSite1"></div>
    <div class="goods-tabs-1" id="detailSite">
      <el-tabs @tab-click="onChecked" v-model="activeName" type="card">
        <el-tab-pane label="商品详情" name="detail1" v-if="commodityImg"></el-tab-pane>
        <el-tab-pane label="性能评测" name="detail2" v-if="application || applicationVideo"></el-tab-pane>
        <el-tab-pane label="规格参数" name="detail3" v-if="normsPicture"></el-tab-pane>
        <el-tab-pane label="售后政策" name="detail4" v-if="policyPicture"></el-tab-pane>
        <el-tab-pane label="客户使用感受" name="detail5" v-if="feelData"></el-tab-pane>
      </el-tabs>
      <div>
        <div id="detail1" v-if="commodityImg" class="scrollClass">
          <DetailsCommodity :commodityImg="commodityImg"></DetailsCommodity>
        </div>
        <div id="detail2" v-if="application || applicationVideo" class="scrollClass">
          <DetailsScene
              :application="application"
              :applicationVideo="applicationVideo"
              :applicationImg="applicationImg"
          ></DetailsScene>
        </div>
        <div id="detail3" v-if="normsPicture" class="scrollClass">
          <div class="shop-container">
            <ui v-for="item in normsPicture" :key="item.index"
            ><li><img :src="item" /></li
            ></ui>
          </div>
        </div>
        <div id="detail4" v-if="policyPicture" class="scrollClass">
          <div class="shop-container">
            <ui v-for="item in policyPicture" :key="item.index"
            ><li><img :src="item.img" /></li
            ></ui>
          </div>
        </div>
        <div id="detail5" v-if="feelData" class="scrollClass">
          <div class="detailCenter">性能评测</div>
          <DetailsFeel @changeClick="changeValue"/>
        </div>
      </div>
    </div>
  </div>
  <Information v-model="showOpen" />
  <HostCustome v-model="showCustom" />
  <div class="app-header-sticky" v-if="scrollTop > swarpSite" :class="{ show: scrollTop > swarpSite + 90}">
    <div v-if="scrollTop > swarpSite"></div>
    <div class="container-btn">
      <div class="container-left">
        <span>{{detailData.productName}}{{ArrayData[tempindex].configType}}</span>
      </div>
      <div class="container-middle">
        <div v-if="commodityImg" class="container-middle-1" @click="topsenceSwtich('detail1')" :class="{ active: checkedActiveName == 'detail1' }">商品详情</div>
        <div v-if="application || applicationVideo" class="container-middle-1" @click="topsenceSwtich('detail2')" :class="{ active: checkedActiveName == 'detail2' }">性能评测</div>
        <div v-if="normsPicture" class="container-middle-1" @click="topsenceSwtich('detail3')" :class="{ active: checkedActiveName == 'detail3' }">规格参数</div>
        <div v-if="policyPicture" class="container-middle-1" @click="topsenceSwtich('detail4')" :class="{ active: checkedActiveName == 'detail4' }">售后政策</div>
        <div v-if="feelData" class="container-middle-1" @click="topsenceSwtich('detail5')" :class="{ active: checkedActiveName == 'detail5' }" style="width: 90px;">客户使用感受</div>
      </div>
      <div class="container-right">
        <p>配置即时价格:</p>
        <p>
          <span style="font-size: 14px;padding-right: 3px;">￥</span>
          <span>{{ tapPane[currentIndex]?.configPrice * num}}</span>
        </p>
        <div class="r-btn">
	        <el-button v-if="!soldOut" @click="immediate(tapPane[currentIndex])">立即订购</el-button>
	        <el-button v-else style="border-color: gray;background-color: gray;color: white;">已售罄</el-button>
        </div>
      </div>
    </div>
  </div>
	<CouponPopup/>
</template>

<script setup>
import Big from 'big.js'
import {onMounted, ref} from "vue";
import useScrollTop from "@/hooks/useScrollTops";
import DetailsImages from "./DetailsImages.vue";
import DetailsContactUs from "./DetailsContactUs.vue";
import {
	addShop,
	checkCustomizedMaterial,
	clickSafe,
	configPicture,
	getCustomizedMaterialById,
	listProductStandard
} from "@/api/details";
import {ElMessage} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import emitter from "@/utils/eventBus";
import Information from "../../../components/directives/Information";
import HostCustome from "@/components/hostCustomer";
import DetailsFeel from "./DetailsFeel.vue";
import DetailsScene from "./DetailsScene.vue";
import DetailsCommodity from "./DetailsCommodity.vue";
import {Decrypt, Encrypt} from "@/utils/aes";
import {couponQueryList} from "@/api/coupon";
import CouponPopup from "@/views/detailsGoodsNew/components/CouponPopup.vue";

const activeName = ref('');
	const application = ref([]); // 应用场景
	const normsPicture = ref([]);
	const policyPicture = ref([]);
	const applicationVideo = ref(null); // 应用场景视频
	const commodityImg = ref([]); // 商品详情
	const feelData = ref([]); // 客户使用感受
	const applicationImg = ref(null);
	const changeValue = (feel) => {
		if (feel == undefined) {
			feelData.value = feel;
		}
	};
	const productCustomizedMaterialPOS = ref([]);
	let flag = ref(false)
	// 点击悬浮标签
	const topsenceSwtich = (index) => {
		flag.value = true
		checkedActiveName.value = index
		//document.getElementById('detailSite1').scrollIntoView()
		document.documentElement.scrollTop = document.getElementById(`${index}`).offsetTop - 200
		activeName.value = index
	};
	const currentIndex = ref(0);
	const scrollTop = useScrollTop();

	const route = useRoute();
	const activeTags = ref("first");
	const soldOut = ref(false); //是否售罄
	const tapPane = ref([]);
	const showShops = ref([]);
	const tempMany = ref(0);
	const detailData = ref({});
	const specificationImg = ref([]); // 规格参数
	const afterSaleImg = ref([]);
	const giftData = ref([]);
	const ArrayData = ref([]);
	const daList = ref({});
	const num = ref(1);
	const btnNum = (val) => {
		num.value = val;
	};

	//处理金额小数
	const getMoney = (num1, num2) => {
		if (!num1) return
		return Big(num1).times(num2)
	}


	const showCustom = ref(false);
	const houseNoData = ref(route.query.houseNo);
	console.log(route.query, "caccccc");
	console.log(houseNoData.value, "cecece");



//产品id
const settingProductId=ref('')
//配置数组
const settingArr=ref([])
const settingPic=(configId)=>{
  return new Promise((resolve,reject)=>{
    configPicture(settingProductId.value,configId).then(res=>{
      //详情图
      commodityImg.value =res.data.detailPictureS
      if (res.data.detailPicturePCS && res.data.detailPicturePCS.length>0){
        commodityImg.value =res.data.detailPicturePCS
      }
      //规格参数
      normsPicture.value = res.data.normsPictureS;
      resolve()
    }).catch(()=>{
      reject()
    })
  })
}

  //获取配置详情
  const settingList=ref([])
  const getSingleSetting=(obj)=>{
     settingList.value=[]
     Object.keys(obj).forEach((v,i)=>{
       let colName='configParent'+(i+1)+'ype0'+(i+1)
       if (Object.keys(obj).includes(colName)){
         obj[colName].forEach(o=>{
           settingList.value.push({
             typeName:o.typeName,
             materialName:o.materialName,
             count:o.count
           })
         })
       }
     })
  }




	const getDetailGoods = async (productId = route.query.id) => {
		try {
			const res = await listProductStandard(productId);
			let data = Decrypt(res.data);
			let secret = JSON.parse(data);


      //读取不同配置下的图片
      settingProductId.value=secret.productInfoPO.id
      settingArr.value=secret.configTypeVOList.map(v=>v.configId)

      await settingPic(secret.configTypeVOList[0].configId)




			tapPane.value = secret.configTypeVOList;
			console.log(secret, "系统数据");


      //读取配置信息
      getSingleSetting(secret.configTypeVOList[0])



			detailData.value = secret.productInfoPO;
			giftData.value = secret.productGiftPOS;
			specificationImg.value = secret.productInfoPO.normsPictures;
			afterSaleImg.value = secret.productInfoPO.policyPictures;
			if (secret.productInfoPO.reviews) {
				applicationVideo.value = secret.productInfoPO.reviews[0]?.img;
			}



			application.value = secret.productInfoPO.behaviourPictures;
			applicationImg.value = secret.productInfoPO.cover;

			policyPicture.value = secret.productInfoPO.policyPictures;

			soldOut.value = secret.productInfoPO.state === 3

			//埋点获取详情页面商品名称
			localStorage.setItem('productName', detailData.value.productName)


			//记录点击数量
			let referer = document.referrer
			console.log(referer, '====referer====')
			if (referer == '') {
				referer = '直接访问'
			} else if (referer.indexOf('?') != -1) {
				referer = referer.substring(0, referer.indexOf('?'))
			}
			let clickData = {
				productId: detailData.value.id,
				productName: detailData.value.productName,
				source: referer,
				client: 'PC'
			}
			console.log(clickData, '====clickdata====')
			let resp = await clickSafe(clickData)

			// 默认选中标签
			if (commodityImg.value) {
				activeName.value = "detail1";
			} else if (application.value) {
				activeName.value = "detail2";
			} else if (normsPicture.value) {
				activeName.value = "detail3";
			} else if (policyPicture.value) {
				activeName.value = "detail4";
			} else {
				activeName.value = "detail5";
			}
			ArrayData.value = tapPane.value.map((item, index) => {
				return Object.assign(item, {
					num: index++
				});
			});
			// 带配置名跳转到详情页
			if (houseNoData.value) {
				daList.value = ArrayData.value.filter(
					(item) => item.configType === houseNoData.value
				);
				currentIndex.value = daList.value[0].num;
        await settingPic(secret.configTypeNewVOList[currentIndex.value].configId)
			}
		} catch (error) {
			console.log(error);
		}
	};
	const swarpSite = ref()
	const loadTopSite = () => {
		const detailSite = document.getElementById('detailSite');
		swarpSite.value = detailSite.offsetTop - 200;
	}
	const checkedActiveName = ref("detail1")
	const alterActiveName = () => {
		if (flag.value) {
			flag.value = false;
			return
		} else {
			let scrollClassName = document.querySelectorAll('.scrollClass')
			for (let i = scrollClassName.length - 1; i >= 0; i--) {
				if (document.documentElement.scrollTop >= scrollClassName[i].offsetTop - 400) {
					checkedActiveName.value = scrollClassName[i].id;
					activeName.value = scrollClassName[i].id;
					break;
				}
			}
		}
	}
	const onChecked = (TabsPaneContext) => {
		document.documentElement.scrollTop = document.getElementById(`${TabsPaneContext.props.name}`).offsetTop - 200
		checkedActiveName.value = TabsPaneContext.props.name
		activeName.value = TabsPaneContext.props.name
	}

	onMounted(() => {
		getDetailGoods();
		loadTopSite();
		unlocation();
		getCustomer();
		getCouponList();
		window.addEventListener("scroll", alterActiveName);
	});
	const unmiddle = ref(0)
	const screenHeight = ref(0)
	const tempsite1 = ref(0)
	const unlocation = () => {
		setTimeout(function() {
			tempsite1.value = document.getElementById('unsite1').offsetTop;
		}, 500)
		screenHeight.value = document.documentElement.clientHeight;
	}
	const couponList = ref([]);
	// 查询优惠券
	const getCouponList = () => {
		couponQueryList().then((res) => {
			if (res.code == 200) {
				couponList.value = res.data
			}
		}).catch((err) => {
			console.log(err)
		})
	};
	const isShowCheck = (val, inx) => {
		const token = localStorage.getItem("token");
		if (token) {
			if (val.changeState == 1) {
				val.checkState = val.checkState == 0 ? 1 : 0
				showShops.value = []
				let data = {
					id: val.id,
					checkState: val.checkState,
					configPrice: tapPane.value[currentIndex.value].configPrice
				}
				checkCustomizedMaterial(data).then((res) => {
					if (res.code == 200) {
						productCustomizedMaterialPOS.value = res.data.productCustomizedMaterialPOList;
						tapPane.value[currentIndex.value].configPrice = res.data.configPrice
						tempMany.value = 0
						productCustomizedMaterialPOS.value.forEach(element => {
							if (element.checkState == 1) {
								showShops.value.push(element)
								tempMany.value += element.price
							}
						});
					}
				})
			} else {
				showCustom.value = true
				emitter.emit("customId", {
					customId: val.id,
					configPrice: tapPane.value[currentIndex.value].configPrice,
					productList: productCustomizedMaterialPOS.value,
					proId: inx
				});
			}
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	}
	const tempindex = ref(0)
	const updateReqParams = (item, inx) => {
    settingPic(settingArr.value[item])
    getSingleSetting( tapPane.value[item])

		activeTags.value = "first";
		currentIndex.value = item;
		tempindex.value = inx;
		supportIndex.value = 0;
		loadTopSite();
	};
	const addshopping = (data) => {
		let primevalData = {
			configId: data.configId,
			materials: [],
			customerMaterials: productCustomizedMaterialPOS.value,
			productAmount: num.value,
			productId: route.query.id,
			price: data.configPrice
		}
		data.configParent1ype01.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		data.configParent2ype02.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		data.configParent3ype03.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		data.configParent4ype04.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		data.configParent5ype05.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		data.configParent6ype06.forEach(item => {
			primevalData.materials.push({
				amount: item.count,
				materialCode: item.materialCode
			})
		})
		let aesData = Encrypt(JSON.stringify(primevalData));
		let datas = {
			encryptData: aesData,
		};
		addShop(datas).then((res) => {
			if (res.code == 200) {
				ElMessage({
					message: "已添加购物车",
					type: "success",
				});
				emitter.emit("addshop", {
					addshop: true
				})
			} else {
				ElMessage({
					message: res.message,
					type: "error",
				});
			}
		})
	}
	const supportIndex = ref(0)
	const supportSelect = (item) => {
		supportIndex.value = item
	}
	const getCustomer = (productId = route.query.id) => {
		getCustomizedMaterialById(productId).then((res) => {
			productCustomizedMaterialPOS.value = res.data.productCustomizedMaterialPOList;
		})
	}
	emitter.on("confimCustomClose", (val) => {
		showCustom.value = false
	})
	emitter.on("customClose", (val) => {
		showShops.value = []
		tempMany.value = 0
		if (val.customClose) {
			productCustomizedMaterialPOS.value = val.productCustomizedMaterialPOS
			productCustomizedMaterialPOS.value.forEach(element => {
				if (element.checkState == 1) {
					showShops.value.push(element)
					tempMany.value += element.price
				}
			});
			if (val.configPrice) {
				tapPane.value[currentIndex.value].configPrice = val.configPrice
			}
			showCustom.value = false
		}
	})
	const customMade = (id, inx) => {
		const token = localStorage.getItem("token");
		if (token) {
			showCustom.value = true
			emitter.emit("customId", {
				customId: id,
				configPrice: tapPane.value[currentIndex.value].configPrice,
				productList: productCustomizedMaterialPOS.value,
				proId: inx
			});
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	}
	const router = useRouter();
	const immediate = (val) => {
		const token = localStorage.getItem("token");
		if (token) {
			const data = {
				orderInfoPO: detailData.value,
				orderProductVO: {
					orderGiftVOS: giftData.value,
					orderMaterialVOS: val,
					number: num.value
				},
				productCustomizedMaterialPOS: productCustomizedMaterialPOS.value
			};
			const temp = JSON.stringify(data);
			router.push({
				path: "/configordersetting",
				query: {
					item: temp,
					productId: route.query.id,
				},
			});
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	};
	const order = ref([{
			id: 1,
			text: "精选正品货源"
		},
		{
			id: 2,
			text: "24H顺丰发货"
		},
		{
			id: 3,
			text: "7天无理由退换"
		},
		{
			id: 4,
			text: "整机3年质保"
		},
	]);
	const showOpen = ref(false);
	emitter.on("openClose", () => {
		showOpen.value = false;
	});
	emitter.on("closeBeside", () => {
		showOpen.value = false;
	});
	const handleOpen = () => {
		showOpen.value = true;
	};
</script>

<style lang="less" scoped>
.container-top-l-lx {
  width: 100%;

  .img-top-content {
    /* width: 405px; */
    display: flex;

    .img-top-content-text {
      display: flex;
      margin-left: 115px;
      height: 45px;

      .img-top-content-l {
        width: 91px;
        line-height: 40px;
        height: 40px;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
      }

      .img-top-content-r {
        width: 405px;
        height: 36px;
        line-height: 38px;
        display: flex;
        justify-content: space-between;
        margin-left: 32px;

        .img-top-text-r {
          .img-top-content-r-text {
            font-size: 11px;
            font-weight: 400;
            color: #555555;
          }
        }

        .num-inputs {
          display: flex;
          margin-top: 3px;

          .btn_minute {
            width: 32px;
            height: 32px;
            background: #f2f2f2;
            border: 1px solid #d9d9d9;
            cursor: pointer;
          }

          .btn_add {
            width: 32px;
            height: 32px;
            background: #f2f2f2;
            border: 1px solid #d9d9d9;
            cursor: pointer;
          }

          .btn-input {
            width: 54px;
            height: 32px;
            text-align: center;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            border-left: 0;
            border-right: 0;
            background-color: #ffffff;
            padding-left: 15px;
          }
        }

        .img-top-content-m {
          width: 405px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #d9d9d9;
          display: flex;

          .img-top-content-m-1 {
            padding-left: 10px;
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            width: 350px;
            color: #555555;
            cursor: pointer;
          }

          .img-top-content-m-1-1 {
            padding-left: 10px;
            width: 350px;
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            cursor: pointer;
          }

          .img-top-content-m-2 {
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
          }

          .img-top-content-m-2-2 {
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
            margin-left: 15px;
          }

          .img-top-content-m-3 {
            width: 15px;
            height: 8px;
            margin: 0 0 3px 10px;
          }
        }

        .input-number-num {
          .num-span {
            font-size: 11px;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }

    .img-top-content-plus {
      /* font-family: PingFang SC, Helvetica Neue, Helvetica,
        Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif; */
      font-size: 13px;
      font-weight: 800;
      font-weight: bold;
      color: #ce1200;
      position: absolute;
      right: 0;
      transform: translate(100%,50%);
      //height: 8px;
      //line-height: 40px;
      margin-left: 10px;
      /* width: 200px; */
    }

    .img-top-content-reduce {
      /* font-family: PingFang SC, Helvetica Neue, Helvetica,
        Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif; */
      font-size: 13px;
      font-weight: 800;
      font-weight: bold;
      position: absolute;
      right: 0;
      transform: translate(100%,50%);
      color: #04ca17;
      //width: 200px;
      //height: 40px;
      //line-height: 40px;
      margin-left: 10px;
      /* width: 200px; */
    }
  }
}
	.choose {
		width: 672px;
		background: #F2F2F2;
		margin-top: 36px;
		padding-bottom: 22px;
		overflow: hidden;

		.choose-1 {
			font-family: Microsoft YaHei;
			font-weight: bold;
			font-size: 17px;
			color: #333333;
			margin: 23px 0 10px 45px;
		}

		.choose-2 {
			margin-left: 45px;
			margin-bottom: 26px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			font-size: 15px;
			color: #666666;
			display: flex;
			position: relative;

			.choose-3 {
				position: absolute;
			}

			.choose-4 {
				position: absolute;
				right: 52px;
			}
		}
	}

	.firmCustomize {
		margin-top: 20px;

		.firmCustomize-1 {
			width: 672px;
			/* height: 506px; */
			padding-bottom: 28px;
			background: #F2F2F2;
			border: 1px solid #E5E5E5;

			.firmCustomize-2 {
				width: 102px;
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 17px;
				color: #666666;
				display: flex;
				margin: 20px 0 26px 6px;
			}

			.firmCustomize-2-1 {
				width: 32px;
				height: 32px;
				margin: 17px 0 0 21px;
			}

			.firmCustomize-3 {
				width: 630px;
				height: 99px;
				background: #FFFFFF;
				border-radius: 5px;
				border: 1px solid #D9D9D9;
				margin-left: 20px;
				margin-bottom: 14px;
				position: relative;
			}

			.firmCustomize-3-plus {
				width: 630px;
				height: 99px;
				background: #FFFFFF;
				border-radius: 5px;
				border: 1px solid #ce1200;
				margin-left: 20px;
				margin-bottom: 14px;
				position: relative;
			}

			.firmCustomize-4 {
				width: 16px;
				height: 16px;
				cursor: pointer;
				position: absolute;
				top: 41px;
				left: 23px;
			}

			.firmCustomize-5 {
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 18px;
				color: #333333;
				position: absolute;
				top: 27px;
				left: 61px;
			}

			.firmCustomize-5-plus {
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 18px;
				color: #ce1200;
				position: absolute;
				top: 27px;
				left: 61px;
			}

			.firmCustomize-6 {
				width: 321px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
				position: absolute;
				top: 59px;
				left: 64px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.firmCustomize-8 {
				width: 100px;
				height: 32px;
				background: #FFFFFF;
				border-radius: 5px;
				border: 1px solid #AAAAAA;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 12px;
				color: #666666;
				text-align: center;
				line-height: 30px;
				position: absolute;
				top: 33px;
				right: 31px;

				&:hover {
					color: #ce1200;
					border: 1px solid #ce1200;
					cursor: pointer;
				}
			}

			.firmCustomize-6-1 {
				width: 48px;
				height: 70px;
				position: absolute;
				top: 15px;
				right: 162px;

				.firmCustomize-6-1-1 {
					width: 48px;
					height: 48px;
					border: 1px solid #E1E1E1;
				}

				.firmCustomize-6-1-2 {
					width: 48px;
					height: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 12px;
					color: #999999;
					margin-top: 6px;
					text-align: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.firmCustomize-9 {
				width: 566px;
				height: 70px;
				display: flex;
				position: absolute;
				top: 92px;
				left: 64px;

				.firmCustomize-9-4 {
					margin-left: 24px;

					.firmCustomize-9-5 {
						width: 48px;
						height: 14px;
						margin-top: 6px;
						text-align: center;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
					}
				}

				.firmCustomize-9-1 {
					width: 108px;
					height: 70px;
					margin-right: 8px;
					text-align: center;

					.firmCustomize-9-2 {
						width: 48px;
						height: 48px;
						border: 1px solid #E1E1E1;
						margin: 0 auto;
					}

					.firmCustomize-9-3 {
						width: 108px;
						/* height: 13px; */
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
						margin-top: 6px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}
		}

		.safeguardServe {
			width: 672px;
			/* height: 336px; */
			background: #F2F2F2;
			border: 1px solid #E5E5E5;
			margin-top: 30px;
			padding-bottom: 7px;

			.safeguardServe-2 {
				width: 102px;
				height: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 17px;
				color: #666666;
				margin: 20px 0 26px 6px;
			}

			.safeguardServe-2-1 {
				width: 32px;
				height: 32px;
				margin: 17px 0 0 21px;
			}

			.safeguardServe-3 {
				width: 630px;
				height: 78px;
				background: #FFFFFF;
				border-radius: 5px;
				margin-left: 21px;
				border: 1px solid #D9D9D9;
				margin-bottom: 14px;
				position: relative;

				.safeguardServe-4 {
					position: absolute;
					width: 16px;
					height: 16px;
					top: 30px;
					left: 23px;
				}

				.safeguardServe-5 {
					width: 405px;
					height: 21px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					font-size: 18px;
					color: #333333;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					position: absolute;
					top: 26px;
					left: 61px;
				}

				.safeguardServe-6 {
					height: 11px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 15px;
					color: #666666;
					position: absolute;
					top: 18px;
					right: 30px;
				}

				.safeguardServe-8 {
					height: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #999999;
					position: absolute;
					top: 38px;
					right: 30px;
				}
			}
		}

		.choose {
			width: 672px;
			/* height: 180px; */
			background: #F2F2F2;
			margin-top: 36px;
			padding-bottom: 22px;
			overflow: hidden;

			.choose-1 {
				font-family: Microsoft YaHei;
				font-weight: bold;
				font-size: 17px;
				color: #333333;
				margin: 23px 0 10px 45px;
			}

			.choose-2 {
				margin-left: 45px;
				margin-bottom: 26px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				font-size: 15px;
				color: #666666;
				display: flex;
				position: relative;

				.choose-3 {
					position: absolute;
				}

				.choose-4 {
					position: absolute;
					right: 52px;
				}
			}
		}
	}

	.goods-tabs {
		background: #fff;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: 0;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		background: #ce1200;
		color: #fff;
	}

	::v-deep .el-tabs--card>.el-tabs__header {
		border-bottom: 0;
	}

	::v-deep .el-tabs__header {
		margin: 0;
	}

	::v-deep .el-tabs__item:hover {
		background: #ce1200;
		color: #fff !important;
	}

	::v-deep .el-tabs__header {
		background: #f2f2f2;
	}

	.shop-container {
		width: 1200px;
		background-color: #eeee;
		margin: 0 0 0 15px;

		img {
			width: 100%;
		}
	}

	.g-name {
		font-size: 22px;
	}

	.g-desc {
		color: #999;
		margin-top: 10px;
	}

	.goods-tabs {
		.good-tabs-one {
			display: flex;

			.untimefix {
				position: fixed;
				top: 105px;
				z-index: 99;
			}

			.goods-text-t {
				margin-bottom: 32px;

				.g-name {
					font-size: 17px;
					font-weight: bold;
					color: #111111;
				}

				.g-desc {
					font-size: 13px;
					font-weight: 400;
					color: #666666;
				}
			}

			.coupon {
				display: flex;
				justify-content: space-between;
				margin-top: 17px;
				height: 30px;
				width: 674px;
				cursor: pointer;

				.coupon-left {
					display: flex;

					.coupon-li {
						margin-right: 10px;
						background-image: url(./img/coupon_background.png);
						background-size: cover;
						//width: 118px;
						width: 130px;
						height: 30px;
						line-height: 30px;
						text-align: center;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 13px;
						color: #ce1200;
					}
				}

				.coupon-right {
					span {
						margin-right: 6px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
						color: #ce1200;
					}

					img {
						width: 8px;
						height: 12px;
					}
				}
			}

			.commodity-tab {
				margin-top: 17px;

				.tags {
					flex: 1;
					display: flex;

					.dt {
						font-weight: bold;
						width: 100px;
						text-align: right;
						line-height: 42px;
					}

					.dd {
						flex: 1;
						display: flex;
						flex-wrap: wrap;

						>a {
							height: 60px;
							margin-bottom: 12px;
							margin-right: 15px;
							border-radius: 4px;
							border: 1px solid #e4e4e4;
							color: #555;
							text-align: center;
							line-height: 60px;
							padding: 0 20px;

							&:hover {
								border-color: #ce1200;
								//background: lighten(#ce1200, 50%);
								color: #ce1200;
							}

							&.active {
								border-color: #ce1200;
								//background: lighten(#ce1200, 50%);
								color: #ce1200;
							}
						}
					}
				}
			}

			.tabs-tab-two {
				.tabs-tab-two-1 {
					height: 18px;
					font-size: 17px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;

					.tabs-e {
						position: relative;
						left: 572px;
						top: -22px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						cursor: pointer;
						font-size: 15px;
						color: #ce1200;
					}

					.tabs-i {
						position: relative;
						width: 12px;
						left: 668px;
						top: -45px;
						cursor: pointer;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
					}
				}

				.tabs-tab-two-2 {
					width: 674px;
					height: 60px;
					margin-top: 17px;

					.tabs-tab-two-2-1 {
						cursor: pointer;
						width: 674px;
						height: 60px;
						background: #FFFFFF;
						border: 1px solid #D9D9D9;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #555555;
						line-height: 60px;
						padding-left: 33px;

						/* &:hover {
            border-color: #ce1200;
            background: lighten(#ce1200, 50%);
            color: #ce1200;
          } */
						&.active {
							border-color: #ce1200;
							background: lighten(#ce1200, 50%);
							color: #ce1200;
						}
					}
				}

				.tabs-tab-two-3 {
					margin-top: 17px;
					width: 674px;
					background: #F7F7F7;
					padding: 22px 34px;

					.tabs-tab-two-3-1 {
						width: 600px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #555555;
					}

					.tabs-tab-two-3-2 {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
					}
				}

				.diy22plus {
					height: 40px;
					background: #fff;
					display: flex;
					margin-top: 17px;

					.diy23 {
						.plusmiuse {
							width: 100px;
							height: 32px;
						}
					}

					.diy24 {
						width: 32px;
						height: 30px;
						border: 1px solid #d9d9d9;
						border-radius: 5px;
						background-color: #fff;
						margin-left: 24px;
					}

					.diy24:hover {
						color: #ce1200;
						border: 1px solid #ce1200;
					}

					.diy25 {
						width: 32px;
						height: 30px;
						border: 1px solid #d9d9d9;
						border-radius: 5px;
						background-color: #fff;
						margin-left: 10px;
					}

					.diy25:hover {
						color: #ce1200;
						border: 1px solid #ce1200;
					}

					.diy26 {
						width: 32px;
						height: 30px;
						border: 1px solid #d9d9d9;
						background-color: #fff;
						border-radius: 5px;
						margin-left: 10px;
					}

					.diy26:hover {
						color: #ce1200;
						border: 1px solid #ce1200;
					}
				}
				::v-deep .el-tabs--border-card>.el-tabs__content {
					//padding: none;
				}
			}

			.g-price-btn {
				.g-price {
					display: flex;
					margin-top: 24px;

					.configuration {
						display: inline-block;
						width: 136px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						margin: auto;
						margin-right: 57px;
						font-size: 14px;
						font-weight: 400;
						color: #555555;
						background-color: #f2f2f2;
					}

					p:nth-child(2) {
						margin-top: 10px;

						span:nth-child(1) {
							&::before {
								content: "¥";
								font-size: 14px;
								margin-right: 5px;
							}

							&:nth-child(1) {
								color: #ce1200;
								margin-right: 10px;
								font-size: 22px;
								font-weight: bold;
							}
						}

						&:nth-child(2) {
							font-size: 11px;
							font-weight: 400;
							color: #555555;

							b {
								color: #ce1200;
							}
						}
					}
				}

				.box-btn {
					width: 100%;
					display: flex;
					margin-top: 32px;

					.box-btn-l {
						width: 340px;
						height: 56px;
						background: #ce1200;
						border-radius: 5px;
						color: #fff;
						font-size: 15px;
						margin-top: 2px;
						border: none;
					}

					.box-btn-2 {
						width: 332px;
						height: 54px;
						border-radius: 5px;
						border: 1px solid #ce1200;
						font-family: Microsoft YaHei;
						font-weight: 400;
						font-size: 15px;
						color: #ce1200;
						cursor: pointer;
						text-align: center;
						line-height: 54px;
						margin-right: 11px;
						margin-top: 3px;
					}

					.box-btn-r {
						width: 340px;
						height: 60px;
						background-repeat: no-repeat;
						// background: #f2f2f2;
						// border: 1px solid #d9d9d9;
						// border-radius: 5px;
						// color: #333333;
						// font-size: 15px;
						background-image: url("../../../assets/images/dtt2.gif");
						cursor: pointer;
					}
				}

				.order-t {
					margin-top: 23px;

					span:nth-child(1) {
						margin-left: 4px;
					}

					span:nth-child(2) {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #555555;
						margin-left: 6px;
					}
				}

				.order-list {
					display: flex;
					margin-top: 22px;

					li {
						margin-right: 30px;

						img {
							width: 16px;
							height: 16px;
							margin-bottom: 3px;
							margin-left: 5px;
						}

						span {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							margin-left: 5px;
						}
					}
				}
			}
		}

		.detailCenter {
			width: 157px;
			height: 46px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			font-size: 26px;
			color: #333333;
			text-align: center;
			margin: 0 auto;
			border-bottom: 4px solid #ce1200;
		}

		.goods-tabs-1 {
			width: 1230px;
			margin-top: 80px;

			.el-tabs__active-bar {
				height: 0;
			}
		}
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	::v-deep .el-tabs__header {
		margin: 0 0 20px;
	}

	::v-deep .el-tabs__content {
		padding: 0;
	}

	::v-deep .el-tabs__item {
		font-size: 17px;
		font-weight: 400;
		// color: #666666;
		// padding: 0 40px;
	}

	::v-deep .el-tabs__item.is-active {
		color: #ce1200;
	}

	::v-deep .el-tabs__item:hover {
		color: #ce1200;
	}

	::v-deep .el-tabs__active-bar {
		background-color: #ce1200;
	}

	.app-header-sticky {
		width: 1920px;
		height: 64px;
		position: fixed;
		left: 50%;
		top: 71px;
		z-index: 99;
		background: #FFFFFF;
		box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.15);
		opacity: 0;
		transform: translateX(-50%);

		&.show {
			//transform: none;
			opacity: 1;
			transition: all 1s ease;
		}

		.container-btn {
			display: flex;
			justify-content: space-around;
			background: #FFFFFF;
			height: 64px;
			line-height: 64px;

			.container-left {
				display: flex;
				margin-left: -46px;

				span {
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111111;
					margin-left: 15px;
					width: 277px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.container-middle {
				display: flex;
				margin-left: -630px;

				.container-middle-1 {
					cursor: pointer;
					width: 60px;
					height: 64px;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					margin-right: 20px;

					&.active {
						color: #ce1200;
						border-bottom: 2px solid #ce1200;
					}

					&:hover {
						color: #ce1200;
						border-bottom: 2px solid #ce1200;
					}
				}
			}

			.container-right {
				display: flex;
				align-items: center;
				margin-left: -670px;

				p:nth-child(1) {
					width: 89px;
					height: 15px;
					line-height: 15px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ce1200;
				}

				p:nth-child(2) {
					padding-left: 11px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ce1200;
				}

				.r-btn {
					padding-left: 23px;
					margin-bottom: 8px;

					::v-deep .el-button {
						width: 100px;
						height: 36px;
						background: #ce1200;
						border-radius: 18px;
						font-size: 15px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						border: #ce1200ff;
						border-color: #ce1200ff;
					}
				}
			}
		}
	}

</style>
