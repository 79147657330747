import request from "@/utils/request";

//查询抽奖详情
export function lotteryQueryOne() {
  return request.get("/customer/lottery/draw/query/one");
}

//点击抽奖
export function lotteryDraw(activityNumber) {
  return request.post(`/customer/lottery/draw?activityNumber=`+activityNumber);
}

//查询抽奖记录
export function lotteryRecords(data) {
  return request.get(`/customer/lottery/draw/query/records`,data);
}
