<template>
  <div class="details-con" v-if="amend === 1">
    <!-- 第一部分 -->
    <div class="con-t">
      <p class="con-t-x"><span @click="handelClick"></span>订单详情</p>
      <div class="q-l-1">
        <p
          class="m-s"
          style="margin-top: -40px"
          v-if="particularsDataThree.orderState === '已发货'"
          @click="confirmReceipt(particularsDataThree.orderNumber)"
        >
          确认收货
        </p>
        <p
          class="m-s"
          v-if="particularsDataThree.orderState === '待支付'"
          @click="handleUnpaid(particularsDataThree.orderNumber)"
        >
          立即支付
        </p>
      </div>
      <div class="con-t-m">
        <div>
          <p class="m-i">
            <img
              src="../../../assets/images/order/dzf.png"
              v-if="particularsDataThree.orderState === '待支付'"
              alt=""
            />
            <img
              src="../../../assets/images/order/dfk.png"
              v-if="particularsDataThree.orderState === '待发货'"
              alt=""
            />
            <img
              src="../../../assets/images/order/dfh.png"
              v-if="particularsDataThree.orderState === '已发货'"
              alt=""
            />
            <img
              src="../../../assets/images/order/wc.png"
              v-if="particularsDataThree.orderState === '已完成'"
              alt=""
            />
            <img
              src="../../../assets/images/order/wc.png"
              v-if="particularsDataThree.orderState === '已退货'"
              alt=""
            />
            <img
              src="../../../assets/images/order/qx.png"
              v-if="particularsDataThree.orderState === '已取消'"
              alt=""
            />
            <img
              src="../../../assets/images/order/dsh.png"
              v-if="particularsDataThree.orderState === '待审核'"
              alt=""
            />
            {{ particularsDataThree.orderState }}
          </p>
        </div>
        <div style="display: flex">
          <div
            class="m-x"
            v-if="
              particularsDataThree.orderState === '已发货' ||
              particularsDataThree.orderState === '已完成'
            "
          >
            <!-- <el-select
              v-model="selectValue"
              @change="handelChangValue($event)"
              class="m-2"
            >
              <el-option
                v-for="item in orderLogisticsPOS"
                :key="item.id"
                :label="item.val + item.num"
                :value="item.logisticsCompanyName + null + item.expressNumber"
              >
              </el-option>
            </el-select>
            <p>快递公司：</p>
            <p>{{ logisticsCompanyName }}</p>
            <p>快递单号：{{ expressNumber }}</p>
            <p style="width: 80px"></p> -->
          </div>
          <div
            class="q-l-2"
            v-if="
              particularsDataThree.orderState === '已发货' ||
              particularsDataThree.orderState === '已完成'
            "
          >
            <p class="m-k" @click="handleOpen">联系客服</p>
          </div>
          <div class="q-l" v-else>
            <p class="m-k" @click="handleOpen">联系客服</p>
          </div>
        </div>
      </div>
    </div>
    <div
      style="margin-left: 20px"
      v-if="
        particularsDataThree.orderState === '已发货' ||
        particularsDataThree.orderState === '已完成'
      "
    >
      <div class="pack1">
        <div>物流信息</div>
        <div class="pack1-1" @click="queryExpress">查看物流动态</div>
      </div>
      <div class="pack2">
        <div
          :class="packAgeId == index ? 'pack2-2' : 'pack2-1'"
          v-for="(item, index) in orderLogisticsPOS"
          :key="index"
          @click="packageclick(item, index)"
        >
          {{ item.val + item.num }}
        </div>
      </div>
      <div class="pack3">
        <div class="pack3-1">快递公司:</div>
        <div class="pack3-2">{{ packAge.logisticsCompanyName }}</div>
      </div>
      <div class="pack4">
        <div class="pack4-1">快递单号:</div>
        <div class="pack4-2">{{ packAge.expressNumber }}</div>
      </div>
      <div class="pack4">
        <div class="pack4-1">主机SN:</div>
        <div class="pack4-2">{{ packAge.masterSnCode }}</div>
      </div>
      <div class="pack11"></div>
    </div>
    <!-- 第二部分 -->
    <div
      v-if="
        particularsDataThree.orderState === '待支付' ||
        particularsDataThree.orderState === '已取消'
      "
    ></div>
    <div
      class="cont-two"
      v-if="
        particularsDataThree.orderState === '待发货' ||
        particularsDataThree.orderState === '已发货' ||
        particularsDataThree.orderState === '已完成' ||
        particularsDataThree.orderState === '待审核'
      "
    >
      <div class="two-t">
        <p class="two-z">收货地址</p>
        <p
          v-if="
            particularsDataThree.orderState != '已发货' &&
            particularsDataThree.orderState != '已完成' &&
            particularsDataThree.payState != '审核未通过'
          "
          class="two-g"
          @click="Modification(particularsDataThree)"
        >
          修改
        </p>
      </div>
      <div class="two-btn">
        <p class="two-x">
          <span>姓&nbsp;&nbsp;&nbsp;名：</span
          ><span>{{ particularsDataOne.receiver }}</span>
        </p>
        <p class="two-s">
          <span>手机号：</span><span>{{ particularsDataOne.mobile }}</span>
        </p>
        <p class="two-d">
          <span>收货地址: </span
          ><span>
            {{
              provinceFilter(addressCity, particularsDataOne.province) +
              cityFilter(addressArea, particularsDataOne.city) +
              districtFilter(addressCount, particularsDataOne.district) +
              streetFilter(addressStreet, particularsDataOne.street) +
              particularsDataOne.detail
                ? provinceFilter(addressCity, particularsDataOne.province) +
                  cityFilter(addressArea, particularsDataOne.city) +
                  districtFilter(addressCount, particularsDataOne.district) +
                  streetFilter(addressStreet, particularsDataOne.street) +
                  particularsDataOne.detail
                : ""
            }}</span
          >
        </p>
      </div>
    </div>
    <!-- 第三部分 -->
    <div v-for="(item, index) in particularsDataTwo">
      <div class="cot-three">
        <div class="three-z">
          <div class="three-plus">
            <div style="display: flex">
              <p class="three-t">商品信息</p>
              <p class="three-n" :title="item.productName">
                {{ item.productName }}
              </p>
            </div>
            <p class="three-t-1" @click="CPMPopUp(item)">物料信息</p>
          </div>
          <div class="three-c">
            <p class="three-i">
              <img :src="item.picture" alt="" />
            </p>
            <div class="three-ZP">
              <!--<p class="three-n" :title="item.productName">
                {{ item.productName }}
              </p>-->
              <div style="display: flex">
                <div class="ZW-two">
                  <div class="sp1">商品单价：</div>
                  <div class="sp2">￥{{ item.price }}</div>
                </div>
                <div class="n-numS">
                  <div class="gm1">购买数量：</div>
                  <div class="gm2">{{ item.count }}</div>
                </div>
              </div>
              <div class="ZP-one">
                <ui
                  v-for="materialItem in item.orderMaterialVOS"
                  :key="materialItem.index"
                >
                  <li>
                    <div class="li-text">
                      <span class="span1">{{ materialItem.materialType }}</span
                      ><span class="span2">x{{ materialItem.count }}</span
                      ><span class="span3" :title="materialItem.materialName">
                        {{ materialItem.materialName }}</span
                      >
                    </div>
                  </li>
                </ui>
              </div>
              <p class="z" v-if="item.orderGiftVOS?.length">赠品</p>
              <!-- {{ particularsDataThree.orderState }} -->
              <div class="z-p" v-if="item.orderGiftVOS?.length">
                <ui v-for="giftItem in item.orderGiftVOS" :key="giftItem.index">
                  <li>
                    <span>{{ giftItem.materialName }}</span
                    ><span>x{{ giftItem.count }}</span>
                  </li>
                </ui>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="con-four">
      <div class="four-cent">
        <p class="four-t">订单支付信息</p>
        <p class="four-d">
          <span>订单编号：</span>
          <span>{{ particularsDataThree.orderNumber }}</span>
        </p>
        <p class="times-one-1">
          <span>创建时间：</span
          ><span>{{ particularsDataThree.createTime }}</span>
        </p>
        <p class="four-z">
          <span>支付状态：</span
          ><span>{{ particularsDataThree.payState }}</span>
        </p>
        <div class="four-ZW">
          <div
            class="ZW-one"
            v-if="
              particularsDataThree.orderState != '已取消' ||
              particularsDataThree.orderState != '待支付'
            "
          >
            <div class="zf1">支付方式：</div>
            <div class="zf2">{{ particularsDataThree.payType }}</div>
          </div>
          <!--<div class="ZW-two">
            <div class="sp1">商品单价：</div>
            <div class="sp2">￥{{ particularsDataThree.price }}</div>
          </div>-->
          <div class="ZW-two">
            <div class="sp1">商品总金额：</div>
            <div class="sp2">￥{{ particularsDataThree.productSAmount }}</div>
          </div>
        </div>
        <div class="four-n">
          <div
            class="n-num"
            v-if="
              particularsDataThree.orderState != '已取消' ||
              particularsDataThree.orderState != '待支付'
            "
          >
            <div class="zfh1">支付交易号：</div>
            <div class="zfh2">{{ particularsDataThree.payNumber }}</div>
          </div>
          <!--<div class="n-numS">
            <div class="gm1">购买数量：</div>
            <div class="gm2">{{ particularsDataThree.count }}</div>
          </div>-->
          <div class="n-numS">
            <div class="gm1">优惠金额：</div>
            <div class="gm2">-￥{{ particularsDataThree.couponAmount }}</div>
          </div>
        </div>
        <div class="times-two">
          <div
            class="times-a"
            v-if="
              particularsDataThree.orderState != '已取消' ||
              particularsDataThree.orderState != '待支付'
            "
          >
            <div class="fk1">付款时间：</div>
            <div class="fk2">{{ particularsDataThree.payTime }}</div>
          </div>
          <div class="times-b">
            <div class="dd1">订单金额：</div>
            <div class="dd2">￥{{ particularsDataThree.amount }}</div>
          </div>
        </div>
        <div
          class="times-one"
          v-if="particularsDataThree.orderState === '已完成'"
        >
          <span>收货时间：</span>
          <span>{{ particularsDataThree.confirmTime }}</span>
        </div>
      </div>
    </div>
    <!-- 第五部分 -->
    <div v-if="particularsDataThree.orderState === '待支付' || '已取消'"></div>
    <div
      class="con-five"
      v-if="
        particularsDataThree.orderState === '待发货' ||
        particularsDataThree.orderState === '已发货' ||
        particularsDataThree.orderState === '已完成' ||
        particularsDataThree.orderState === '待审核'
      "
    >
      <div class="five-all">
        <div class="five-one">
          <p class="five-f">发票信息</p>
          <div class="five-2">
            <p
              class="five-2-1"
              v-if="
                particularsDataFore &&
                particularsDataThree.payState != '审核未通过'
              "
              @click="CheckTheInvoiceValue"
            >
              查看发票
            </p>
            <p
              class="five-2-2"
              v-if="
                InvoiceNumber.makeOutState != 0 &&
                particularsDataFore &&
                particularsDataThree.payState != '审核未通过'
              "
              @click="recompose(particularsDataThree)"
            >
              修改
            </p>
            <p
              class="five-2-2"
              v-if="!particularsDataFore"
              @click="recompose(particularsDataThree)"
            >
              新增发票
            </p>
          </div>
        </div>
        <div class="five-two" v-if="particularsDataFore">
          <p>
            <span>发票类型：</span
            ><span>{{ particularsDataFore.invoiceType }}</span>
          </p>
          <p>
            <span>发票抬头：</span
            ><span>{{ particularsDataFore.invoiceHead }}</span>
          </p>
          <p v-if="particularsDataFore.invoiceType != '个人发票'">
            <span>单位税号：</span
            ><span>{{ particularsDataFore.tariffLine }}</span>
          </p>
          <p
            v-if="
              particularsDataFore.invoiceType != '企业发票---增值税专用发票'
            "
          >
            <span style="margin-right: 20px">邮箱地址：</span
            ><span>{{ particularsDataFore.mailAddress }}</span>
          </p>
          <p v-if="particularsDataFore.invoiceType === '个人发票'">
            <span style="margin-right: 34px">手机号：</span
            ><span>{{ particularsDataFore.mobile }}</span>
          </p>
          <p
            v-if="
              particularsDataFore.invoiceType === '企业发票---增值税专用发票'
            "
          >
            <span>开户行:</span><span>{{ particularsDataFore.bankName }}</span>
          </p>
          <p
            v-if="
              particularsDataFore.invoiceType === '企业发票---增值税专用发票'
            "
          >
            <span>银行账号：</span
            ><span>{{ particularsDataFore.bankAccount }}</span>
          </p>
          <p
            v-if="
              particularsDataFore.invoiceType === '企业发票---增值税专用发票'
            "
          >
            <span>企业电话：</span
            ><span>{{ particularsDataFore.businessMobile }}</span>
          </p>
          <p
            v-if="
              particularsDataFore.invoiceType === '企业发票---增值税专用发票'
            "
          >
            <span>企业地址：</span
            ><span>{{ particularsDataFore.businessAddress }}</span>
          </p>
        </div>
        <div v-else class="five-two">
          <p>暂无发票信息</p>
        </div>
      </div>
    </div>
    <!-- 第六部分 -->
    <div v-if="particularsDataThree.orderState == '待支付' || '已取消'"></div>
    <div
      class="con-six"
      v-if="
        particularsDataThree.orderState === '待发货' ||
        particularsDataThree.orderState === '已发货' ||
        particularsDataThree.orderState === '已完成'
      "
    >
      <div class="six-all">
        <p class="six-t">订单跟进人员</p>
        <p class="six-n">
          <span>跟进人员：</span
          ><span>{{ particularsDataThree.followName }}</span>
        </p>
        <p class="six-l">
          <span>联系方式：</span
          ><span>{{ particularsDataThree.followMobile }}</span>
        </p>
      </div>
    </div>
    <div class="seven">
      <ui class="ui-li" v-for="(item, index) in Yes" :key="item.id + index">
        <li class="li-ui">
          <img :src="item.img" alt="" /><span>{{ item.title }}</span>
        </li>
      </ui>
    </div>
  </div>
  <ShippingAdd
    v-if="amend === 2"
    :amendValue="amendValue"
    @sureNextChangeId="sureNextChange"
  />
  <InvoicePopup
    v-if="amend === 3"
    :amendValue="amendValue"
    @noInvoice="handleNoInvoice"
  />
  <CheckTheInvoice
    v-if="dialogOpen"
    v-model="dialogOpen"
    :checkValue="checkValue"
    @offTitle="offTitle"
  />
  <div class="dialog-list">
    <el-dialog
      style="width: 62%; height: 76%; --el-dialog-margin-top: 10vh"
      v-model="popUpList"
    >
      <div style="display: flex; margin-left: 68px">
        <div
          v-for="(item, index) in snsInfo"
          :class="snsselect == index ? 'snsCode1' : 'snsCode'"
          @click="snsSwitch(index)"
        >
          主机SN：{{ item.masterSnCode }}
        </div>
      </div>
      <div class="border-content">
        <el-table
          :data="orderMaterialVOSMaterial"
          height="32.5rem"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="materialName" label="物料名称" width="380" />
          <el-table-column prop="count" label="数量" align="center" />
          <el-table-column
            prop="materialType"
            label="物料类型"
            align="center"
          />
          <el-table-column
            prop="materialCode"
            label="物料编码"
            align="center"
          />
          <el-table-column prop="materialSnCode" label="SN码" align="center" />
        </el-table>
        <div class="qOk" @click="popUpList = false">确定</div>
      </div>
    </el-dialog>
  </div>
  <el-dialog
    v-model="openfill"
    destroy-on-close="true"
    title="物流进度"
    @close="clean"
    width="40%"
  >
    <div class="pack2">
      <div
        :class="packAgeId1 == index ? 'pack2-2' : 'pack2-1'"
        v-for="(item, index) in orderLogisticsPOS"
        :key="index"
        @click="packageclick1(item, index)"
      >
        {{ item.val + item.num }}
      </div>
    </div>
    <div style="margin-bottom: 30px"></div>
    <el-timeline>
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        :timestamp="activity.acceptTime"
      >
        {{ activity.acceptStation }}
      </el-timeline-item>
    </el-timeline>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="openfill = false">取消</el-button>
        <el-button type="primary" @click="openfill = false"> 确定 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from "vue";
import {
  InvoiceByOrderNumber,
  orderLogistics,
  particularsOrder,
  ProductMaterials,
  SnCode,
  UpdateOrder,
} from "@/api/orderCenter/index.js";
import { Timeout } from "../../../api/orderCenter/index.js";
import ShippingAdd from "./ShippingAdd";
import InvoicePopup from "./InvoicePopup";
import CheckTheInvoice from "./CheckTheInvoice";
import { ElMessage, ElMessageBox } from "element-plus";
import Address from "@/utils/address.json";
import { useRouter } from "vue-router";
import emitter from "@/utils/eventBus";
import { Decrypt } from "@/utils/aes";

const props = defineProps({
  numValue: {
    type: String,
    required: true,
  },
});
const popUpList = ref(false); // 物料信息弹窗
const openfill = ref(false);
const activities = ref([]);
const queryExpress = () => {
  activities.value = [];
  openfill.value = true;
  let data = {
    logisticsCompanyName: orderLogisticsPOS.value[0].logisticsCompanyName,
    orderNumber: orderLogisticsPOS.value[0].orderNumber,
    expressNumber: orderLogisticsPOS.value[0].expressNumber,
  };
  orderLogistics(data).then((res) => {
    activities.value = res.data.traces.reverse();
  });
};
// 物料弹窗
const snsInfo = ref([]);
const snsselect = ref(0);
const snsSwitch = (val) => {
  orderMaterialVOSMaterial.value = [];
  snsselect.value = val;
  findMaterials(snsInfo.value[val].id);
};
const CPMPopUp = (val) => {
  const data = {
    orderNumber: orderNumber.value,
    productId: val.productId,
    serialNum: val.serialNum,
  };
  // 查询商品主机SN
  SnCode(data).then((res) => {
    if (res.code == 200 && res.data.length !== 0) {
      snsselect.value = 0;
      snsInfo.value = res.data;
      // 查询SN对应的物料
      findMaterials(snsInfo.value[0].id);
    }
  });
  popUpList.value = true;
};
// 查询SN对应的物料
const findMaterials = (id) => {
  if (id) {
    ProductMaterials(id).then((res) => {
      if (res.code == 200) {
        orderMaterialVOSMaterial.value = res.data; // 物料
      }
    });
  }
};
const dialogOpen = ref(false);
const amend = ref(1);
const Yes = ref([
  {
    id: 1,
    img: require("../../../assets/images/order/dg.png"),
    title: "精选正品货源",
  },
  {
    id: 2,
    img: require("../../../assets/images/order/dg.png"),
    title: "24H顺丰发货",
  },
  {
    id: 3,
    img: require("../../../assets/images/order/dg.png"),
    title: "7天无理由退换",
  },
  {
    id: 4,
    img: require("../../../assets/images/order/dg.png"),
    title: "整机3年质保",
  },
]);
const selectValue = ref("");

// 子组件传值给父组件
const emit = defineEmits(["changeId"]);
const handelClick = () => {
  emit("changeId", 1);
};
// 咨询在线客服
const handleOpen = () => {
  emitter.emit("onlineClient");
};
const packAge = ref({});
const packAgeId = ref(0);
const packAgeId1 = ref(0);
const packageclick = (val, id) => {
  packAge.value = val;
  packAgeId.value = id;
};
const packageclick1 = (val, id) => {
  activities.value = [];
  packAgeId1.value = id;
  let data = {
    logisticsCompanyName: val.logisticsCompanyName,
    expressNumber: val.expressNumber,
    orderNumber: val.orderNumber,
  };
  orderLogistics(data).then((res) => {
    activities.value = res.data.traces.reverse();
  });
};
const orderNumber = ref(props.numValue);
// 详情页面数据
const particularsDataOne = ref({}); // 收货地址
const particularsDataTwo = ref([]); // 商品信息
const particularsDataThree = ref({}); //订单信息
const particularsDataFore = ref({}); //发票信息
const orderLogisticsPOS = ref([]); // 包裹物流信息
const orderMaterialVOSMaterial = ref([]);
const getParticulars = async () => {
  // console.log(orderNumber.value, "详情页面数据");
  try {
    let res = await particularsOrder(orderNumber.value);
    let data = Decrypt(res.data);
    let secret = JSON.parse(data);
    if (res.code === 200) {
      if (
        secret.orderLogisticsPOS != null &&
        secret.orderLogisticsPOS != undefined
      ) {
        orderLogisticsPOS.value = secret.orderLogisticsPOS;
        console.log(orderLogisticsPOS.value);
        orderLogisticsPOS.value.map((item, index) => {
          return Object.assign(item, { num: ++index, val: "包裹" });
        });
        packAge.value = selectValue.value = orderLogisticsPOS.value[0];
        orderLogisticsPOS.value[0].val + orderLogisticsPOS.value[0].num;
        logisticsCompanyName.value =
          orderLogisticsPOS.value[0].logisticsCompanyName;
        expressNumber.value = orderLogisticsPOS.value[0].expressNumber;
      }
      // console.log(data.data);
      secret.productVOS.forEach((item) => {
        item.amount = item.amount.toFixed(2);
        item.price = item.price.toFixed(2);
      }); // 保留商品价格小数
      particularsDataTwo.value = secret.productVOS;
      // orderMaterialVOSMaterial.value =
      //     secret.orderProductVO.orderMaterialVOS.concat(
      //     secret.orderProductVO.orderGiftVOS
      //   );
      console.log(orderMaterialVOSMaterial.value);
      secret.orderInfoPO.amount = secret.orderInfoPO.amount.toFixed(2); // 保留订单总金额小数
      particularsDataThree.value = secret.orderInfoPO;
      particularsDataFore.value = secret.orderInvoicePO;
      if (particularsDataThree.value.payState === 0) {
        particularsDataThree.value.payState = "待支付";
      } else if (particularsDataThree.value.payState === 1) {
        particularsDataThree.value.payState = "待审核";
      } else if (particularsDataThree.value.payState === 2) {
        particularsDataThree.value.payState = "审核未通过";
      } else if (particularsDataThree.value.payState === 3) {
        particularsDataThree.value.payState = "审核通过";
      } else if (particularsDataThree.value.payState === 4) {
        particularsDataThree.value.payState = "支付成功";
      } else if (particularsDataThree.value.payState === 5) {
        particularsDataThree.value.payState = "支付失败";
      }
      // if (particularsDataThree.value.payType === 1) {
      //   particularsDataThree.value.payState = "支付宝支付";
      // } else if (particularsDataThree.value.payType === 2) {
      //   particularsDataThree.value.payType = "微信支付";
      // } else {
      //   particularsDataThree.value.payType = " 网银转账";
      // }
      if (particularsDataThree.value.orderState === 0) {
        particularsDataThree.value.orderState = "待支付";
      } else if (particularsDataThree.value.orderState === 1) {
        particularsDataThree.value.orderState = "已取消";
      } else if (particularsDataThree.value.orderState === 2) {
        particularsDataThree.value.orderState = "待发货";
      } else if (particularsDataThree.value.orderState === 3) {
        particularsDataThree.value.orderState = "已发货";
      } else if (particularsDataThree.value.orderState === 4) {
        particularsDataThree.value.orderState = "已完成";
      } else if (particularsDataThree.value.orderState === 5) {
        particularsDataThree.value.orderState = "待审核";
      } else if (particularsDataThree.value.orderState === 6) {
        particularsDataThree.value.orderState = "已退货";
      }
      if (secret.orderAddressPO != null && secret.orderAddressPO != undefined) {
        particularsDataOne.value = secret.orderAddressPO;
        // console.log(particularsDataOne.value);
      }
      if (
        particularsDataFore.value != null &&
        particularsDataFore.value != undefined
      ) {
        if (particularsDataFore.value.invoiceType === 0) {
          particularsDataFore.value.invoiceType = "个人发票";
        } else if (particularsDataFore.value.invoiceType === 1) {
          particularsDataFore.value.invoiceType = "企业发票---普通发票";
        } else if (particularsDataFore.value.invoiceType === 2) {
          particularsDataFore.value.invoiceType = "企业发票---增值税专用发票";
        } else {
          particularsDataFore.value = null;
        }
      } else {
        return;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
// 物流
const logisticsCompanyName = ref(null);
const expressNumber = ref(null);
const handelChangValue = (val) => {
  let valNum = val.split("null");
  if (val != null && val != undefined) {
    logisticsCompanyName.value = valNum[0];
    expressNumber.value = valNum[1];
  }
};
// 待支付
const router = useRouter();
const handleUnpaid = async (item) => {
  let orderNumber = item;
  try {
    let data = await Timeout(orderNumber);
    let TimeNum = data.data.data.orderInfoPO.orderState;
    if (TimeNum === 1) {
      ElMessage.error("订单已超时");
      handelClick();
    } else {
      router.push({ path: "payorder", query: { orderNumber: orderNumber } });
    }
  } catch (error) {
    console.log(error);
  }
};
// 确认收货
const confirmReceipt = async (item) => {
  // console.log(item, "确认收货");
  let res = await ElMessageBox.confirm("是否提交当前订单已收货?", "", {
    confirmButtonText: "是",
    cancelButtonText: "否",
    type: "warning",
  }).catch((error) => {
    console.log(error);
  });
  if (res === "confirm") {
    // console.log(item);
    let orderNumber = item;
    await UpdateOrder(orderNumber);
    getParticulars();
    ElMessage({
      type: "success",
      message: "提交成功",
    });
  }
};
// 修改地址
const amendValue = ref();
const Modification = (item) => {
  amendValue.value = item.orderNumber;
  amend.value = 2;
};
const sureNextChange = (i) => {
  amend.value = i;
  getParticulars();
};

// 修改发票
const recompose = (item) => {
  amendValue.value = item.orderNumber;
  amend.value = 3;
};
const handleNoInvoice = (i) => {
  amend.value = i;
  getParticulars();
  getInvoiceByOrderNumber();
};

// 查看发票
const InvoiceNumber = ref({});
const getInvoiceByOrderNumber = async () => {
  try {
    let data = await InvoiceByOrderNumber(orderNumber.value);
    if (data.code === 200) {
      InvoiceNumber.value = data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

let checkValue = ref();
const CheckTheInvoiceValue = () => {
  checkValue.value = InvoiceNumber.value;
  console.log(checkValue.value, "11111");
  dialogOpen.value = true;
};
const offTitle = () => {
  dialogOpen.value = false;
  getParticulars();
  getInvoiceByOrderNumber();
};
const addressCity = ref([]);
const addressArea = ref([]);
const addressCount = ref([]);
const addressStreet = ref([]);
const addressQuery = () => {
  Address.forEach((city) => {
    addressCity.value.push({
      code: city.code,
      name: city.name,
    });
    if (city.children) {
      city.children.forEach((area) => {
        addressArea.value.push({
          code: area.code,
          name: area.name,
        });
        if (area.children) {
          area.children.forEach((count) => {
            addressCount.value.push({
              code: count.code,
              name: count.name,
            });
            if (count.children) {
              count.children.forEach((street) => {
                addressStreet.value.push({
                  code: street.code,
                  name: street.name,
                });
              });
            }
          });
        }
      });
    }
  });
};
const provinceFilter = (addressCity, value) => {
  // console.log(addressCity, value);
  let obj = value;
  const val = addressCity.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const cityFilter = (addressArea, value) => {
  let obj = value;
  const val = addressArea.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const districtFilter = (addressCount, value) => {
  let obj = value;
  const val = addressCount.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const streetFilter = (addressStreet, value) => {
  let obj = value;
  const val = addressStreet.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
onMounted(() => {
  getParticulars();
  getInvoiceByOrderNumber();
  addressQuery();
});
</script>

<style lang="less" scoped>
.snsCode {
  /* width: 140px; */
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #aaaaaa;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}
.snsCode1 {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #aaaaaa;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
  border-color: #ce1200;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}
.pack4 {
  display: flex;
  margin-top: 15px;
  .pack4-1 {
    width: 58px;
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .pack4-2 {
    margin-left: 20px;
    /* width: 53px; */
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
.pack3 {
  display: flex;
  margin-top: 15px;
  .pack3-1 {
    width: 58px;
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .pack3-2 {
    margin-left: 20px;
    /* width: 53px; */
    height: 13px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
.pack2 {
  display: flex;
  .pack2-1 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    color: #666666;
    cursor: pointer;
  }
  .pack2-2 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border-radius: 5px;
    background: #ce1200;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    cursor: pointer;
  }
}
.pack1 {
  display: flex;
  height: 17px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 26px 0 20px 0;
  .pack1-1 {
    margin-left: 633px;
    text-align: center;
    line-height: 30px;
    width: 120px;
    height: 30px;
    border: 1px solid #aaaaaa;
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
    color: #666666;
  }
}
.pack11 {
  width: 844px;
  height: 1px;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 24px;
}
.package1 {
  width: 60px;
  height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.details-con {
  .con-t {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 35px;
    .con-t-x {
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../../../assets/images/order/return2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 8px;
        position: relative;
        top: 5px;
        left: 0;
        cursor: pointer;
        &:hover {
          background-image: url("../../../assets/images/order/return3.png");
          cursor: pointer;
        }
      }
    }
    .q-l-1 {
      display: flex;
      justify-content: end;
      margin-right: 30px;
      .m-s {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        font-size: 11px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
        .hoverBorder();
      }
    }
    .con-t-m {
      display: flex;
      // justify-content: space-between;
      // margin-top: 26px;
      margin-left: 5px;
      margin-right: 68px;
      margin-bottom: 21px;
      .m-i {
        font-size: 18px;
        font-weight: 400;
        color: #ce1200;
        margin-right: 45px;
        // margin-right: 70px;
        img {
          display: inline-block;
          width: 24px;
          height: 22px;
          // margin-right: 6px;
        }
      }
      .m-x {
        // margin-right: 35px;
        // margin-left: 45px;
        display: flex;
        // width: 490px;
        :nth-child(2) {
          margin-left: 11px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
        }
        :nth-child(3) {
          margin-right: 12px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
          width: 60px;
        }
        :nth-child(4) {
          width: 200px;
          margin-right: 20px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
        }
        :nth-child(5) {
          font-size: 11px;
          font-weight: 400;
          color: #111111;
          cursor: pointer;
        }
        .m-2 {
          width: 90px;
          height: 30px !important;
        }
      }
      .q-l-2 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        font-size: 11px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-bottom: 5px;
        cursor: pointer;
        position: relative;
        left: 592px;
      }
      .q-l {
        position: relative;
        top: 0;
        left: 592px;
        .m-s {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-weight: 400;
          color: #666666;
          text-align: center;
          cursor: pointer;
          margin-bottom: 10px;
          .hoverBorder();
        }
        .m-k {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-weight: 400;
          color: #666666;
          text-align: center;
          margin-bottom: 5px;
          // margin-left: 50px;
          cursor: pointer;
          .hoverBorder();
        }
      }
    }
  }
  .cont-two {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 51px;
    .two-t {
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 13px;
      margin-top: 31px;
      margin-bottom: 11px;
      .two-z {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }
      .two-g {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        font-size: 11px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        cursor: pointer;
        .hoverBorder();
      }
    }
    .two-btn {
      margin-left: 20px;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;
      .two-x {
        :nth-child(1) {
          margin-right: 21px;
        }
      }
      .two-s {
        margin: 15px 0;
        :nth-child(1) {
          margin-right: 19px;
        }
      }
      .two-d {
        :nth-child(1) {
          margin-right: 14px;
        }
      }
    }
  }
  .cot-three {
    border-bottom: 1px solid #e1e1e1;
    .three-z {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;
      .three-plus {
        display: flex;
        justify-content: space-between;
        .three-t {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        .three-n {
          width: 460px;
          margin-left: 16px;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .three-t-1 {
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #aaaaaa;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: center;
          cursor: pointer;
          &:hover {
            background-color: #ce1200;
            border: none;
            color: #ffffff;
          }
        }
      }

      .three-c {
        display: flex;
        margin-top: 28px;
        margin-bottom: 20px;
        .three-i {
          margin-right: 16px;
          img {
            display: inline-block;
            width: 90px;
            height: 90px;
          }
        }
        .three-ZP {
          .three-n {
            width: 460px;
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          .ZW-two {
            display: flex;
            justify-content: flex-start;
            width: 204px;
            .sp1 {
              font-size: 13px;
              font-weight: 400;
              color: #666;
              margin-right: 23px;
            }
            .sp2 {
              font-size: 13px;
              font-weight: 400;
              color: #ce1200;
            }
          }
          .n-numS {
            display: flex;
            justify-content: flex-start;
            width: 204px;
            .gm1 {
              font-size: 13px;
              font-weight: 400;
              color: #666;
              margin-right: 25px;
            }
            .gm2 {
              font-size: 13px;
              font-weight: 400;
              color: #ce1200;
            }
          }
          .ZP-one {
            width: 728px;
            display: flex;
            flex-wrap: wrap;
            background-color: #f2f2f2;
            margin-top: 13px;
            padding-top: 21px;
            padding-bottom: 6px;
            li {
              .li-text {
                margin-bottom: 12px;
                margin-right: 20px;
                .span1 {
                  display: inline-block;
                  width: 70px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #999999;
                  margin-left: 22px;
                  margin-right: 18px;
                }
                .span2 {
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                }
                .span3 {
                  width: 180px;
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 34px;
                  position: relative;
                  top: 4px;
                  white-space: nowrap; /* 不换行 */
                  overflow: hidden; /* 超出隐藏 */
                  text-overflow: ellipsis; /* 显示省略号 */
                }
              }
            }
          }
          .z {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            margin: 15px 0;
          }
          .z-p {
            width: 728px;
            background: #f2f2f2;
            padding: 18px 33px;
            li {
              display: flex;
              justify-content: space-between;
              :nth-child(1) {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 12px;
                margin-right: 50px;
              }
              :nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
  .con-four {
    border-bottom: 1px solid #e1e1e1;
    .four-cent {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;
      .four-t {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }
      .four-d {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-top: 24px;
        margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 24px;
        }
      }
      .four-z {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 24px;
        }
      }
      .four-ZW {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .ZW-one {
          display: flex;
          .zf1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 24px;
          }
          .zf2 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
          }
        }
        .ZW-two {
          display: flex;
          width: 204px;
          .sp1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 23px;
          }
          .sp2 {
            font-size: 13px;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }
      .four-n {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .n-num {
          display: flex;
          .zfh1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 12px;
          }
          .zfh2 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
          }
        }
        .n-numS {
          display: flex;
          width: 204px;
          .gm1 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            margin-right: 25px;
          }
          .gm2 {
            font-size: 13px;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }
      .times-one-1 {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        // margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 24px;
        }
      }
      .times-one {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        // margin-bottom: 15px;
        :nth-child(1) {
          margin-right: 27px;
        }
      }
      .times-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;
        .times-a {
          display: flex;
          .fk1 {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            margin-right: 27px;
          }
          .fk2 {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          }
        }
        .times-b {
          display: flex;
          align-items: baseline;
          width: 204px;
          .dd1 {
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            margin-right: 21px;
          }
          .dd2 {
            font-size: 22px;
            font-weight: bold;
            color: #ce1200;
            // margin-left: 31px;
            width: 118px;
          }
        }
      }
    }
  }
  .con-five {
    border-bottom: 1px solid #e1e1e1;
    .five-all {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;
      .five-one {
        display: flex;
        justify-content: space-between;
        .five-f {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        .five-2 {
          .five-2-1 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            font-size: 11px;
            font-weight: 400;
            color: #666666;
            text-align: center;
            cursor: pointer;
            .hoverBorder();
          }
          .five-2-2 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            font-size: 11px;
            font-weight: 400;
            color: #666666;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
            .hoverBorder();
          }
        }
      }
      .five-two {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        :nth-child(1) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 19px;
          }
        }
        :nth-child(2) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 19px;
          }
        }
        :nth-child(3) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 20px;
          }
        }
        :nth-child(4) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 43px;
          }
        }
        :nth-child(5) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 20px;
          }
        }
        :nth-child(6) {
          margin-bottom: 15px;
          :nth-child(1) {
            margin-right: 21px;
          }
        }
        :nth-child(7) {
          margin-bottom: 20px;
          :nth-child(1) {
            margin-right: 21px;
          }
        }
      }
    }
  }
  .con-six {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 51px;
    .six-all {
      margin-left: 20px;
      margin-right: 68px;
      margin-top: 31px;
      .six-t {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 24px;
      }
      .six-n {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 14px;
        :nth-child(1) {
          margin-right: 21px;
        }
      }
      .six-l {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 20px;
        :nth-child(1) {
          margin-right: 21px;
        }
      }
    }
  }
  .seven {
    width: 980px;
    margin: 0 210px;
    margin-top: 32px;
    display: flex;
    .li-ui {
      margin-right: 30px;
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
        left: 0;
        margin-right: 5px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
//鼠标经过下拉框
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  color: #ce1200;
}
.el-select-dropdown__item {
  color: #999;
}
.el-select .el-input.is-focus .el-input__inner {
  color: #ce1200;
}
::v-deep .el-input__wrapper {
  height: 30px;
}
.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;
    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }
    .el-dialog__close:hover {
      color: #ce1200;
    }
  }
  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;
    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
