<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="diy1">
    <div class="diy2">
      <div class="diy3">请准确填写您的收货地址，以便于我们为您快速交付产品</div>
      <div class="diy4">
        <div
          v-if="addressList"
          v-for="(item, index) in addressList"
          :class="isSelect == index ? 'diy8plus' : 'diy8'"
          @click="sizeAddress(item,index)"
        >
          <div class="hiddenImg"></div>
          <div class="diy9">{{ item.receiver }}</div>
          <div class="diy10">{{ item.mobile }}</div>
          <div class="diy11">
            {{
              provinceFilter(addressCity, item.province) +
              cityFilter(addressArea, item.city) +
              districtFilter(addressCount, item.district) +
              streetFilter(addressStreet, item.street) +
              item.detail
            }}
          </div>
          <div class="default">
            <img v-if="item.isDefault == 1" src="../../order/image/defalut.png">
            <img @click="setDefalut(item)" v-else src="../../order/image/isdefalut.png">
          </div>
          <el-button class="diy12" @click="editAddress(item)">编辑</el-button>
        </div>
        <div class="diy5" @click="addAddress">
          <div class="diy6"></div>
          <div class="diy7">新增收货地址</div>
        </div>
      </div>
      <div class="diy13"></div>
      <div class="diy13plus"></div>
      <div class="diy14">
        <div class="diy22line"></div>
        <div class="diy15">
          我们的销售支持团队预计将于5个工作日内为您完成产品安装和部署，以便更好的服务您的企业
        </div>
        <el-button class="diy16" @click="sureNext"
        >确认收货地址</el-button
        >
      </div>
    </div>
  </div>
  <div>
    <el-dialog v-model="open" :show-close="false" @close="clean" width="35%"  style="padding: 0">
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <el-button @click="close" class="open2"> </el-button>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"

      >
        <el-form-item label=" " prop="receiver">
          <el-input
            class="open3"
            v-model="ruleForm.receiver"
            placeholder="姓名"
          />
        </el-form-item>
        <el-form-item label=" " prop="mobile">
          <el-input
            class="open4"
            v-model="ruleForm.mobile"
            placeholder="手机号"
          />
        </el-form-item>
        <el-form-item label=" " prop="address">
          <div class="m-4">
            <el-cascader
              placeholder="选择地址"
              v-model="ruleForm.address"
              :options="options"
              @change="chooseAddress"
              filterable
              clearable
            />
          </div>
        </el-form-item>
        <el-form-item label=" " prop="detail">
          <el-input
            type="textarea"
            :rows="4"

            v-model="ruleForm.detail"
            placeholder="详细地址"
          />
        </el-form-item>
        <el-form-item style="margin-top: 3.75rem">
          <el-button class="open7" @click="closeForm()"> 取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
          >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
  <!-- <AppFooter/> -->
  <HomeBackup />
  <tips-dialog v-if="isShowDialog" @close="isShowDialog=false" ></tips-dialog>
</template>

<script setup>
import AppHeader from "@/components/AppHeader.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
import TipsDialog from './components/tipsDialog.vue'
import {
  listAddress,
  addEditAddress,
  submitAddress,
  defaultAddress,
  vevifyApply,
} from "@/api/order";
import { pointsRedeem } from "@/api/points";

import { ref, reactive, onMounted, onBeforeUnmount, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import Address from "@/utils/address.json";
import { getButtonName } from "@/utils/point";
onBeforeUnmount(() => {});
onBeforeMount(()=>{
  window.onpageshow=()=>{
    let isHandlingClickEvent=false
    document.addEventListener('click', e =>getButtonName(e,isHandlingClickEvent) , true)
    //兼容浏览器前进或者后退
    document.addEventListener('popstate', (e)=>{
      if(!isHandlingClickEvent){
        localStorage.setItem('buttonName', '返回')
      }
    });
  }
})


const isShowDialog=ref(false)



const options = [];
const title = ref();
const ruleFormRef = ref();
const ruleForm = reactive({
  receiver: "",
  mobile: "",
  address: [],
  detail: "",
  province: "",
  city: "",
  district: "",
  street: "",
});
const rules = reactive({
  receiver: [{ required: true, message: "请输入姓名", trigger: "blur" }],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  address: [{ required: true, message: "请选择地址", trigger: "blur" }],
  detail: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
});

const clean = () => {
  ruleForm.mobile = null;
  ruleForm.receiver = null;
  ruleForm.detail = null;
  ruleForm.address = []
  return
  ruleFormRef.value?.resetFields();
};
const router = useRouter();
const addressList = ref([]);
const addressCity = ref([]);
const addressArea = ref([]);
const addressCount = ref([]);
const addressStreet = ref([]);
const addressQuery = () => {
  Address.forEach((city, index) => {
    addressCity.value.push({
      code: city.code,
      name: city.name,
    });
    if (city.children) {
      city.children.forEach((area, index) => {
        addressArea.value.push({
          code: area.code,
          name: area.name,
        });
        if (area.children) {
          area.children.forEach((count, index) => {
            addressCount.value.push({
              code: count.code,
              name: count.name,
            });
            if (count.children) {
              count.children.forEach((street, index) => {
                addressStreet.value.push({
                  code: street.code,
                  name: street.name,
                });
              });
            }
          });
        }
      });
    }
  });
};
const addressDeafault = ref([]);
const isSelect = ref(0)
const sizeAddress = (val,index) => {
  // defaultAddress(val.id).then((res) => {
  //   if (res.code == 200) {
  //   }
  // });
  isSelect.value = index
  addressDeafault.value = val;
  // getlistAddress();
};
const setDefalut = (val) => {
  defaultAddress(val.id).then((res) => {
    if (res.code == 200) {
      getlistAddress();
      isSelect.value = 0
      addressDeafault.value = val;
    }
  });
}
const provinceFilter = (addressCity, value) => {
  let obj = value;
  const val = addressCity.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const cityFilter = (addressArea, value) => {
  let obj = value;
  const val = addressArea.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const districtFilter = (addressCount, value) => {
  let obj = value;
  const val = addressCount.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const streetFilter = (addressStreet, value) => {
  let obj = value;
  const val = addressStreet.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const getlistAddress = () => {
  listAddress().then((res) => {
    addressList.value = res.data;
    // res.data.forEach((el) => {
    //   if (el.isDefault == 1) {
    //   }
    // });
    addressDeafault.value = res.data[0] ? res.data[0] : [];
  });
};
const addressId = ref(null);
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (addressId.value != null) {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        ruleForm.id = addressId.value;
        delete ruleForm.address;
        addressId.value = null;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "修改地址成功",
              type: "success",
            });
            open.value = false;
            getlistAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        delete ruleForm.id
        delete ruleForm.address;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "新增地址成功",
              type: "success",
            });
            open.value = false;
            getlistAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
const closeForm = () => {
  open.value = false;
};

const editAddress = (val) => {
  clean();
  title.value = "修改收货地址";
  ruleForm.mobile = val.mobile;
  ruleForm.receiver = val.receiver;
  ruleForm.detail = val.detail;
  ruleForm.address.push(val.province.toString());
  ruleForm.address.push(val.city.toString());
  ruleForm.address.push(val.district.toString());
  ruleForm.address.push(val.street.toString());
  addressId.value = val.id;
  open.value = true;
};
const addAddress = () => {
  clean();
  title.value = "新增收货地址";
  addressId.value = null;
  open.value = true;
};
const chooseAddress = (val) => {};
const route = useRoute();
const open = ref(false);
const sureNext = () => {
  if (addressDeafault.value.length != 0) {
    if (route.query.orderNumber) {
      let data = {
        detail: addressDeafault.value.detail,
        id: addressDeafault.value.id,
        mobile: addressDeafault.value.mobile,
        receiver: addressDeafault.value.receiver,
      };
      submitInfo(data);
    } else {
      let data = {
        detail: addressDeafault.value.detail,
        id: addressDeafault.value.id,
        mobile: addressDeafault.value.mobile,
        receiver: addressDeafault.value.receiver,
      };
      submitInfo(data);
    }
  } else {
    ElMessage({
      message: "请选择或新增收货地址!",
      type: "error",
    });
  }
};
const submitInfo = (data) => {
  pointsRedeem({goodsCode:route.query.goodsCode,userMobile:data.mobile}).then((res)=>{
    if (res.code===200){
      data.orderNumber=res.data
      return  submitAddress(data)
    }else{
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  }).then(res=>{
    if (res.code === 200) {
      isShowDialog.value=true
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
      setTimeout(() => {
        router.push("/");
      }, 1000);
    }
  }).catch((e)=>{
    if(e.err.response.data.code != 200){
      ElMessage({
        message: '系统错误,请联系管理员',
        type: "error",
      });
      setTimeout(() => {
        router.push("/");
      }, 1000);
    }
  });

};
onMounted(() => {
  getlistAddress();
  Address.forEach((item1, index1) => {
    options.push({
      value: item1.code,
      label: item1.name,
      children: [],
    });
    if (item1.children) {
      item1.children.forEach((item2, index2) => {
        if (item2.children) {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
            children: [],
          });
        } else {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
          });
        }
        if (item2.children) {
          item2.children.forEach((item3, index3) => {
            if (item3.children) {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
                children: [],
              });
            } else {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
              });
            }
            if (item3.children) {
              item3.children.forEach((item4, index4) => {
                options[index1].children[index2].children[index3].children.push(
                  {
                    value: item4.code,
                    label: item4.name,
                  }
                );
              });
            }
          });
        }
      });
    }
  });
  addressQuery();
});
</script>
<style lang="less" scoped>
.default{
  position: absolute;
  bottom: 8px;
  left: 20px;
  cursor: pointer;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  height: 60px;
  position: relative;
  //left: -20px;
  top: -20px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  cursor: pointer;
  height: 40px;
  width: 593px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  background: url("../../order/image/close.png") no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  background: url("../../order/image/closeactive.png") no-repeat;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 15px 8px 0 0;
}
.open3 {
  height: 40px;
}
.open4 {
  height: 40px;
}
.open5 {
  height: 40px;
}
.open6 {
  height: 40px;
}

.open7 {
  border: none;
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 296px;
}
.open8 {
  border: none;
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.diy1 {
  width: 100%;
  background-color: #f2f2f2;
  padding-bottom: 200px;
  /* height: 729px; */
  .diy2 {
    width: 1200px;
    /* height: 677px; */
    background: #ffffff;
    margin: 48px auto 60px auto;
    .diy3 {
      width: 530px;
      height: 19px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 64px 0 0 70px;
    }
    .diy4 {
      width: 1024px;
      margin-top: 50px;
      margin-left: 70px;
      display: flex;
      flex-wrap: wrap;
      .diy8 {
        width: 320px;
        height: 200px;
        border: 1px solid #d9d9d9;
        margin-right: 16px;
        margin-bottom: 16px;
        position: relative;
        .diy9 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 31px;
        }
        .diy10 {
          height: 10px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 25px 0 0 31px;
        }
        .diy11 {
          width: 235px;
          height: 36px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 13px 0 0 31px;
        }
        .diy12 {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 28px 0 0 247px;
        }
      }
      .diy8plus {
        width: 320px;
        height: 200px;
        border: 1px solid #ce1200;
        margin-right: 16px;
        margin-bottom: 16px;
        position: relative;
        .hiddenImg {
          background: url("../../order/image/check.png") no-repeat;
          width: 24px;
          height: 24px;
          float: right;
          margin: -10px;
        }
        .diy9 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 31px;
        }
        .diy10 {
          height: 10px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 25px 0 0 31px;
        }
        .diy11 {
          width: 235px;
          height: 36px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 13px 0 0 31px;
        }
        .diy12 {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 28px 0 0 247px;
        }
      }
      .diy5 {
        width: 320px;
        height: 200px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(
          -45deg,
          #d9d9d9 0,
          #d9d9d9 0.4em,
          #fff 0,
          #fff 0.6em
        );
        .diy6 {
          background-image: url("../../order/image/add.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 73px 0 0 145px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 121px;
        }
      }
      .diy5:hover {
        width: 320px;
        height: 200px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(
          -45deg,
          #aaaaaa 0,
          #aaaaaa 0.4em,
          #fff 0,
          #fff 0.6em
        );
        .diy6 {
          background-image: url("../../order/image/addactive.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 73px 0 0 145px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 121px;
        }
      }
    }
    .diy13 {
      width: 1150px;
      /* height: 1px; */
      background: #e1e1e1;
      margin-top: 300px;
      margin-left: 25px;
    }
    .diy13plus {
      height: 1px;
      background: #fff;
      padding-top: 30px;
      margin-left: 25px;
    }
  }
}
.diy14 {
  height: 69px;
  display: flex;
  position: fixed;
  background: #fff;
  width: 1200px;
  bottom: 0px;
  .diy22line{
    position: fixed;
    width: 1150px;
    height: 1px;
    text-align: center;
    background-color: #E1E1E1;
    margin-left: 25px;
  }
  .diy15 {
    width: 506px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
    margin: auto 0;
    margin-left: 70px;
  }
  .diy16 {
    border: none;
    width: 200px;
    height: 48px;
    background: #ce1200;
    border-radius: 5px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin: auto 0;
    margin-left: 330px;
  }
}
:deep(.el-dialog__body) {
  padding: 0px 10px 10px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
:deep(.el-dialog__header) {
  padding: 20px 0 20px 0 !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}


.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}
.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}
.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}
::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
</style>

