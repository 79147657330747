<template>
  <AppLayout>
    <div class="content-warp">
      <div class="warp-title">
        <div class="warp-title-home">
          <span class="warp-title-home1" @click="$router.push('/')">首页</span>
          > <span style="color: #333333">搜索结果</span>
        </div>
        <div class="commodity-input-1">
          <el-input
            v-model="input"
            placeholder="请输入关键词搜索"
            style="
              padding-left: 16px;
              border-color: #b1b1b1 !important;
              color: #999999;
            "
            size="large"
            clearable
            @change="handelChange"
          >
            <template #append style="position: absolute; left: -160px">
              <div @click="handelChangeData">
                <img class="img-val" src="./images/search.png" />
                搜索
              </div>
            </template>
          </el-input>
        </div>
      </div>
      <div class="warp-title-1">
        <div class="warp-title-2">
          <div class="warp-title-3">产品分类：</div>
          <div
            :class="select1 == index ? 'warp-title-4-2' : 'warp-title-4-1'"
            v-for="(item, index) in titleList.productType"
            @click="titleselect(item, index)"
          >
            {{ item.machineName }}
          </div>
        </div>
        <div class="warp-title-5">
          <div class="warp-title-3">处理器：</div>
          <div
            :class="select2 == index ? 'warp-title-4-2' : 'warp-title-4-1'"
            v-for="(item, index) in titleList.cpu"
            @click="titlecpu(item, index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="warp-title-5">
          <div class="warp-title-3">显卡：</div>
          <div
            :class="select3 == index ? 'warp-title-4-2' : 'warp-title-4-1'"
            v-for="(item, index) in titleList.videoCard"
            @click="videoCard(item, index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="warp-title-5">
          <div class="warp-title-3">价格：</div>
          <div
            :class="select4 == index ? 'warp-title-4-2' : 'warp-title-4-1'"
            v-for="(item, index) in titleList.prices"
            @click="prices(item, index)"
          >
            {{ item.price }}
          </div>
        </div>
      </div>
      <div class="desktop-Carousel">
        <div class="desktop-content">
          <ui v-for="(item, index) in positionList" :key="index">
            <li class="desktop-content-all" @click="shopjump(item)">
              <p class="desktop-content-all-img">
                <img :src="item.picture" alt="" />
              </p>
              <p class="desktop-content-all-t">{{ item.productName }}</p>
              <div class="desktop-content-all-c">
                <div>{{ item.described }}</div>
              </div>
              <div class="desktop-content-all-content">
                <div
                  class="desktop-content-all-content-item"
                  v-for="(val, index) in item.productStandardS"
                  :key="index"
                >
                  <div>{{ val.typeName }}</div>
                  <div>
                    <div v-if="val.materialName" class="name-item">
                      {{ val.materialName }}
                    </div>
                    <div v-else class="tips">具体请看详情</div>
                  </div>
                  <div v-if="val.count">X{{ val.count }}</div>
                </div>
              </div>
              <div class="bottom-btn">
                <div class="desktop-content-all-d">
                  <span>￥</span>
                  <span class="price-item">{{ item.configPrice }}</span>
                </div>
                <div class="desktop-content-all-x">
                  <div class="search-list-button-text">
                    {{ item.configType }}
                  </div>
                </div>
              </div>
            </li>
          </ui>
        </div>
        <div
          v-show="positionList.length == 0"
          style="
            width: 100%;
            height: 500px;
            padding-bottom: 40px;
            background-color: #fff;
            text-align: center;
          "
        >
          <div style="padding-top: 100px">
            <img style="width: 323px" src="./images/default.png" alt="" />
            <p
              style="
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 18px;
                color: #999999;
              "
            >
              抱歉，没有找到您搜索的相关商品，试试修改搜索词吧！
            </p>
          </div>
        </div>
      </div>
      <div class="demo-pagination-block" v-show="positionList.length !== 0">
        <el-pagination
          :current-page="articleData.pageNum"
          :page-size="articleData.size"
          :page-count="articleData.navigateLastPage"
          :small="small"
          background
          layout="prev, pager, next, jumper,total"
          :total="articleData.total"
          next-text="下一页"
          prev-text="上一页"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <el-button size="mini" style="margin-left: 10px; background: #f2f2f2"
          >确定
        </el-button>
      </div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { byOptionslist, byoptions } from "../../api/support/index.js";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const input = ref("");
const titleList = ref({});
const handelChange = () => {};
const getGuarantee = () => {
  byoptions().then((res) => {
    titleList.value = res.data;
    searchPosition();
  });
};
const article = reactive({
  pageNum: 1,
  cpu: null,
  productName: null,
  productType: null,
  videoCard: null,
  price1: null,
  price2: null,
});
const articleData = ref([]);
const positionList = ref([]);
const select1 = ref(0);
const select2 = ref(0);
const select3 = ref(0);
const select4 = ref(0);
const selecttype = ref(0);

const router = useRouter();
const shopjump = (data) => {
  const params = {
    id: data.id,
    houseNo: data.configType,
  };
  if (data.machineType == 3 || data.machineType == 5 || data.machineType == 6) {
    router.push({ name: "detailsGoodsNew", query: params });
  } else {
    router.push({ name: "detailsGoods", query: params });
  }
};
const handelChangeData = () => {
  article.productName = input.value;
  searchPosition(article);
};
const handleCurrentChange = (pageNum) => {
  article.pageNum = pageNum;
  searchPosition(article);
};
const titleselect = (val, inx) => {
  select1.value = inx;
  selecttype.value = val.machineType;
  article.productType = val.machineType;
  searchPosition(article);
};
const titlecpu = (val, inx) => {
  select2.value = inx;
  article.cpu = val;
  searchPosition(article);
};
const videoCard = (val, inx) => {
  select3.value = inx;
  article.videoCard = val;
  searchPosition(article);
};
const prices = (val, inx) => {
  select4.value = inx;
  article.price1 = val.price1;
  article.price2 = val.price2;
  searchPosition(article);
};

const options = [
  { value: 1, typeName: "cpu" },
  { value: 2, typeName: "显卡" },
  { value: 3, typeName: "主板" },
  { value: 4, typeName: "内存" },
  { value: 5, typeName: "硬盘" },
];
const searchPosition = (data) => {
  byOptionslist(data).then((res) => {
    res.data.list.forEach((v) => {
      if (!v.productStandardS) {
        v.productStandardS = options;
      } else if (v.productStandardS.length === 0) {
        v.productStandardS = options;
      } else {
        options.forEach((val) => {
          let obj = v.productStandardS.find((x) => x.type === val.value);
          if (obj) {
            obj.typeName = val.typeName;
          } else {
            v.productStandardS.push(val);
          }
        });
      }
    });

    positionList.value = res.data.list;
    articleData.value = res.data;
  });
};
onMounted(() => {
  getGuarantee();
});
</script>

<style lang="less" scoped>
.content-warp {
  width: 1920px;
  background-color: #f2f2f2;
  margin: 0 auto;

  .warp-title {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background: #f2f2f2;
    padding-top: 108px;
    display: flex;

    .warp-title-home {
      width: 88px;
      height: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }

    .warp-title-home1:hover {
      color: #ce1200;
      cursor: pointer;
    }

    .commodity-input-1 {
      width: 420px;
      border-radius: 5px;
      border: 1px solid #b1b1b1;
      line-height: 40px;
      background: #f2f2f2;
      margin-left: 690px;
      margin-top: -13px;

      .img-val {
        height: 12px;
        width: 12px;
        margin-right: 3px;
        cursor: pointer;
        color: #ffffff;
      }

      /deep/ .el-input__inner::placeholder {
        color: #999;
      }

      /deep/ .el-input-group__append,
      .el-input-group__prepend {
        background-color: #ce1200;
        color: #ffffff;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        border-radius: var(--el-input-border-radius);
        padding: 0 20px;
        white-space: nowrap;
        top: 1px;
        height: 36px;
        cursor: pointer;
        margin-right: 4px;
      }

      /deep/ .el-input__wrapper {
        background: #f2f2f2;
      }

      ::v-deep .el-input__wrapper {
        box-shadow: none;
      }
    }
  }

  .warp-title-1 {
    width: 1200px;
    height: 224px;
    background: #ffffff;
    margin: 26px auto 0 auto;

    .warp-title-2 {
      display: flex;
      padding: 26px 0 0 36px;

      .warp-title-3 {
        width: 70px;
        height: 15px;
        line-height: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-right: 37px;
      }

      .warp-title-4 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        margin-right: 32px;
        padding: 5px 10px;
        background: rgba(255, 105, 0, 0.1);
        border-radius: 5px;
        color: #ce1200;
        cursor: pointer;
      }

      .warp-title-4-1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        margin-right: 32px;
        padding: 5px 10px;
        border-radius: 5px;
        color: #666666;
        cursor: pointer;
      }

      .warp-title-4-2 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        margin-right: 32px;
        padding: 5px 10px;
        border-radius: 5px;
        color: #ce1200;
        cursor: pointer;
        background: rgba(255, 105, 0, 0.1);
      }
    }

    .warp-title-5 {
      display: flex;
      padding: 19px 0 0 36px;

      .warp-title-3 {
        width: 70px;
        height: 15px;
        line-height: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-right: 37px;
      }

      .warp-title-4 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-right: 32px;
        padding: 5px 10px;
        background: rgba(255, 105, 0, 0.1);
        border-radius: 5px;
        color: #ce1200;
        cursor: pointer;
      }

      .warp-title-4-1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        margin-right: 32px;
        padding: 5px 10px;
        border-radius: 5px;
        color: #666666;
        cursor: pointer;
      }

      .warp-title-4-2 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        margin-right: 32px;
        padding: 5px 10px;
        border-radius: 5px;
        color: #ce1200;
        cursor: pointer;
        background: rgba(255, 105, 0, 0.1);
      }
    }
  }

  .desktop-Carousel {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;

    .desktop-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
      margin-top: 26px;

      .desktop-content-all {
        padding: 25px;
        box-sizing: border-box;
        width: 383px;
        height: 630px;
        cursor: pointer;
        background: #ffffff;
        .hoverShadow ();

        .desktop-content-all-img {
          width: 215px;
          height: 228px;
          //margin: 6px 90px 12px 35px;
          margin: 0 auto;
          cursor: pointer;

          img {
            display: inline-block;
            width: 220px;
            height: 220px;
          }
        }


        .desktop-content-all-t {
          margin-top: 20px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .desktop-content-all-c {
          height: 48px;
          &>div:nth-child(1){
            margin-top: 5px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .desktop-content-all-content {
          padding-top: 20px;
          box-sizing: border-box;

          .desktop-content-all-content-item {
            position: relative;
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 12px;
            box-sizing: border-box;

            & > div:nth-child(1) {
              width: 15%;
              color: #666666;
              font-size: 14px;
            }

            & > div:nth-child(2) {
              width: 75%;

              .name-item {
                color: #333333;
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: left;
              }

              .tips {
                position: absolute;
                right: 12px;
                top: 0;
                transform: translateY(50%);

                color: #666666;
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            & > div:nth-child(3) {
              width: 10%;
              color: #999999;
              font-size: 14px;
              text-align: right;
            }
          }
        }

        .desktop-content-all-content > div:nth-child(odd) {
          background: #f2f2f2;
        }

        .desktop-content-all-content > div:nth-child(even) {
          background: #ffffff;
        }

        .bottom-btn {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .desktop-content-all-d {
          font-size: 16px;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .price-item {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .desktop-content-all-x {
          width: 168px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ce1200;
          border-radius: 5px;
          text-align: center;
          font-size: 13px;
          font-weight: 400;

          .search-list-button-text {
            margin: 0 auto;
            width: 160px;
            color: #ce1200;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }

        .desktop-content-all-x:hover {
          background-color: #ce1200;
          cursor: pointer;

          .search-list-button-text {
            color: #fff;
          }
        }
      }
    }
  }

  .demo-pagination-block {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;

    ::v-deep .el-button:focus,
    .el-button:hover {
      color: #ce1200;
      border-color: #ce1200;
      background-color: transparent;
      outline: 0;
    }

    ::v-deep .el-pagination.is-background .el-pager li.is-active {
      background-color: #ce1200;
      color: #fff;
      border: #ce1200;
    }

    ::v-deep .el-pager li:hover {
      color: #ce1200;
      border: 1px solid #ce1200;
    }

    ::v-deep .el-pagination button:hover {
      color: #ce1200;
      border: 1px solid #ce1200;
    }

    ::v-deep .el-pagination.is-background .el-pager li {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background: transparent;
    }

    ::v-deep .el-button:focus,
    .el-button:hover {
      color: #ce1200;
      border-color: #ce1200;
      background-color: transparent;
      outline: 0;
    }

    ::v-deep .el-pagination.is-background .btn-next {
      background: transparent;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
    }

    ::v-deep .el-pagination.is-background .btn-prev {
      background: transparent;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
    }
  }
}
</style>
