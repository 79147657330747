<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="menustatus">
    <div class="menustatusmiddle">
      <div style="display: flex">
        <div class="fontstyle">1</div>
        <div class="fontsite">确认产品配置信息</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">2</div>
        <div class="fontsiteorther">支付订单</div>
        <div class="linestyleorther"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyleorther">3</div>
        <div class="fontsiteorther">填写发票信息</div>
      </div>
    </div>
  </div>
  <div class="containerContent">
    <div class="contentpart">
      <div class="diy">
        <div class="reset">
          <div style="display: flex;align-items: center;height: 32px; margin-bottom: 17px;margin-top: 17px">
            <img class="serviceIcon" src="./img/serviceIcon.png" alt="">
            <div class="reset-1">宁美增值服务</div>
          </div>
          <div class="reset-2">
            <img class="detailImg" :src="info.publicizePicture[0]" alt="" />
            <div class="reset-3">
              <div style="display: flex; justify-content: space-between">
                <div class="productName">{{ info.productName }}</div>
                <div class="productPrice">￥ {{info.price}}</div>
              </div>

              <div style="display: flex">
                <div class="contentDetail">
                  {{ info.described }}
                </div>
                <div class="contentDetailNum">X1</div>
              </div>

              <div class="reset-9">
                <div style="display: flex">
                  <img src="./img/ticked.png" />
                  <div class="reset-9-1">精选正品货源</div>
                </div>
                <div style="display: flex; margin-left: 33px">
                  <img src="./img/ticked.png" />
                  <div class="reset-9-1">24H顺丰发货</div>
                </div>
                <div style="display: flex; margin-left: 33px">
                  <img src="./img/ticked.png" />
                  <div class="reset-9-1">7天无理由退换</div>
                </div>
                <div style="display: flex; margin-left: 33px">
                  <img src="./img/ticked.png" />
                  <div class="reset-9-1">整机3年质保</div>
                </div>
              </div>
            </div>
          </div>

          <!-- 优惠券 -->
          <div class="coupon-container" v-if="couponList.length">
            <div class="title">
              <img
                src="./img/title-2.png"
                alt=""
                style="width: 32px; height: 32px"
              />
              <span>优惠信息</span>
            </div>
            <div class="coupon-list" v-if="couponList.length">
              <div v-for="item in couponList" class="coupon-li">
                <div class="coupon-li-left">
                  <div class="amount"><span>￥</span>{{ item.amount }}</div>
                  <span>满 {{ item.amountOver }} 元可用</span>
                  <span>有效期至 {{ item.validityEnd }}</span>
                </div>
                <div class="coupon-li-right" @click="checkCoupon(item)">
                  <img v-if="item.isCheck" src="./img/isTrue.png" alt="" />
                  <img v-else src="./img/isFalse.png" alt="" />
                </div>
              </div>
            </div>
            <div style="text-align: center" v-else>
              <img src="./img/default1.png" style="width: 320px;height: 200px;">
              <div style="font-family: Microsoft YaHei;font-weight: 400;font-size: 18px;color: #999999;">
                暂无优惠券
              </div>
            </div>
          </div>



          <div class="remark">
            <div>订单备注</div>
            <div>
              <el-input
                type="textarea"
                :rows="10"
                v-model="remark"
                placeholder="请输入订单备注，如涉及收货地址或上门服务类型的增值服务，请详细填写收货地址等信息，方便我们为您提供专业服务"
              ></el-input>
            </div>
          </div>
        </div>


        <div class="diy22plus">
          <div class="diy22line"></div>
          <div class="fixed-container">
            <div style="display: flex; align-items: center">
              <div class="diy22">请选择购买数量</div>
              <div class="diy23">
                <el-input-number
                  class="plusmiuse"
                  @change="changeNumber"
                  v-model="num"
                  :min="1"
                  :step="1"
                  step-strictly
                />
              </div>
              <button class="diy24" @click="btnNum(10)">10</button>
              <button class="diy25" @click="btnNum(20)">20</button>
              <button class="diy26" @click="btnNum(30)">30</button>
            </div>
            <div style="display: flex; align-items: center">
              <div class="autodiy">
                <div style="display: flex">
                  <div class="diy27">订单金额：</div>
                  <div class="diy28">
                    ¥ {{ orderPrice }}
                  </div>
                </div>
                <div class="autodiy-bottom" v-if="discountedPrice">
                  <div>已优惠金额：</div>
                  <div>-￥{{discountedPrice}}</div>
                </div>
              </div>
              <el-button class="diy29" @click="submitOrd">提交订单</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppHeader from "@/components/AppHeader";
import Big from 'big.js'
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import emitter from "@/utils/eventBus";
import {addedAdd, addedDetail} from "@/api/incrementService/index";
import ossPath from "@/global-variable";
import {couponListMy} from "@/api/coupon";
import {ElMessage} from "element-plus";

const route = useRoute();
const router = useRouter();
const info = ref({
  publicizePicture: [],
});
const remark=ref('')

const num = ref(1);
const btnNum = (val) => {
   num.value=val
  changeNumber()
};

const orderPrice=computed(()=>{
  if(!info.value.price){
    return
  }
  return Big(num.value).times(info.value.price).minus(discountedPrice.value)
})

const changeNumber=()=>{
  getCouponList(Big(num.value).times(info.value.price).toString())
}

setTimeout(()=>{
  changeNumber()
},1000)

const couponList = ref([]);
const discountedPrice=ref(0)
const receiveNumber=ref(null)
// 查询可用优惠券
const getCouponList = (orderAmount) => {
  discountedPrice.value = 0;
  receiveNumber.value = null; // 重置优惠券
  couponListMy(Number(orderAmount))
    .then((res) => {
      if (res.code == 200) {
        res.data.forEach((item) => {
          item.isCheck = false;
        })
        couponList.value = res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// 勾选优惠券
const checkCoupon = (couponData) => {
  if(couponData.isCheck){
    discountedPrice.value = 0;
    couponData.isCheck = false;
    receiveNumber.value = null;
    discountedPrice.value = 0;
  }else{
    couponList.value.forEach((item) => {
      if (item.isCheck == true) {
        item.isCheck = false;
      }
    });
    couponData.isCheck = true;
    receiveNumber.value = couponData.receiveId;
    discountedPrice.value = couponData.amount;
  }
};


onMounted(() => {
  const token = localStorage.getItem("token");
  if (!token) {
    emitter.emit("openLogin", {
      openLogin: true,
    });
  }

  addedDetail(route.query.id).then((res) => {
    res.data.publicizePicture = res.data.publicizePicture
      .split(",")
      .map((v) => {
        return ossPath.ossPath + "/goods" + v;
      });
    info.value = res.data;
    info.value.price=res.data.config.find(v=>v.configName===route.query.settingName)?.price
  });
});

//提交订单
const submitOrd=()=>{
  let data={
    configName:route.query.settingName,
    count:num.value,
    orderSource:0,
    productCode:info.value.productCode,
    receiveId:receiveNumber.value,
    remark:remark.value
  }
  addedAdd(data).then(res=>{
    if (res.code===200){
      ElMessage({
        message: "提交订单成功",
        type: "success",
      });
      setTimeout(function () {
        router.push({
          path: "/payorder",
          query: {
            orderNumber: res.data,
            isService:1
          },
        });
      }, 500);
    }
  })
}

</script>
<style lang="less" scoped>
.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }

  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;

    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }

    .el-dialog__close:hover {
      color: #ce1200;
    }
  }

  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;

    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}

.containerContent {
  margin: 0 auto;
  width: 1920px;
  min-height: 723px;
  background: #f2f2f2;

  /* position: relative; */

  .contentpart {
    /* position: absolute;
  left: 360px;
  top: 40px; */
    /* min-height: 633px; */
    margin-top: 40px !important;
    margin: 0 auto;
    width: 1200px;
    /* height: 869px; */
    background: #ffffff;

    .diy {
      min-height: 723px;


      .reset-11 {
        /* height: 242px; */
        width: 998px;
        margin-left: 101px;
        margin-bottom: 33px;

        .reset-1 {
          line-height: 32px;
          margin-left: 6px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }

        .reset-12-plus {
          width: 998px;
          height: 98px;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #ce1200;
          position: relative;
          margin-bottom: 14px;

          .reset-13 {
            position: absolute;
            top: 36px;
            left: 23px;
          }

          .reset-14 {
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            position: absolute;
            top: 24px;
            left: 61px;
          }

          .reset-14-plus {
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #ce1200;
            position: absolute;
            top: 24px;
            left: 61px;
          }

          .reset-15 {
            width: 517px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: 56px;
            left: 61px;
          }

          .reset-15-plus {
            height: 61px;
            width: 48px;
            position: absolute;
            top: 15px;
            right: 328px;

            .reset-15-plus-2 {
              text-align: center;
              width: 48px;
              margin-top: 5px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .reset-16 {
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 710px;
          }

          .reset-16-plus {
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #ce1200;
            position: absolute;
            top: 39px;
            left: 710px;
          }

          .reset-17 {
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 761px;
          }

          .reset-17-plus {
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #ce1200;
            position: absolute;
            top: 39px;
            left: 761px;
          }

          .reset-18 {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #aaaaaa;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 32px;

            &:hover {
              color: #ce1200;
              border: 1px solid #ce1200;
              cursor: pointer;
            }
          }

          .reset-19 {
            height: 40px;
            width: 517px;
            display: flex;
            margin-top: 92px;
            margin-left: 64px;

            .reset-19-4 {
              width: 48px;
              text-align: center;
              margin-right: 24px;

              .reset-19-5 {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                width: 48px;
                margin-top: 6px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            .reset-19-1 {
              width: 108px;
              height: 40px;
              text-align: center;
              margin-right: 44px;

              .reset-19-2 {
                margin: 0 auto;
                width: 48px;
                height: 48px;
                border: 1px solid #e1e1e1;
              }

              .reset-19-3 {
                margin-top: 6px;
                width: 108px;
                height: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }

        .reset-12 {
          width: 998px;
          height: 98px;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          position: relative;
          margin-bottom: 14px;

          .reset-13 {
            position: absolute;
            top: 36px;
            left: 23px;
          }

          .reset-14 {
            height: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            position: absolute;
            top: 24px;
            left: 61px;
          }

          .reset-15 {
            width: 517px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: 56px;
            left: 61px;
          }

          .reset-16 {
            width: 14px;
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 710px;
          }

          .reset-17 {
            height: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 39px;
            left: 761px;
          }

          .reset-18 {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #aaaaaa;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 32px;

            &:hover {
              color: #ce1200;
              border: 1px solid #ce1200;
              cursor: pointer;
            }
          }

          .reset-19 {
            height: 40px;
            width: 517px;
            display: flex;
            margin-top: 92px;
            margin-left: 64px;

            .reset-19-4 {
              width: 48px;
              text-align: center;
              margin-right: 24px;

              .reset-19-5 {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                width: 48px;
                margin-top: 6px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            .reset-19-1 {
              width: 108px;
              height: 40px;
              text-align: center;
              margin-right: 44px;

              .reset-19-2 {
                margin: 0 auto;
                width: 48px;
                height: 48px;
                border: 1px solid #e1e1e1;
              }

              .reset-19-3 {
                margin-top: 6px;
                width: 108px;
                height: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }

      .reset {
        width: 998px;
        overflow: hidden;
        margin: 48px 0 0 101px;


        .serviceIcon{
          width: 20px;
          height: 20px;
        }

        .reset-1 {
          line-height: 32px;
          margin-left: 6px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }

        .reset-2 {
          width: 998px;
          height: 160px;
          display: flex;

          .detailImg {
            width: 160px;
            height: 160px;
          }

          .reset-3 {
            margin-left: 20px;
            flex: 1;

            .productName {
              width: 600px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 17px;
              color: #333333;
            }

            .productPrice {
              font-size: 17px;
              color: #ce1200;
              font-weight: bold;
              font-family: Microsoft YaHei;
            }

            .contentDetail {
              margin-top: 10px;
              width: 400px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              color: #6a6969;
              font-size: 14px;
            }

            .contentDetailNum {
              margin-top: 10px;
              margin-left: 20px;
            }

            .reset-9 {
              margin-top: 20px;
              height: 16px;
              display: flex;

              .reset-9-1 {
                //width: 73px;
                height: 13px;
                margin-left: 5px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
              }
            }
          }

          .reset-10 {
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #ce1200;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 20px;
          }
        }

        .remark {
          margin-top: 20px;
          height: 300px;
          &>div:nth-child(1){
            margin-bottom: 10px;
          }
        }
      }

      .diy1 {
        position: absolute;
        left: 150px;
        top: 97px;
        width: 240px;
        height: 240px;
      }

      .diy2 {
        position: absolute;
        width: 540px;
        // height: 18px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        left: 491px;
        top: 64px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .diy3 {
        position: absolute;
        left: 491px;
        top: 107px;
        width: 89px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
      }

      .diy4 {
        position: absolute;
        left: 592px;
        top: 106px;
        width: 11px;
        height: 14px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #555555;
      }

      .diy5 {
        position: absolute;
        left: 610px;
        top: 100px;
        width: 86px;
        height: 18px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #555555;
      }

      .diy6 {
        position: absolute;
        left: 493px;
        top: 153px;
        width: 85px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }

      .diy7 {
        position: absolute;
        left: 163px;
        top: 363.5px;
        width: 16px;
        height: 16px;
      }

      .diy8 {
        position: absolute;
        left: 184px;
        top: 364px;
        width: 73px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .diy9 {
        position: absolute;
        left: 294px;
        top: 365px;
        width: 16px;
        height: 16px;
      }

      .diy10 {
        position: absolute;
        left: 315px;
        top: 364px;
        width: 75px;
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .diy11 {
        position: absolute;
        left: 163px;
        top: 405px;
        width: 16px;
        height: 16px;
      }

      .diy12 {
        position: absolute;
        left: 184px;
        top: 405px;
        width: 83px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .diy13 {
        position: absolute;
        left: 294px;
        top: 404.5px;
        width: 16px;
        height: 16px;
      }

      .diy14 {
        position: absolute;
        left: 315px;
        top: 404.5px;
        width: 74px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .checkborder {
        border: 1px solid #ce1200 !important;
        position: absolute;
        left: 70px;
        top: 342px;
        width: 220px;
        height: 60px;
      }

      .diy16 {
        padding-left: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .diy17 {
        padding-left: 35px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .diy18 {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .diy20 {
        /* position: absolute;
      left: 491px;
      top: 183px; */
        padding: 183px 0 0 491px;
        width: 540px;

        /* background: red; */

        .diy15 {
          width: 540px;
          background: #f2f2f2;
          margin-top: 26px;
        }

        .diy19 {
          width: 28px;
          height: 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ce1200;
          margin-top: 26px;
          margin-bottom: 14px;
        }
      }

      .diy21 {
        margin-top: 176px;
        margin-left: 18px;
        width: 1160px;
        height: 1px;
        background: #e1e1e1;
      }
    }
  }
}

.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}

.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}

.menustatus {
  width: 100%;
  height: 84px;
  background-color: #fff;
}

.menustatusmiddle {
  position: relative;
  top: 32%;
  display: flex;
  justify-content: center;
}

.fontstyle {
  width: 30px;
  height: 30px;
  background: #ce1200;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.fontstyleorther {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  text-align: center;
  line-height: 30px;
  color: #aaaaaa;
}

.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}

.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}

.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}

::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}

.diy22plus {
  width: 1200px;
  height: 68px;
  //line-height: 68px;
  background: #fff;
  /* border-top: 1px solid #E1E1E1; */
  position: fixed;
  bottom: 0px;

  .diy22line {
    width: 1150px;
    height: 1px;
    text-align: center;
    background-color: #e1e1e1;
    margin-left: 25px;
  }

  .fixed-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.6rem;

    .diy22 {
      margin-left: 71px;
      width: 85px;
      height: 13px;
      line-height: 13px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
    }

    .diy23 {
      margin-left: 22px;

      .plusmiuse {
        width: 100px;
        height: 32px;
      }
    }

    .diy24 {
      margin-left: 27px;
      width: 32px;
      height: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background-color: #fff;
    }

    .diy24:hover {
      color: #ce1200;
      border: 1px solid #ce1200;
    }

    .diy25 {
      margin-left: 7px;
      width: 32px;
      height: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background-color: #fff;
    }

    .diy25:hover {
      color: #ce1200;
      border: 1px solid #ce1200;
    }

    .diy26 {
      margin-left: 7px;
      width: 32px;
      height: 30px;
      border: 1px solid #d9d9d9;
      background-color: #fff;
      border-radius: 5px;
    }

    .diy26:hover {
      color: #ce1200;
      border: 1px solid #ce1200;
    }

    .autodiy {
      height: 100%;
      /* width: 60px; */
      display: flex;
      flex-direction: column;
      justify-content: center;

      //margin-top: 18px;
      .diy27 {
        height: 15px;
        line-height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }

      .diy28 {
        height: 18px;
        line-height: 18px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ce1200;
      }

      .autodiy-bottom {
        display: flex;
        margin-top: 6px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
      }
    }

    .diy29 {
      margin-left: 32px;
      margin-right: 71px;
      width: 200px;
      height: 48px;
      background: #ce1200;
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
  }
}
.coupon-container {
  width: 998px;
  margin-top: 30px;


  .title {
    display: flex;
    justify-content: left;
    align-items: center;
    span {
      margin-left: 6px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
    }
  }
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 21px;
    margin-bottom: 14px;
    .coupon-li {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 14px;
      margin-bottom: 14px;
      width: 315px;
      height: 112px;
      background: url("./img/background.png");
      background-size: cover;
      cursor: default;
      .coupon-li-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .amount {
          display: flex;
          align-items: center;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 22px;
          color: #ce1200;
          span {
            font-weight: 400 !important;
            font-size: 14px !important;
          }
        }
        & > span:nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #ce1200;
        }
        & > span:nth-child(3) {
          margin-top: 4px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
        }
      }
      .coupon-li-right {
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

</style>
