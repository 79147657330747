import request from "@/utils/request";

// 商品详情物料配置页面
export function listProductStandard(productId) {
  // return request.get(`/production/productById/${productId}`);
  return request.get(`/goods/production/productById/${productId}`);
}

export function listProductStandardNew(productId) {
  return request.get(`/goods/production/productByIdNew/${productId}`);
}

//根据id查询商品配置图片
export function configPicture(productId,configId) {
  return request.get(`/goods/config/type/configPicture/${productId}/${configId}`);
}


export function getCustomizedMaterialById(productId) {
  return request.get(`/goods/production/getCustomizedMaterialById/${productId}`);
}

export function customizedMaterialById(productId) {
  return request.get(`/goods/production/customizedMaterialById/${productId}`);
}

export function addShop(data) {
  return request.post(`/order/cart/add`,data);
}

export function newCustomerLogo(data) {
  return request.post(`/order/order/updateCustomerLogo`,data);
}

export function comparelist(data) {
  return request.post(`/goods/production/compare`,data);
}

export function compare(id) {
  return request.get(`/goods/production/query/compare/${id}`);
}

export function compareconfig(id,configId,productId,traceId) {
  return request.get('/goods/production/query/compare?id=' + id + '&configId=' + configId + '&productId=' + productId + '&traceId=' + traceId);
}

export function listCompare(id,traceId) {
  return request.get(`/goods/production/list/compare/${id}/${traceId}`);
}
export function checkconfig(id,configId) {
  return request.get(`/goods/production/query/config/${id}/${configId}`);
}

export function onlyDiffer(data) {
  return request.post("/goods/production/only/differ",data);
}

export function generateTraceid(){
  return request.get('/goods/production/generator/traceId');
}

export function checkCustomizedMaterial(data) {
  return request.get("/goods/production/checkCustomizedMaterial?id=" + data.id + '&checkState=' + data.checkState + '&configPrice=' + data.configPrice);
}

export function viewOrderCustomized(data) {
  return request.get(`/order/order/viewOrderCustomized/${data.orderNumber}/${data.id}`);
}

export function saveCustomizedMaterial(data) {
  return request.post("/goods/production/saveCustomizedMaterial",data);
}

// 客户使用感受
export function experience(productId) {
  return request.get(`/goods/production/listExperience/${productId}`);
  // return request.get(`/production/listExperience/${productId}`);
}

// desktop页面接口
export function desktop(machineType) {
  return request.get(`/goods/production/listByMachineType/${machineType}`);
  // return request.get(`/production/listByMachineType/${machineType}`);
}

//记录点击
export function clickSafe(details) {
  return request.get(`/goods/click/safe`,details);
}
