<template>
  <div id="returnSize" style="position: relative; top: -300px"></div>
  <div class="diy1">
    <div class="diy2">
      <div class="diy3">请准确填写您的发票信息，以便于我们为您开具发票</div>
      <div class="diy4">
        <div
          v-for="(item, index) in InvoiceList"
          @click="changeInvoice(item, index)"
          :key="item.id + index"
          :class="isDefault == index ? 'diy8plus' : 'diy8'"
        >
          <div v-if="item.invoiceType == 0">
            <div class="hiddenImg"></div>
            <div class="diy9">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy11">
              邮箱地址<span class="diy11-1">{{ item.mailAddress }}</span>
            </div>
            <div class="diy20">
              手机号<span class="diy20-1">{{ item.mobile }}</span>
            </div>
            <el-button class="diy12" @click="editInvoice(item)">编辑</el-button>
          </div>
          <div v-if="item.invoiceType == 1">
            <div class="hiddenImg"></div>
            <div class="diy9-1">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy20">
              单位税号<span style="margin-left: 38px" class="diy20-1">{{
                item.tariffLine
              }}</span>
            </div>
            <div class="diy11">
              邮箱地址<span class="diy11-1">{{ item.mailAddress }}</span>
            </div>
            <el-button class="diy12" @click="editInvoice(item)">编辑</el-button>
          </div>
          <div v-if="item.invoiceType == 2">
            <div class="hiddenImg"></div>
            <div class="diy9-2">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy11">
              单位税号<span class="diy11-1">{{ item.tariffLine }}</span>
            </div>
            <div class="diy20">
              开户行<span style="margin-left: 55px" class="diy20-1">{{
                item.bankName
              }}</span>
            </div>
            <div class="diy21">
              银行账号<span class="diy21-1">{{ item.bankAccount }}</span>
            </div>
            <div class="diy22">
              企业电话<span class="diy22-1">{{ item.businessMobile }}</span>
            </div>
            <div class="dz" style="display: flex; margin-top: 15px">
              <div class="dz1">企业地址</div>
              <div class="dz2">
                {{ item.businessAddress }}
              </div>
            </div>
            <el-button class="diy12plus" @click="editInvoice(item)"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="diy5" @click="addInvoice">
          <div class="diy6"></div>
          <div class="diy7">新增发票信息</div>
        </div>
      </div>
      <div class="diy13"></div>
      <div class="diy14">
        <div class="diy15">
          发票将于5个工作日内为您开具，有任何问题可联系我们
        </div>

        <el-button class="diy17" @click="noInvoice">暂不开发票</el-button>
        <el-button class="diy16" @click="confimSumbit">确认</el-button>
      </div>
    </div>
  </div>
  <div>
    <el-dialog
      v-model="open"
      :show-close="false"
      @close="clean"
      width="41.25rem"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <el-button @click="close" class="open2"> </el-button>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label=" " prop="invoiceType">
          <el-select v-model="ruleForm.invoiceType" placeholder="选择发票类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div v-if="ruleForm.invoiceType == 0">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
          <el-form-item label=" " prop="mobile">
            <el-input
              class="open6"
              v-model="ruleForm.mobile"
              placeholder="手机号"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 1">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 2">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankName">
            <el-input
              class="open6"
              v-model="ruleForm.bankName"
              placeholder="开户行"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankAccount">
            <el-input
              class="open6"
              v-model="ruleForm.bankAccount"
              placeholder="银行账号"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessMobile">
            <el-input
              class="open6"
              v-model="ruleForm.businessMobile"
              placeholder="企业电话"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessAddress">
            <el-input
              class="open6"
              v-model="ruleForm.businessAddress"
              placeholder="企业地址"
            />
          </el-form-item>
        </div>
        <el-form-item style="margin-top: 60px">
          <el-button class="open7" @click="closeForm()"> 取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import {listInvoice, saveInvoice} from "@/api/order";
import {ModifyNewInvoice} from "@/api/orderCenter/index";
import {defineEmits, defineProps, onMounted, reactive, ref} from "vue";
import {ElMessage} from "element-plus";

const props = defineProps({
  amendValue: String,
});
const open = ref(false);
const ruleFormRef = ref();
const clean = () => {
  ruleForm.invoiceHead = null;
  ruleForm.mailAddress = null;
  ruleForm.mobile = null;
  ruleForm.tariffLine = null;
  ruleForm.bankName = null;
  ruleForm.bankAccount = null;
  ruleForm.businessMobile = null;
  ruleForm.businessAddress = null;
  return;
  ruleFormRef.value?.resetFields();
};
const ruleForm = reactive({
  invoiceType: "0",
  invoiceHead: "",
  mailAddress: "",
  mobile: "",
  tariffLine: "",
  bankName: "",
  bankAccount: "",
  businessMobile: "",
  businessAddress: "",
});
const options = [
  {
    value: "0",
    label: "个人发票",
  },
  {
    value: "1",
    label: "企业发票---普通发票",
  },
  {
    value: "2",
    label: "企业发票---增值税专用发票",
  },
];
// const newInvoice = ref({});
const InvoiceDeafault = ref([]);
const isDefault = ref();
const changeInvoice = (val, index) => {
  // newInvoice.value = val;
  isDefault.value = index;
  InvoiceDeafault.value = val;
};
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (InvoiceId.value == null) {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
          };
          saveInvoiceFn(data, 0);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
          };
          saveInvoiceFn(data, 0);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
          };
          saveInvoiceFn(data, 0);
        }
      } else {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        }
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
const title = ref();
const InvoiceList = ref([]);
const InvoiceId = ref(null);
const listInvoiceFn = () => {
  InvoiceList.value = [];
  listInvoice().then((res) => {
    if (res.code == 200) {
      InvoiceList.value = res.data;
    }
  });
};
const saveInvoiceFn = (data, val) => {
  if (val == 0) {
    delete data.id;
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "新增发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
      }
    });
  } else {
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "修改发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
        isDefault.value = 78;
      }
    });
  }
};
const closeForm = () => {
  open.value = false;
};
const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};

const rules = reactive({
  invoiceType: [{ required: true, message: "请选择发票类型", trigger: "blur" }],
  invoiceHead: [
    { required: true, message: "请输入发票抬头", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  mailAddress: [{ required: true, message: "请输入邮箱地址", trigger: "blur" }],
  tariffLine: [
    { required: true, message: "请输入单位税号", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  bankName: [{ required: true, message: "请输入开户行", trigger: "blur" }],
  bankAccount: [
    { required: true, message: "请输入银行账号", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  businessMobile: [
    {
      required: true,
      message: "请输入企业电话",
      trigger: "blur",
    },
    { max: 30, message: "长度不能超过30位" },
  ],
  businessAddress: [
    { required: true, message: "请输入企业地址", trigger: "blur" },
  ],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
});
// 暂不开票
let emit = defineEmits();
const noInvoice = () => {
  emit("noInvoice", 1);
};
// 确定
const confimSumbit = async () => {
  console.log(InvoiceDeafault.value);
  if (InvoiceDeafault.value.length != 0 && isDefault.value != 78) {
    try {
      if (InvoiceDeafault.value.invoiceType == 0) {
        let data = {
          invoiceType: InvoiceDeafault.value.invoiceType,
          invoiceHead: InvoiceDeafault.value.invoiceHead,
          mailAddress: InvoiceDeafault.value.mailAddress,
          mobile: InvoiceDeafault.value.mobile,
          orderNumber: props.amendValue,
          invoiceId: InvoiceDeafault.value.id,
        };
        await ModifyNewInvoice(data);
      } else if (InvoiceDeafault.value.invoiceType == 1) {
        let data = {
          invoiceType: InvoiceDeafault.value.invoiceType,
          invoiceHead: InvoiceDeafault.value.invoiceHead,
          mailAddress: InvoiceDeafault.value.mailAddress,
          tariffLine: InvoiceDeafault.value.tariffLine,
          orderNumber: props.amendValue,
          invoiceId: InvoiceDeafault.value.id,
        };
        await ModifyNewInvoice(data);
      } else {
        let data = {
          invoiceType: InvoiceDeafault.value.invoiceType,
          invoiceHead: InvoiceDeafault.value.invoiceHead,
          tariffLine: InvoiceDeafault.value.tariffLine,
          bankName: InvoiceDeafault.value.bankName,
          bankAccount: InvoiceDeafault.value.bankAccount,
          businessAddress: InvoiceDeafault.value.businessAddress,
          businessMobile: InvoiceDeafault.value.businessMobile,
          orderNumber: props.amendValue,
          invoiceId: InvoiceDeafault.value.id,
        };
        await ModifyNewInvoice(data);
      }
      emit("noInvoice", 1);
    } catch (error) {
      console.log(error);
    }
  } else {
    ElMessage({
      message: "请选择发票信息",
      type: "warning",
    });
  }
};

onMounted(() => {
  listInvoiceFn();
  document.getElementById("returnSize").scrollIntoView();
});
const editInvoice = (val) => {
  clean();
  title.value = "修改发票信息";
  console.log(val, 888);
  if (val.invoiceType == 0) {
    ruleForm.invoiceType = "0";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.mailAddress = val.mailAddress;
    ruleForm.mobile = val.mobile;
    InvoiceId.value = val.id;
    open.value = true;
  } else if (val.invoiceType == 1) {
    ruleForm.invoiceType = "1";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.mailAddress = val.mailAddress;
    ruleForm.tariffLine = val.tariffLine;
    InvoiceId.value = val.id;
    open.value = true;
  } else {
    ruleForm.invoiceType = "2";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.tariffLine = val.tariffLine;
    ruleForm.bankName = val.bankName;
    ruleForm.bankAccount = val.bankAccount;
    ruleForm.businessMobile = val.businessMobile;
    ruleForm.businessAddress = val.businessAddress;
    InvoiceId.value = val.id;
    open.value = true;
  }
};
const addInvoice = () => {
  clean();
  InvoiceId.value = null;
  ruleForm.invoiceType = "0";
  ruleForm.invoiceHead = "";
  ruleForm.mailAddress = "";
  open.value = true;
  title.value = "新增发票信息";
};
</script>
<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
::v-deep .el-select .el-input__wrapper {
  cursor: pointer;
  width: 160px;
  height: 39px !important;
}
::v-deep .el-select--small {
  line-height: 24px;
  width: 620px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  width: 660px;
  height: 60px;
  position: relative;
  left: -36px;
  top: -36px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  cursor: pointer;
  height: 40px;
  width: 563px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  background: url("../../order/image/close.png") no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  background: url("../../order/image/closeactive.png") no-repeat;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 15px 8px 0 0;
}
.open3 {
  height: 40px;
}
.open4 {
  height: 40px;
}
.open5 {
  height: 40px;
}
.open6 {
  height: 40px;
}

.open7 {
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 264px;
}
.open8 {
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
::v-deep .el-select__wrapper {
  height: 40px;
}
.diy1 {
  width: 990px;
  background-color: #f2f2f2;
  /* height: 785px; */
  .diy2 {
    width: 990px;
    /* height: 677px; */
    background: #ffffff;
    // margin: 48px auto 60px auto;
    .diy3 {
      width: 530px;
      height: 19px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      // padding: 64px 0 0 70px;
      margin-left: 70px;
    }
    .diy4 {
      width: 990px;
      margin-top: 30px;
      margin-left: 70px;
      display: flex;
      flex-wrap: wrap;
      .diy8 {
        width: 340px;
        height: 300px;
        border: 1px solid #d9d9d9;
        margin-right: 16px;
        margin-bottom: 16px;
        .diy9 {
          width: 64px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 138px;
        }
        .diy9-1 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 92px;
        }
        .diy9-2 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 68px;
        }
        .diy10 {
          height: 14px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 31px 0 0 31px;
          .diy10-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0 0 0 31px;
          }
        }
        .diy11 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy11-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy20 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy20-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 44px;
          }
        }
        .diy21 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy21-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy22 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy22-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .dz {
          display: flex;
          margin-top: 15px;
          height: 40px;
          .dz1 {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
            margin-left: 31px;
          }
          .dz2 {
            width: 200px;
            //text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .diy12 {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 109px;
          left: 267px;
        }
        .diy12plus {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 0px;
          left: 267px;
        }
      }
      .diy8plus {
        width: 340px;
        height: 300px;
        border: 1px solid #ce1200;
        margin-right: 16px;
        margin-bottom: 16px;
        .hiddenImg {
          background: url("../../order/image/check.png") no-repeat;
          width: 24px;
          height: 24px;
          float: right;
          margin: -40px -10px 0 0;
        }
        .diy9 {
          width: 64px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 138px;
        }
        .diy9-1 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 92px;
        }
        .diy9-2 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 68px;
        }
        .diy10 {
          height: 14px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 31px 0 0 31px;
          .diy10-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0 0 0 31px;
          }
        }
        .diy11 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy11-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy20 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy20-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 44px;
          }
        }
        .diy21 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy21-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy22 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy22-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy12 {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 109px;
          left: 267px;
        }
        .diy12plus {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 0px;
          left: 267px;
        }
        .dz {
          display: flex;
          margin-top: 15px;
          height: 40px;
          .dz1 {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
            margin-left: 31px;
          }
          .dz2 {
            width: 200px;
            //text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .diy5 {
        width: 340px;
        height: 160px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #d9d9d9 0,
            #d9d9d9 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("../../order/image/add.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 53px 0 0 155px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 131px;
        }
      }
      .diy5:hover {
        width: 340px;
        height: 160px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #aaaaaa 0,
            #aaaaaa 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("../../order/image/addactive.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 53px 0 0 155px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 131px;
        }
      }
    }
    .diy13 {
      // width: 1150px;
      height: 1px;
      background: #e1e1e1;
      margin-top: 39px;
      margin-left: 25px;
      margin-right: 132px;
    }
    .diy14 {
      height: 69px;
      display: flex;
      .diy15 {
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        margin: auto 0;
        margin-left: 70px;
      }
      .diy16 {
        width: 100px;
        height: 48px;
        background: #ce1200;
        border-radius: 5px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: auto 0;
        margin-left: 16px;
      }
      .diy17 {
        width: 100px;
        height: 48px;
        border: 1px solid #aaaaaa;
        border-radius: 5px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: auto 0;
        margin-left: 190px;
      }
    }
  }
}
:deep(.el-dialog__body) {
  padding: 0px 10px 10px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
:deep(.el-dialog__header) {
  padding: 20px 20px 0px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}

::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
</style>
